import React, { useState } from "react";
import { Box, Button, Grid, CircularProgress } from "@mui/material";
import { useAppSelector } from "../../globalState/hooks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import { SetWorksheetDataToRedux } from "../../utils/setWorksheetDataToRedux";
import { setWorksheetAPIData } from "../../globalState/actions/create-worksheet-action";
import { setWorksheetDataForPrintWorkSheet } from "../../utils/setWorksheetDataForPrintWorkSheet";
import createWorksheetService from "../../services/worksheet/create-worksheet-service";
import { RootState } from "../../utils/userDetailsCommonType";

const SearchWorksheetButtons = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userDetails = useAppSelector((state: RootState) => state.authReducer);
  const states = useAppSelector((state) => state.createWorksheet);
  /// Open Worksheet By worksheetID
  const handleOpenWorksheetById = () => {
    const finalWorksheetData = SetWorksheetDataToRedux(states);
    dispatch(setWorksheetAPIData(finalWorksheetData));
    navigate(`/WorkSheets/updateWorksheet/${states.worksheet_id}`);
    dispatch(headerActiveLink("/WorkSheets/updateWorksheet"));
  };
  /// Print worksheet data with pass worksheet details
  const handlePrintWorksheetResult = () => {
    const submitedByValue = userDetails.loginDetials.username;
    const printWorksheetData = setWorksheetDataForPrintWorkSheet(
      states,
      submitedByValue
    );
    setIsLoading(true);
    createWorksheetService
      .postDisplayWorksheetAPI(printWorksheetData)
      .then((response) => {
        if (response.data.pdf_url) {
          window.open(response.data.pdf_url, "_blank");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error, "API Error");
      });
  };
  return (
    <>
      <Grid item xs={12} md={3} sx={{ pt: 2, width: "70%" }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="center"
        >
          <Button
            variant="contained"
            disabled={isLoading}
            sx={{
              borderRadius: "20px",
              p: 1,
              m: 1,
              width: { xs: "100%", md: "100%" },
              background: "#0464AB",
              fontWeight: 600,
              border: "1px solid #0464AB",
              textTransform: "none",
              maxWidth: { xs: "none", md: "250px" },
              "&:hover": {
                backgroundColor: "#FBB522",
                border: "1px solid #FBB522",
                color: "#022E4C",
              },
            }}
            onClick={handlePrintWorksheetResult}
          >
            Print Worksheet Results
            {isLoading && (
              <CircularProgress size={18} sx={{ padding: "0px 8px" }} />
            )}
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "20px",
              p: 1,
              m: 1,
              color: "#0464AB",
              fontWeight: 600,
              border: "1px solid #0464AB",
              textTransform: "none",
              width: { xs: "100%", md: "100%" },
              maxWidth: { xs: "none", md: "250px" },
              "&:hover": {
                backgroundColor: "#FBB522",
                border: "1px solid #FBB522",
                color: "#022E4C",
              },
            }}
            onClick={handleOpenWorksheetById}
          >
            Open This Worksheet
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "20px",
              p: 1,
              m: 1,
              color: "#0464AB",
              fontWeight: 600,
              border: "1px solid #0464AB",
              textTransform: "none",
              width: { xs: "100%", md: "100%" },
              maxWidth: { xs: "none", md: "250px" },
              "&:hover": {
                backgroundColor: "#FBB522",
                border: "1px solid #FBB522",
                color: "#022E4C",
              },
            }}
            onClick={() => {
              dispatch(headerActiveLink("/searchFolderWorksheet"));
              navigate("/searchFolderWorksheet");
            }}
          >
            Go To Search Screen
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default SearchWorksheetButtons;
