import React, { useEffect,forwardRef } from "react";
import { Typography, Box, IconButton, GlobalStyles } from "@mui/material";
import CustomTooltip from "../tooltip";
import interrogation from "../../assets/images/interrogation.svg";
import calendarIcon from "../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";

interface SafariDatePickerFieldProps {
  label?: string;
  tooltip?: string;
  backgroundColor?: string;
  value: Date | null | string;
  required?: boolean;
  onChange: (date: Date | null) => void;
  isDateDisabled?: boolean;
  error?: string;
}
interface CustomInputProps {
  selectedDate: string | null; // Explicitly defining the type for date
  onClick: () => void;
}

export default function SafariDatePickerField({
  label,
  tooltip,
  onChange,
  required,
  value,
  isDateDisabled,
  backgroundColor,
  error,
}: SafariDatePickerFieldProps) {
  const handleScroll = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const CustomCalendarIcon = () => (
  //   <IconButton>
  //     <img src={calendarIcon} alt="Calendar Icon" height={16} width={16} />
  //   </IconButton>
  // );

  // Parse string date into Date object, ensure compatibility with MM/DD/YYYY
  const parsedDateValue =
    typeof value === "string" && value
      ? dayjs(value, ["MM-DD-YYYY", "YYYY-MM-DD", "MM/DD/YYYY"]).isValid()
        ? dayjs(value, ["MM-DD-YYYY", "YYYY-MM-DD", "MM/DD/YYYY"]).toDate()
        : null
      : (value as Date | null);

  const handleDateChange = (date: Date | null) => {
    onChange(date);
  };
  const CustomInput = forwardRef<HTMLDivElement, CustomInputProps>(
    ({ selectedDate, onClick }, ref) => (
      <div className="custom-datepicker-wrapper" onClick={onClick} ref={ref}>
        <input
          value={selectedDate || ""}
          readOnly
          placeholder="mm-dd-yyyy"
          className={`custom-datepicker ${error ? "error" : ""}`}
        />
        <IconButton>
          <img src={calendarIcon} alt="Calendar Icon" height={16} width={16} />
        </IconButton>
      </div>
    )
  );
  CustomInput.displayName = "CustomInput";
  return (
    <>
      <GlobalStyles
        styles={{
          ".react-datepicker-wrapper": {
            backgroundColor: backgroundColor || "white",
            borderRadius: "28px",
            border: "1px solid #E2E0E0",
            display: "flex",
            alignItems: "center",
            height:"35px",
          },
          ".custom-datepicker-wrapper" :{
            display: "flex",
            padding: "0px 15px",
          },
          ".react-datepicker__input-container input": {
            fontSize: "15px",
            color: "#9C9BAA",
            border: "none",
            outline: "none",
            fontWeight: "normal",
          },
          ".custom-datepicker" :{
            width:"90%",
            padding: "0px 0px",
            backgroundColor: backgroundColor || "white",
          },
          ".custom-datepicker.error": {
            borderColor: "red",
          },
          ".react-datepicker__input-container": {
            fontSize: "15px",
            fontWeight: "normal",
            color: "#9C9BAA",
          },
          ".react-datepicker__input-container .react-datepicker__inputGroup__divider": {
            color: "#9C9BAA",
            fontSize: "16px",
            padding: "0 1px",
          },
          ".react-datepicker__clear-button, .react-datepicker__calendar-button": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          ".react-datepicker__calendar-button": {
            marginLeft: "auto",
          },
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {label && (
          <Typography
            variant="subtitle1"
            sx={{
              mb: 1,
              fontWeight: "medium",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {label} {required && <span style={{ color: "red" }}>*</span>}{" "}
            {tooltip && (
              <CustomTooltip title={tooltip}>
                <img
                  src={interrogation}
                  alt="Logo"
                  height={13}
                  style={{ marginLeft: "5px" }}
                />
              </CustomTooltip>
            )}
          </Typography>
        )}

        <DatePicker
          selected={parsedDateValue}
          onChange={handleDateChange}
          disabled={isDateDisabled}
          maxDate={isDateDisabled ? new Date() : undefined}
          customInput={
            <CustomInput
              selectedDate={value ? value.toString() : ""}
              onClick={() => {}}
            />
          }
          format="MM/dd/yyyy"
        />

        {error && (
          <Typography
            variant="caption"
            color="error"
            sx={{ mt: 1, fontSize: "12px" }}
          >
            {error}
          </Typography>
        )}
      </Box>
    </>
  );
}
