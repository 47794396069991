import React, { useEffect, useState, ChangeEvent } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Paper,
  Button,
  Grid,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  Select,
  TableCell,
  Collapse,
} from "@mui/material";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import styles from "./searchArchived.module.css";
import { ReactComponent as FileExporticon } from "../../assets/images/file-export.svg";
import ExportFileDropdown from "../../components/exportFileDropDown";
import CustomModal from "../../components/customModal";
import CustomDropdown from "../../components/drop-down";
import ExportOptionData from "../../components/exportDataSelection";
import searchArchiveWorksheet from "../../services/searchArchiveWorksheet";
import {
  ApiResponse,
  CountryData,
  CourtData,
  DropDown,
} from "../../types/global";
import CustomInput from "../../components/inputField";
import { showToast } from "../../components/toastNotification";
import { getFileName } from "../../utils/fileNameUtil";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { headerActiveLink } from "../../globalState/actions/headerAction";

interface searchArchiveWorkshitData {
  worksheet_id: number;
  plaintiff_name: string;
  defendant_name: string;
  county_name: string;
  court_name: string;
  civil_action_case_number: string;
  dhsc_no: string;
  prepared_by: string;
  county_id__county_name: string;
  court_id__court_name: string;
  prepared_by__username: string;
}

interface subWorkshitData {
  map(
    arg0: (
      item: {
        description:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined;
        created_at:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined;
        version:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined;
        worksheet_id: number;
      },
      idx: React.Key | null | undefined
    ) => JSX.Element
  ): React.ReactNode;
  worksheet_id: number;
  description: string;
  created_at: string;
  version: number;
  is_expired: boolean;
  is_archived: boolean;
  make_available_to_court: boolean;
}
interface TableSearchData {
  plaintiff_name: string;
  defendant_name: string;
  county_id__county_name: string;
  court_id__court_name: string;
  civil_action_case_number: string;
  dhsc_no: string;
  prepared_by__username: string;
}

const SearchArchivedFolderWorksheet: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [restoreWorksheetModal, setRestoreWorksheetModal] = useState(false);
  const [allRowsExpanded, setAllRowsExpanded] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [searchArchiveWorksheetData, setSearchArchiveWorksheetData] = useState<
    searchArchiveWorkshitData[]
  >([]);
  const [subWorksheetTblData, setSubWorksheetTblData] = useState<
    subWorkshitData[]
  >([]);
  const [courtData, setCourtData] = useState<DropDown[]>([]);
  const [countyData, setCountyData] = useState<DropDown[]>([]);
  const [tableSearchData, setTableSearchData] = useState<TableSearchData>({
    plaintiff_name: "",
    defendant_name: "",
    county_id__county_name: "",
    court_id__court_name: "",
    civil_action_case_number: "",
    dhsc_no: "",
    prepared_by__username: "",
  });
  const [totalTableRecords, setTotalTableRecords] = useState(0);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [subTableWorksheetId, setSubTableWorksheetId] = useState<number>(0);
  //const [worksheetArchiveId, setWorksheetArchiveId] = useState<number>(0);
  //const [isRowOpen, setIsRowOpen] = useState<number | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({
    key: "",
    direction: "asc",
  });

  useEffect(() => {
    const searchTime = setTimeout(() => {
      handleGetSearchArchiveWorksheetListData();
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [pageIndex, perPage, tableSearchData, sortConfig]);

  useEffect(() => {
    handleGetCourtListData();
    handleGetCountyListData();
  }, []);

  ///-------------API calling Function start-------------------
  /// fetch Search Archive Worksheet Listinst data
  const handleGetSearchArchiveWorksheetListData = () => {
    const tableOrdering =
      sortConfig.direction === "asc" ? sortConfig.key : `-${sortConfig.key}`;

    const inputData = {
      pageIndex,
      perPage,
      tableSearchData,
      tableOrdering,
    };
    searchArchiveWorksheet
      .getSearchArchiveWorksheetTblListAPI(inputData)
      .then(
        (
          response: ApiResponse<{
            count: number;
            results: searchArchiveWorkshitData[];
          }>
        ) => {
          if (response?.data?.results?.length > 0) {
            const tempArrayList = response?.data.results;
            const updatedArray = tempArrayList.map((item) => ({
              ...item,
              county_id__county_name: item.county_name,
              court_id__court_name: item.court_name,
              prepared_by__username: item.prepared_by,
            }));

            setSearchArchiveWorksheetData(updatedArray);
            setTotalTableRecords(response?.data.count);
          } else {
            setSearchArchiveWorksheetData([]);
            setTotalTableRecords(0);
          }
        }
      )
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  const handleHomeRedirection = () => {
    dispatch(headerActiveLink(""));
    navigate("/");
  };
  /// fetch Court List data
  const handleGetCourtListData = () => {
    searchArchiveWorksheet
      .getCourtListAPI()
      .then((response: { data: ApiResponse<CourtData[]> }) => {
        if (response?.data?.data?.length > 0) {
          const finalData: DropDown[] = response.data.data.map(
            (court_name: CourtData) => ({
              value: court_name.court_name,
              label: court_name.court_name,
            })
          );
          setCourtData(finalData);
        } else {
          setCourtData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// fetch County List data
  const handleGetCountyListData = () => {
    searchArchiveWorksheet
      .getCountyListAPI()
      .then((response: { data: ApiResponse<CountryData[]> }) => {
        if (response?.data.data?.length > 0) {
          const finalData: DropDown[] = response.data.data.map(
            (item: CountryData) => ({
              value: item.county_name,
              label: item.county_name,
            })
          );
          setCountyData(finalData);
        } else {
          setCountyData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// fetch Search Archive Worksheet Listing data
  const handleGetSubTableWorksheetList = (id: number | string) => {
    searchArchiveWorksheet
      .getSearchArchiveWorksheetSubTblListAPI(id)
      .then((response: ApiResponse<subWorkshitData[]>) => {
        if (response?.data) {
          setSubWorksheetTblData(response.data);
        } else {
          setSubWorksheetTblData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// API called for Delete Worksheet data
  const handleDeleteWorksheetData = () => {
    searchArchiveWorksheet
      .deleteDeleteWorksheetAPI(subTableWorksheetId)
      .then((response) => {
        if (response.data?.message === "Worksheet deleted successfully") {
          setRestoreWorksheetModal(false);
          //handleGetSubTableWorksheetList(worksheetArchiveId);
          handleGetSearchArchiveWorksheetListData();
          showToast(response?.data?.message, "success");
        } else {
          showToast(response?.data?.message, "error");
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// API called for Restore Worksheet data
  const handleRestoreWorksheetData = () => {
    searchArchiveWorksheet
      .patchRestoreWorksheetAPI(subTableWorksheetId)
      .then((response) => {
        if (
          response.data?.message.includes("Worksheet restored successfully")
        ) {
          setRestoreWorksheetModal(false);
          //handleGetSubTableWorksheetList(worksheetArchiveId);
          handleGetSearchArchiveWorksheetListData();
          showToast(response?.data?.message, "success");
        } else {
          showToast(response?.data?.message, "error");
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };

  //// handle Download Worksheet Files data
  const handleDownloadWorksheetData = (key: string) => {
    const arrayIDs = searchArchiveWorksheetData.map(
      (item) => item.worksheet_id
    );
    searchArchiveWorksheet
      .getDownloadWorkSheetAPI(key, arrayIDs)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          getFileName(key, "SearchArchiveWorksheets")
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("API Error", error);
      });
  };

  ///-------------API calling Function end-------------------
  /// handle table input onchange
  const searchInputOnchange = (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setTableSearchData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPageIndex(1);
    setPerPage(10);
  };

  /// Restore and delete Worksheet Modal Open
  const handleDeleteRestoreWorksheetModalOpen = (
    tab: string,
    worksheetId: number
  ) => {
    const checkData = tab === "Delete" ? true : false;
    setRestoreWorksheetModal(true);
    setIsDeleteModal(checkData);
    setSubTableWorksheetId(worksheetId);
  };
  //// modal close code
  const handleModalClose = () => {
    setRestoreWorksheetModal(false);
  };
  /// handle File Download onchange
  const handleFileDownloadOnChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    handleDownloadWorksheetData(event.target.value);
  };

  const handleExpandAllRows = () => {
    if (allRowsExpanded) {
      setExpandedRows([]);
    } else {
      const allWorksheetIds = searchArchiveWorksheetData.map(
        (item) => item.worksheet_id
      );
      setExpandedRows(allWorksheetIds);
      handleGetSubTableWorksheetList(allWorksheetIds.join(","));
    }
    setAllRowsExpanded(!allRowsExpanded);
  };

  const OptionData = ExportOptionData(selectedValue);

  const tableHeadings = [
    { id: "1", label: "#", sortable: false, search: false },
    {
      id: "2",
      label: (
        <IconButton onClick={handleExpandAllRows}>
          {allRowsExpanded ? (
            <RemoveCircleOutlineIcon sx={{ color: "#fff" }} />
          ) : (
            <AddCircleOutlineIcon sx={{ color: "#fff" }} />
          )}
        </IconButton>
      ),
      sortable: false,
      search: false,
    },
    {
      id: "plaintiff_name",
      label: "Plaintiff Name",
      sortable: true,
      search: true,
    },
    {
      id: "defendant_name",
      label: "Defendant Name",
      sortable: true,
      search: true,
    },
    {
      id: "county_id__county_name",
      label: "County",
      sortable: true,
      search: true,
    },
    {
      id: "court_id__court_name",
      label: "Court",
      sortable: true,
      search: true,
    },
    {
      id: "civil_action_case_number",
      label: "CACN",
      sortable: true,
      search: true,
    },
    { id: "dhsc_no", label: "DHS/DCSS", sortable: true, search: true },
    {
      id: "prepared_by__username",
      label: "Prepared By",
      sortable: true,
      search: true,
    },
  ];

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
  };

  /// handle Row select for per page size
  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(Number(event.target.value));
    setPageIndex(1);
  };

  /// handle table search reset data
  const handleResetGridData = () => {
    setTableSearchData({
      plaintiff_name: "",
      defendant_name: "",
      county_id__county_name: "",
      court_id__court_name: "",
      civil_action_case_number: "",
      dhsc_no: "",
      prepared_by__username: "",
    });
    setPageIndex(1);
    setPerPage(10);
    setSelectedValue("");
    setSortConfig({
      key: "",
      direction: "asc",
    });
  };
  /// handle Open Sub Table collapse
  const handleTableRowOpen = (workshitId: number) => {
    let updatedExpandedRows: number[] = [];

    if (expandedRows.includes(workshitId)) {
      updatedExpandedRows = expandedRows.filter((id) => id !== workshitId);
    } else {
      updatedExpandedRows = [...expandedRows, workshitId];
    }

    setExpandedRows(updatedExpandedRows);

    const expandedWorksheetIdsString = updatedExpandedRows.join(",");

    if (expandedWorksheetIdsString) {
      handleGetSubTableWorksheetList(expandedWorksheetIdsString);
    }
  };

  const isRowExpanded = (worksheetId: number) => {
    return expandedRows.includes(worksheetId);
  };
  /// table sorting code
  const handleSort = (columnId: keyof searchArchiveWorkshitData) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === columnId &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnId, direction });
  };

  ///table sorting icons
  const getSortIcon = (columnId: keyof searchArchiveWorkshitData) => {
    if (!sortConfig) return null;
    return (
      <>
        {sortConfig.key === columnId ? (
          sortConfig.direction === "asc" ? (
            <ArrowUpwardIcon
              fontSize="small"
              sx={{ verticalAlign: "middle" }}
            />
          ) : (
            <ArrowDownwardIcon
              fontSize="small"
              sx={{ verticalAlign: "middle" }}
            />
          )
        ) : (
          <ArrowUpwardIcon
            fontSize="small"
            sx={{ opacity: 0.2, verticalAlign: "middle" }}
          />
        )}
      </>
    );
  };

  const totalPages = Math.ceil(totalTableRecords / perPage);
  const startIndex = (pageIndex - 1) * perPage + 1;
  const endIndex = Math.min(pageIndex * perPage, totalTableRecords);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        className="contain__area"
      >
        <Box
          className="breadcrumbs"
          sx={{
            mt: { md: "3rem", sm: "1rem", xs: "1rem" },
            mb: { md: "1.5rem", sm: "1rem", xs: "1rem" },
            marginX: { md: "56px", sm: "0px", xs: "0px" },
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/"
                className="LinkColorBlue"
                onClick={handleHomeRedirection}
              >
                Home
              </Link>
              <Typography color="#000000">
                Search Archived Folders-Worksheets
              </Typography>
            </Breadcrumbs>
          </div>
        </Box>
        <Box>
          <Paper
            elevation={0}
            sx={{
              borderRadius: "5px",
              border: "1px solid #DFDFDF",
              margin: { md: "0px 56px", sm: " 0px", xs: "0px" },
            }}
          >
            <Box m={2} mb={0}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="h5"
                    className="h5"
                    color="#0464AB"
                    gutterBottom
                    sx={{
                      fontSize: { md: 23, sm: 19, xs: 19 },
                      letterSpacing: "1px",
                      textAlign: { md: "left", sm: "left", xs: "center" },
                    }}
                  >
                    Search Archived Folders-Worksheets
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box
              display="flex"
              justifyContent={{
                md: "space-between",
                sm: "space-between",
                xs: "center",
              }}
              flexWrap="wrap"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
                mx={2}
                marginLeft={{ xs: "30%", sm: "2%", md: "1.5%" }}
                flexWrap="wrap"
              >
                <Grid
                  container
                  alignItems={{ xs: "center" }}
                  justifyContent={{ xs: "center" }}
                  spacing={1}
                >
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography variant="body1">
                      Showing{" "}
                      <strong>
                        {startIndex}-{endIndex}
                      </strong>{" "}
                      of <strong>{totalTableRecords} </strong> items.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" mr={1}>
                        Show
                      </Typography>
                      <Select
                        value={perPage}
                        onChange={handleRowsPerPageChange}
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "50px" }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                      <Typography variant="body1" ml={1}>
                        entries
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1} mr={3} ml={1}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="end"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="outlined"
                      className={styles.resetButtoncss}
                      sx={{
                        height: "35px",
                        width: {
                          lg: "max-content",
                          md: "135px",
                          sm: "135px",
                          xs: "fit-content",
                        },
                        fontSize: {
                          md: "12px",
                          sm: "12px",
                          xs: "9px",
                        },
                      }}
                      onClick={handleResetGridData}
                    >
                      <ReplayOutlinedIcon sx={{ marginRight: "2px" }} />
                      Reset Grid
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    paddingLeft={{ xs: "5px!important", md: "10px!important" }}
                  >
                    <Box>
                      <ExportFileDropdown
                        name="dropdown"
                        label=""
                        value={selectedValue}
                        onChange={handleFileDownloadOnChange}
                        options={OptionData.slice(1)}
                        placeholder={
                          <div style={{ display: "flex", alignItems: "left" }}>
                            <FileExporticon
                              className={
                                selectedValue === "Export"
                                  ? styles.activeSVG
                                  : styles.inactiveSVG
                              }
                            />
                            <Typography
                              sx={{
                                marginLeft: { md: "6px", sm: "6px", xs: "0px" },
                              }}
                            >
                              {OptionData[0].label}
                            </Typography>
                          </div>
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box mt={1}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    "& .MuiTableCell-root": {
                      padding: "10px 13px",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#404040" }}>
                      {tableHeadings.map((label, index) => (
                        <TableCell
                          key={index}
                          onClick={() =>
                            label.sortable &&
                            handleSort(
                              label.id as keyof searchArchiveWorkshitData
                            )
                          }
                          sx={{
                            color: "#F8F8F8",
                            width:
                              label.label === "Plaintiff Name"
                                ? "16%"
                                : label.label === "Defendant Name"
                                ? "15%"
                                : "80px",
                            minWidth: {
                              md:
                                label.label === "#" || label.id === "2"
                                  ? "25px"
                                  : "90px",
                              sm:
                                label.label === "#" || label.id === "2"
                                  ? "25px"
                                  : "130px",
                              xs:
                                label.label === "#" || label.id === "2"
                                  ? "25px"
                                  : "130px",
                            },
                            textAlign:
                              label.label === "#" || label.id === "2"
                                ? "center"
                                : "inherit",

                            cursor: label.sortable ? "pointer" : "default",
                          }}
                        >
                          {label.label}
                          {label.sortable &&
                            getSortIcon(
                              label.id as keyof searchArchiveWorkshitData
                            )}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {tableHeadings.map((label, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            color: "#F8F8F8",
                            width:
                              label.label === "Plaintiff Name"
                                ? "16%"
                                : label.label === "Defendant Name"
                                ? "20%"
                                : label.label === "County" ||
                                  label.label === "Court"
                                ? "5%"
                                : "auto",
                          }}
                        >
                          {label.label === "Court" ? (
                            <CustomDropdown
                              mbHeading="0px"
                              height={35}
                              name="court_id__court_name"
                              value={
                                tableSearchData[
                                  label.id as keyof TableSearchData
                                ] || ""
                              }
                              onChange={searchInputOnchange}
                              options={courtData}
                              placeholder="Select"
                            />
                          ) : label.label === "County" ? (
                            <CustomDropdown
                              mbHeading="0px"
                              height={35}
                              name="county_id__county_name"
                              value={
                                tableSearchData[
                                  label.id as keyof TableSearchData
                                ] || ""
                              }
                              onChange={searchInputOnchange}
                              options={countyData}
                              placeholder="Select"
                            />
                          ) : (
                            label.search && (
                              <CustomInput
                                name={label.id}
                                placeholder="Search"
                                value={
                                  tableSearchData[
                                    label.id as keyof TableSearchData
                                  ] || ""
                                }
                                onChange={searchInputOnchange}
                                endAdornment={<SearchOutlinedIcon />}
                              />
                            )
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchArchiveWorksheetData.map((row, index) => (
                      <>
                        <TableRow
                          sx={{
                            "& > *": { borderBottom: "unset" },
                            padding: "0px",
                          }}
                          key={index}
                        >
                          <TableCell>
                            {(pageIndex - 1) * perPage + index + 1}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                handleTableRowOpen(row.worksheet_id)
                              }
                            >
                              {isRowExpanded(row.worksheet_id) ? (
                                <RemoveCircleOutlineIcon
                                  sx={{ color: "#0464AB" }}
                                />
                              ) : (
                                <AddCircleOutlineIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>{row.plaintiff_name}</TableCell>
                          <TableCell>{row.defendant_name}</TableCell>
                          <TableCell>{row.county_id__county_name}</TableCell>
                          <TableCell>{row.court_id__court_name}</TableCell>
                          <TableCell>{row.civil_action_case_number}</TableCell>
                          <TableCell>{row.dhsc_no}</TableCell>
                          <TableCell>{row.prepared_by__username}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              background: "#E6F5FF",
                            }}
                            colSpan={9}
                          >
                            <Collapse
                              in={isRowExpanded(row.worksheet_id)}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box sx={{ background: "#E6F5FF", my: 2, mx: 1 }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                  sx={{ color: "#022E4C", fontWeight: 600 }}
                                >
                                  Child Support Worksheets For:{" "}
                                  {row.plaintiff_name} Vs {row.defendant_name}
                                </Typography>
                                <Table size="small" aria-label="worksheets">
                                  <TableHead
                                    sx={{ backgroundColor: "#022E4C" }}
                                  >
                                    <TableRow>
                                      <TableCell sx={{ color: "#FFFFFF" }}>
                                        Worksheet Description
                                      </TableCell>
                                      <TableCell sx={{ color: "#FFFFFF" }}>
                                        Date Entered
                                      </TableCell>
                                      <TableCell sx={{ color: "#FFFFFF" }}>
                                        Version
                                      </TableCell>
                                      <TableCell sx={{ color: "#FFFFFF" }}>
                                        Options
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody sx={{ background: "#fff" }}>
                                    {subWorksheetTblData[row.worksheet_id]?.map(
                                      (item, idx) => (
                                        <TableRow
                                          key={idx}
                                          sx={{ verticalAlign: "top" }}
                                        >
                                          <TableCell sx={{ width: "35%" }}>
                                            {item.description}
                                          </TableCell>
                                          <TableCell sx={{ width: "20%" }}>
                                            {item.created_at}
                                          </TableCell>
                                          <TableCell sx={{ width: "5%" }}>
                                            {item.version}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "40%",
                                              padding: "5px!important",
                                            }}
                                          >
                                            <Button
                                              variant="outlined"
                                              sx={{
                                                margin: "5px",
                                                padding: "5px 25px",
                                              }}
                                              className={
                                                styles.globalButtonStyle
                                              }
                                              onClick={() =>
                                                handleDeleteRestoreWorksheetModalOpen(
                                                  "Restore",
                                                  item.worksheet_id
                                                )
                                              }
                                            >
                                              Restore Worksheet
                                            </Button>
                                            <Button
                                              variant="outlined"
                                              sx={{
                                                margin: "5px",
                                                padding: "5px 25px",
                                              }}
                                              className={
                                                styles.globalButtonStyle
                                              }
                                              onClick={() =>
                                                handleDeleteRestoreWorksheetModalOpen(
                                                  "Delete",
                                                  item.worksheet_id
                                                )
                                              }
                                            >
                                              Delete Worksheet
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                container
                my={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ textAlign: { xs: "center", sm: "left", md: "left" } }}
                >
                  <Typography sx={{ marginX: "20px" }} variant="body1">
                    Showing {startIndex}-{endIndex} of {totalTableRecords}{" "}
                    items.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-end" },
                  }}
                >
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={pageIndex}
                    onChange={handlePageChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          {/*---------- Restore and Delete Worksheet Modal----------- */}
          <CustomModal
            onClose={handleModalClose}
            actionButtonLabel={
              isDeleteModal ? "Delete Worksheet" : "Restore Worksheet"
            }
            open={restoreWorksheetModal}
            title={isDeleteModal ? "Delete Worksheet" : "Restore Worksheet"}
            boxStyle={{ width: { md: "33%", sm: "70%", xs: "90%" } }}
            save={
              isDeleteModal
                ? handleDeleteWorksheetData
                : handleRestoreWorksheetData
            }
          >
            <Typography sx={{ mt: 2, textAlign: "center", fontWeight: "bold" }}>
              {isDeleteModal
                ? " If You Delete The Worksheet, The Worksheet Will Be Permanently Deleted"
                : "If You Restore The Worksheet, You Will Have The Ability To Review, Edit and/or Print The Worksheet."}
            </Typography>
          </CustomModal>
        </Box>
      </Box>
    </>
  );
};

export default SearchArchivedFolderWorksheet;
