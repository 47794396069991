import React, {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  FocusEvent,
} from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Link,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../assets/images/info.svg";
import { styled } from "@mui/material/styles";
import { ReactComponent as Interrogation } from "../../../assets/images/interrogation.svg";
import CustomInput from "../../../components/inputField";
import CustomDropdown from "../../../components/drop-down";
import { useAppDispatch, useAppSelector } from "../../../globalState/hooks";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import {
  deleteFromArray,
  updateArrayInfo,
  updateBasicInfo,
} from "../../../globalState/actions/create-worksheet-action";
import CurrencyValue from "../../../components/currencyInputWithoutSelect";
import TextareaField from "../../../components/text-area";
import WorksheetButtons from "../../../components/worksheetButtons";
import SettingIcons from "../../../assets/images/SettingIcon.svg";
import style from "../workSheet.module.css";
import CustomModal from "../../../components/customModal";
import ReportTechnicalForm, {
  ReportTechnicalFormHandle,
} from "../../../components/reportTechnicalIssue";
import CustomTooltip from "../../../components/tooltip";
import { GenerateCaptcha } from "../../../utils/generateCaptcha";
import technicalReportService from "../../../services/technicalReport-service";
import { showToast } from "../../../components/toastNotification";
import ValidationErrorModal from "../../../components/validationErrorModal";
import { DropDown, FormErrors } from "../../../types/global";
import {
  AddExtraEducationalExpensesValidation,
  AddExtraordinaryMedicalExpensesValidation,
  AddExtraordinarySpecialExpensesValidation,
  AddProposedLowIncomeValidation,
  AddSpecificNonspecificDeviationsValidation,
  createOptionsValue,
  createParentsOptionsValue,
  getTotalExtraordinaryEducationalSums,
  getTotalExtraordinaryMedicalSums,
  getTotalExtraordinarySpecialSums,
  getTotalProposedDeviation,
} from "./DeviationsValidationTabs";
import { deviationTypeData } from "../../../utils/constants";
import {
  AddExtraEducationalExpenses,
  AddExtraMedicalExpenses,
  AddExtraSpecialExpenses,
  AddspeNonspecificDeviation,
} from "../../../types/create-worksheet/DeviationsTab";
import DeleteModal from "../../../components/globalDeleteModal";
import { calculateIncomeDeviation } from "../../../utils/calculateIncomeDeviation";
import interrogation from "../../../assets/images/interrogation.svg";
import { finalCalculationForGrossAndAdjustedTabDeviation } from "../../../utils/calculationForGrossAdjustedValueForDeviation";

const buttonStyle = {
  borderRadius: "20px",
  padding: "8px 16px",
  margin: "8px",
  width: "fit-content",
  maxWidth: "none",
  transition: "background-color 0.3s, color 0.3s, border 0.3s",
  backgroundColor: "transparent",
  color: "#0464ab",
  border: "1px solid #0464ab",
};

const cellHeaderStyle = {
  position: "sticky",
  right: 0,
  zindex: 2,
};

const cellStyle = {
  border: "1px solid #DFDFDF",
  fontFamily: "Inter !important",
  fontSize: "12px !important",
  "& > *:last-child": {
    verticalAlign: "top",
    position: "sticky",
    right: 0,
    zIndex: 1,
  },
};
type ParentsTotal = {
  [key: string]: number;
};
interface TechnicalReportInput {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
}

const DeviationsSchedule = () => {
  const dispatch = useAppDispatch();
  const formRef = useRef<ReportTechnicalFormHandle>(null);
  const workSheetStates = useAppSelector((state) => state.createWorksheet);

  const [reportTechnicalModal, setReportTechnicalModal] =
    useState<boolean>(false);
  const [proposedLowIncome49Data, setProposedLowIncome49Data] = useState({
    deviation_is_for: "",
    number_of_children: workSheetStates.add_child_to_worksheet?.filter(
      (child) => child.child_status === "included"
    ).length,
    max_amount_low_income_deviation: "",
    proposed_low_income_deviation: "",
    low_income_deviation: "",
    low_income_deviation_explanation: "",
  });

  const [
    judicialDiscretionLowIncome49Data,
    setJudicialDiscretionLowIncome49Data,
  ] = useState({
    deviation_is_for: "",
    number_of_children: workSheetStates.add_child_to_worksheet?.filter(
      (child) => child.child_status === "included"
    ).length,
    max_amount_low_income_deviation: "",
    proposed_low_income_deviation: "",
    low_income_deviation: "",
    low_income_deviation_explanation: "",
  });

  const [speNonspecificDeviationData, setSpeNonspecificDeviationData] =
    useState<AddspeNonspecificDeviation>({
      deviation_type: "",
      combined_adjusted_income_above_40000: "",
      deviation_is_for: "",
      proposed_deviation_amount: "",
      judicial_discretion_applied_deviation_amount: "",
    });
  const [extraEducationalExpensesData, setExtraEducationalExpensesData] =
    useState<AddExtraEducationalExpenses>({
      child_name: "",
      tuition_room_board_books_yearly: "",
      other_extraordinary_expenses_yearly: "",
      paid_by: "",
    });
  const [extraMedicalExpensesData, setExtraMedicalExpensesData] =
    useState<AddExtraMedicalExpenses>({
      child_name: "",
      paid_by: "",
      extraordinary_medical_expenses_yearly: "",
    });
  const [extraSpecialExpensesData, setExtraSpecialExpensesData] =
    useState<AddExtraSpecialExpenses>({
      child_name: "",
      amount_paid_yearly: "",
      paid_by: "",
      explanation_of_expense: "",
    });

  const [lowIncomeDeviation, setLowIncomeDeviation] = useState(false);
  const [
    judicialDiscretionlowIncomeDeviation,
    setjudicialDiscretionlowIncomeDeviation,
  ] = useState(false);

  const [specificDeviations, setSpecificDeviations] = useState(false);
  const [educationalExpenses, setEducationalExpenses] = useState(false);
  const [medicalExpenses, setMedicalExpenses] = useState(false);
  const [specialExpenses, setSpecialExpenses] = useState(false);
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [inputValidationErrorModal, setInputValidationErrorModal] =
    useState<boolean>(false);
  const [inputValidationMessage, setInputValidationMessage] = useState("");
  const [isActiveTab2, setIsActiveTab2] = useState(false);
  const [isEdit, setIsEdit] = useState<string>("");
  const [deviationForSelectedParentsData, setDeviationForSelectedParentsData] =
    useState<DropDown[]>([]);
  const [formProposedLowIncomeErrors, setFormProposedLowIncomeErrors] =
    useState<FormErrors>({});
  const [deleteTblId, setDeleteTblId] = useState("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [childNameData, setChildNameData] = useState<DropDown[]>([]);
  const [paidByData, setPaidbyData] = useState<DropDown[]>([]);
  const [isDeletedModal, setIsDeletedModal] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<string | null>(null);
  const [currentArrayKey, setCurrentArrayKey] = useState<string>("");

  useEffect(() => {
    if (workSheetStates.add_child_to_worksheet.length > 0) {
      const optionsData = createParentsOptionsValue(
        workSheetStates.parent_name_1,
        workSheetStates.parent_name_2
      );
      setPaidbyData(optionsData);
      const getChildName = workSheetStates.add_child_to_worksheet.map(
        (child, index) => ({
          value: child.child_name,
          label: `${(index + 1).toString().padStart(2, "0")}. ${
            child.child_name
          }`,
        })
      );
      setChildNameData(getChildName);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const parentsData = createOptionsValue(
      workSheetStates.non_custodial_parent
    );
    setDeviationForSelectedParentsData(parentsData);
  }, []);

  //// API calling function start
  /// API called for Technical Report
  const handleSubmitTechnicalReport = async (
    reportInputValues: TechnicalReportInput
  ) => {
    const inputData = {
      worksheet_id: Number(reportInputValues.worksheet_id),
      name: reportInputValues.name !== "" ? reportInputValues.name : null,
      email: reportInputValues.email,
      phone:
        reportInputValues.phone !== "" ? Number(reportInputValues.phone) : null,
      browser:
        reportInputValues.browser !== "" ? reportInputValues.browser : null,
      technical_issue: reportInputValues.technical_issue,
    };
    await technicalReportService
      .reportTechnicalIssueAPI(inputData)
      .then((response) => {
        if (response && response.data) {
          showToast(response.data.message, "success");
          setReportTechnicalModal(false);
        } else {
          showToast(response?.data?.message, "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };
  //// API calling function end
  /// handle Low Income Deviation Open code
  const handleLowIncomeDeviationOpen = (modal: string) => {
    if (workSheetStates.non_custodial_parent) {
      if (
        workSheetStates.add_child_to_worksheet.length > 0 &&
        workSheetStates?.add_child_to_worksheet?.filter(
          (child) => child.child_status === "included"
        ).length > 0
      ) {
        if (
          Number(workSheetStates.total_gross_income1) > 0 ||
          Number(workSheetStates.total_gross_income2) > 0
        ) {
          if (modal === "49A") {
            setLowIncomeDeviation(true);
          } else {
            setjudicialDiscretionlowIncomeDeviation(true);
          }
        } else {
          setInputValidationMessage(
            "Please add gross income on schedule a before adding a low income deviations."
          );
          setIsActiveTab2(true);
          setInputValidationErrorModal(true);
        }
      } else {
        setInputValidationMessage(
          "Please add a child on the basic tab before adding a low Income deviations."
        );
        setInputValidationErrorModal(true);
      }
    } else {
      setInputValidationMessage(
        "Please choose the Noncustodial Parent on the Basic Information Worksheet tab before adding a Low Income Deviation."
      );
      setInputValidationErrorModal(true);
      setIsActiveTab2(false);
    }
  };

  /// handle close low income deviation modal
  const handleLowIncomeDeviationClose = () => {
    // setLowIncomeDeviation(false);
    setModalDataEmpty();
  };
  const handleJDLowIncomeDeviationClose = () => {
    // setjudicialDiscretionlowIncomeDeviation(false);
    setModalDataEmpty();
  };

  /// handle Specific deviation modal open
  const handleSpecificDeviationsOpen = () => {
    if (workSheetStates.non_custodial_parent) {
      setFormProposedLowIncomeErrors({});
      setSpecificDeviations(true);
    } else {
      setInputValidationMessage(
        "Please choose the Noncustodial Parent on the Basic Information Worksheet tab before adding a Specific/Nonspecific Deviation."
      );
      setInputValidationErrorModal(true);
    }
  };
  const handleSpecificDeviationsClose = () => {
    setSpecificDeviations(false);
    setModalDataEmpty();
  };

  /// Educational Expenses modal Open
  const handleEducationalExpensesOpen = () => {
    if (workSheetStates.add_child_to_worksheet.length > 0) {
      setFormProposedLowIncomeErrors({});
      setEducationalExpenses(true);
    } else {
      setInputValidationMessage(
        "Please Add A Child on the Basic Tab Before Adding Educational Expenses Deviations."
      );
      setInputValidationErrorModal(true);
    }
  };
  /// Educational Expenses modal close
  const handleEducationalExpensesClose = () => {
    setEducationalExpenses(false);
    setModalDataEmpty();
  };
  /// Medical Expenses modal Open
  const handleMedicalExpensesOpen = () => {
    if (workSheetStates.add_child_to_worksheet.length > 0) {
      setFormProposedLowIncomeErrors({});
      setMedicalExpenses(true);
    } else {
      setInputValidationMessage(
        "Please Add A Child on the Basic Tab Before Adding Medical Expenses Deviations."
      );
      setInputValidationErrorModal(true);
    }
  };
  const handleMedicalExpensesClose = () => {
    setMedicalExpenses(false);
    setModalDataEmpty();
  };
  /// Medical Special Expenses modal Open
  const handleSpecialExpensesOpen = () => {
    if (workSheetStates.add_child_to_worksheet.length > 0) {
      setFormProposedLowIncomeErrors({});
      setSpecialExpenses(true);
    } else {
      setInputValidationMessage(
        "Please Add A Child on the Basic Tab Before Adding Other Deviations."
      );
      setInputValidationErrorModal(true);
    }
  };
  const handleSpecialExpensesClose = () => {
    setSpecialExpenses(false);
    setModalDataEmpty();
  };
  /// show Input validation messages
  const handleInputValidationErrorModal = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);
    dispatch(updateBasicInfo("activeTab", isActiveTab2 ? "2" : "1"));
  };
  const handleInputValidationErrorModalOnCancel = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);
  };
  /// handle modal close
  const handleDeleteMdlClose = () => {
    setDeleteModal(false);
  };

  /// handle report Modal Open
  const handleReportModalOpen = () => {
    setInputCaptcha(GenerateCaptcha);
    setReportTechnicalModal(true);
  };
  const handleReportModalClose = () => setReportTechnicalModal(false);

  /// handle Input Onchange
  const handleInputOnChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const { name, value } = event.target;
    const validTwoDecimalRegex = /^-?\d*\.?\d{0,2}$/;
    if (
      name === "parenting_time_deviation1" ||
      name === "parenting_time_deviation2"
    ) {
      if (validTwoDecimalRegex.test(value)) {
        dispatch(updateBasicInfo(name, value));
      }
    } else {
      dispatch(updateBasicInfo(name, value));
    }
  };

  //// onblur Insured Input Handle
  const uninsuredInputHandle = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const formattedValue = parseFloat(value).toFixed(2);
    const finalValue = isNaN(Number(formattedValue)) ? "" : formattedValue;
    dispatch(updateBasicInfo(name, finalValue));
  };

  const handleSaveData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };
  // add data on Proposed LowIncome Deviation and Judicial Discretion For Low Income Deviation Table
  const addProposedLowIncomeDeviation = (modalName: string) => {
    let formErrors = {};

    if (modalName === "Proposed") {
      formErrors = AddProposedLowIncomeValidation(proposedLowIncome49Data);
    } else if (modalName === "Judicial") {
      formErrors = AddProposedLowIncomeValidation(
        judicialDiscretionLowIncome49Data
      );
    }

    setFormProposedLowIncomeErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });

    const isFormValid = Object.keys(formErrors).length === 0;
    if (isFormValid) {
      const commonExplanation =
        modalName === "Proposed"
          ? proposedLowIncome49Data.low_income_deviation_explanation
          : judicialDiscretionLowIncome49Data.low_income_deviation_explanation;

      const { deviation_is_for } =
        modalName === "Proposed"
          ? proposedLowIncome49Data
          : judicialDiscretionLowIncome49Data;

      // Update explanation in proposed_low_income_deviation array
      workSheetStates.proposed_low_income_deviation.forEach((item) => {
        if (item.deviation_is_for === deviation_is_for) {
          dispatch(
            updateArrayInfo({
              arrayKey: "proposed_low_income_deviation",
              itemId: item.id || "",
              newItemValues: {
                ...item,
                low_income_deviation_explanation: commonExplanation,
              },
            })
          );
        }
      });

      // Update explanation in judicial_discretion_for_low_income array
      workSheetStates.judicial_discretion_for_low_income.forEach((item) => {
        if (item.deviation_is_for === deviation_is_for) {
          dispatch(
            updateArrayInfo({
              arrayKey: "judicial_discretion_for_low_income",
              itemId: item.id || "",
              newItemValues: {
                ...item,
                low_income_deviation_explanation: commonExplanation,
              },
            })
          );
        }
      });

      // Continue with updating or adding the entry
      let existingEntryIndex = -1;
      const arrayKey =
        modalName === "Proposed"
          ? "proposed_low_income_deviation"
          : "judicial_discretion_for_low_income";

      existingEntryIndex = workSheetStates[arrayKey].findIndex(
        (item) => item.deviation_is_for === deviation_is_for
      );

      const newData = {
        deviation_is_for:
          modalName === "Proposed"
            ? proposedLowIncome49Data.deviation_is_for
            : judicialDiscretionLowIncome49Data.deviation_is_for,
        number_of_children: workSheetStates.add_child_to_worksheet?.length,
        max_amount_low_income_deviation:
          modalName === "Proposed"
            ? proposedLowIncome49Data.max_amount_low_income_deviation
            : judicialDiscretionLowIncome49Data.max_amount_low_income_deviation,
        ...(modalName === "Judicial" && {
          proposed_low_income_deviation:
            judicialDiscretionLowIncome49Data.proposed_low_income_deviation,
        }),
        low_income_deviation:
          modalName === "Proposed"
            ? proposedLowIncome49Data.low_income_deviation
            : judicialDiscretionLowIncome49Data.low_income_deviation,
        low_income_deviation_explanation: commonExplanation,
        id:
          existingEntryIndex !== -1
            ? workSheetStates[arrayKey][existingEntryIndex].id
            : new Date().getTime().toString(), // Generate a unique ID if this is a new entry
      };

      if (existingEntryIndex !== -1) {
        dispatch(
          updateArrayInfo({
            arrayKey: arrayKey,
            itemId: workSheetStates[arrayKey][existingEntryIndex].id || "",
            newItemValues: newData,
          })
        );
      } else {
        dispatch(
          updateArrayInfo({
            arrayKey: arrayKey,
            itemId: "",
            newItemValues: newData,
          })
        );
      }

      // Update basic info for the selected parent
      const selectedParent =
        modalName === "Proposed"
          ? proposedLowIncome49Data.deviation_is_for
          : judicialDiscretionLowIncome49Data.deviation_is_for;
      const lowIncomeExplanation = commonExplanation;
      const lowIncomeAmount =
        modalName === "Proposed"
          ? proposedLowIncome49Data.low_income_deviation
          : judicialDiscretionLowIncome49Data.low_income_deviation;

      if (selectedParent === workSheetStates.parent_name_1) {
        dispatch(
          updateBasicInfo("requestLowIncomeDeviationP1", lowIncomeExplanation)
        );
        if (modalName === "Proposed") {
          dispatch(
            updateBasicInfo("proposedLowIncomeDeviationAmoun1", lowIncomeAmount)
          );
        } else {
          dispatch(
            updateBasicInfo("judicialDiscretionAppliedAmount1", lowIncomeAmount)
          );
        }
      } else if (selectedParent === workSheetStates.parent_name_2) {
        dispatch(
          updateBasicInfo("requestLowIncomeDeviationP2", lowIncomeExplanation)
        );
        if (modalName === "Proposed") {
          dispatch(
            updateBasicInfo("proposedLowIncomeDeviationAmoun2", lowIncomeAmount)
          );
        } else {
          dispatch(
            updateBasicInfo("judicialDiscretionAppliedAmount2", lowIncomeAmount)
          );
        }
      }

      // Reset the modal data
      setModalDataEmpty();
    }
  };
  // Edit data from Proposed LowIncome Deviation and Judicial Discretion For Low Income Deviation
  const handleEditTableData = (itemId: number, modalName: string) => {
    const arrayKey =
      modalName === "Proposed"
        ? "proposed_low_income_deviation"
        : "judicial_discretion_for_low_income";

    // Adjust itemId to match zero-based index
    if (workSheetStates[arrayKey].length > 0) {
      let adjustedIndex = itemId - 1; // Correct the index to be zero-based

      // If the array has only one item, make sure to select it
      if (workSheetStates[arrayKey].length === 1) {
        adjustedIndex = 0; // Set the index to 0 since there's only one item
      }

      const itemToEdit = workSheetStates[arrayKey][adjustedIndex];

      if (itemToEdit) {
        // Create a common data structure for both modals
        const commonExplanation = "";

        const dataToSet = {
          deviation_is_for: "",
          number_of_children: itemToEdit.number_of_children,
          max_amount_low_income_deviation: "",
          proposed_low_income_deviation: "",
          low_income_deviation: "",
          low_income_deviation_explanation: commonExplanation,
        };

        // Synchronize the low_income_deviation_explanation in both modal states
        setProposedLowIncome49Data((prevState) => ({
          ...prevState,
          ...dataToSet,
          low_income_deviation_explanation: commonExplanation,
        }));

        setJudicialDiscretionLowIncome49Data((prevState) => ({
          ...prevState,
          ...dataToSet,
          low_income_deviation_explanation: commonExplanation,
        }));

        // Set the modal to edit mode
        setIsEditMode(true);
        setIsEdit(itemToEdit.id || "");

        if (modalName === "Proposed") {
          setLowIncomeDeviation(true);
          setjudicialDiscretionlowIncomeDeviation(false);
        } else if (modalName === "Judicial") {
          setjudicialDiscretionlowIncomeDeviation(true);
          setLowIncomeDeviation(false);
        }
      } else {
        showToast("Please add data before editing.", "error");
      }
    } else {
      showToast("No data available to edit.", "error");
    }
  };
  // Delete data from Proposed LowIncome Deviation and Judicial Discretion For Low Income Deviation

  const handleDeleteData = () => {
    const arrayKey = lowIncomeDeviation
      ? "proposed_low_income_deviation"
      : "judicial_discretion_for_low_income";
    const deviation_is_for = lowIncomeDeviation
      ? proposedLowIncome49Data.deviation_is_for
      : judicialDiscretionLowIncome49Data.deviation_is_for;

    const formErrors: FormErrors = {};

    // Ensure that the "Deviation Is For" field has a valid value
    if (!deviation_is_for.trim()) {
      formErrors.deviation_is_for =
        "Must choose a non-custodial parent to delete";
    }

    if (Object.keys(formErrors).length > 0) {
      setFormProposedLowIncomeErrors(formErrors);
      return;
    }

    // Find the item to delete
    const itemToDelete = workSheetStates[arrayKey]?.find(
      (item) => item.deviation_is_for === deviation_is_for
    );

    if (itemToDelete) {
      const itemId = itemToDelete.id || "";

      if (itemId) {
        // Store the itemId and arrayKey for later use
        setItemIdToDelete(itemId);
        setCurrentArrayKey(arrayKey);
        // Open the delete confirmation modal
        setDeleteModalOpen(true);
      } else {
        console.error("Item ID is undefined. Cannot delete item.");
      }
    } else {
      showToast("Record doesn't exist for this user", "error");
    }
  };

  /// handle confirm Delete modal data
  const confirmDeleteData = () => {
    if (itemIdToDelete && currentArrayKey) {
      // Dispatch the deletion of the item from the array
      dispatch(
        deleteFromArray({
          arrayKey: currentArrayKey,
          itemId: itemIdToDelete,
        })
      );

      // Update the basic info depending on the array
      const deviation_is_for = lowIncomeDeviation
        ? proposedLowIncome49Data.deviation_is_for
        : judicialDiscretionLowIncome49Data.deviation_is_for;

      if (currentArrayKey === "proposed_low_income_deviation") {
        if (deviation_is_for === workSheetStates.parent_name_1) {
          dispatch(updateBasicInfo("proposedLowIncomeDeviationAmoun1", ""));
          dispatch(updateBasicInfo("requestLowIncomeDeviationP1", ""));
        } else if (deviation_is_for === workSheetStates.parent_name_2) {
          dispatch(updateBasicInfo("proposedLowIncomeDeviationAmoun2", ""));
          dispatch(updateBasicInfo("requestLowIncomeDeviationP2", ""));
        }
      } else if (currentArrayKey === "judicial_discretion_for_low_income") {
        if (deviation_is_for === workSheetStates.parent_name_1) {
          dispatch(updateBasicInfo("judicialDiscretionAppliedAmount1", ""));
          dispatch(updateBasicInfo("requestLowIncomeDeviationP1", ""));
        } else if (deviation_is_for === workSheetStates.parent_name_2) {
          dispatch(updateBasicInfo("judicialDiscretionAppliedAmount2", ""));
          dispatch(updateBasicInfo("requestLowIncomeDeviationP2", ""));
        }
      }

      // Reset states and close the modal
      setIsEditMode(false);
      setIsEdit("");
      setLowIncomeDeviation(false);
      setModalDataEmpty();
      setjudicialDiscretionlowIncomeDeviation(false);

      // Close the delete confirmation modal
      setDeleteModalOpen(false);
    } else {
      console.error("Cannot delete item. Missing item ID or array key.");
    }
  };

  //modal data empty code
  const setModalDataEmpty = () => {
    setLowIncomeDeviation(false);
    // handleLowIncomeDeviationClose();
    // handleJDLowIncomeDeviationClose();
    setjudicialDiscretionlowIncomeDeviation(false);

    setProposedLowIncome49Data((prev) => {
      return {
        ...prev,
        deviation_is_for: "",
        number_of_children: workSheetStates.add_child_to_worksheet?.length,
        max_amount_low_income_deviation: "",
        proposed_low_income_deviation: "",
        low_income_deviation: "",
        low_income_deviation_explanation: "",
      };
    });

    setJudicialDiscretionLowIncome49Data((prev) => {
      return {
        ...prev,
        deviation_is_for: "",
        number_of_children: workSheetStates.add_child_to_worksheet?.length,
        max_amount_low_income_deviation: "",
        proposed_low_income_deviation: "",
        low_income_deviation: "",
        low_income_deviation_explanation: "",
      };
    });

    setSpeNonspecificDeviationData((prev) => {
      return {
        ...prev,
        deviation_type: "",
        combined_adjusted_income_above_40000: "",
        deviation_is_for: "",
        proposed_deviation_amount: "",
        judicial_discretion_applied_deviation_amount: "",
      };
    });
    setExtraEducationalExpensesData((prev) => {
      return {
        ...prev,
        child_name: "",
        tuition_room_board_books_yearly: "",
        other_extraordinary_expenses_yearly: "",
        paid_by: "",
      };
    });
    setExtraMedicalExpensesData((prev) => {
      return {
        ...prev,
        child_name: "",
        paid_by: "",
        extraordinary_medical_expenses_yearly: "",
      };
    });
    setExtraSpecialExpensesData((prev) => {
      return {
        ...prev,
        child_name: "",
        amount_paid_yearly: "",
        paid_by: "",
        explanation_of_expense: "",
      };
    });
    setFormProposedLowIncomeErrors({});
    setIsEdit("");
  };

  /// handle Proposed Low Income Deviation Input Onchange
  const handleProposedLowIncomeInputChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | SelectChangeEvent
      | ChangeEvent<HTMLTextAreaElement>,
    modalName: string
  ) => {
    const { name, value } = event.target;
    const validTwoDecimalRegex = /^-?\d*\.?\d{0,2}$/;

    let tempDeviationCalculation = {
      lineDeviationValue1: 0,
      lineDeviationValue2: 0,
    };

    tempDeviationCalculation =
      finalCalculationForGrossAndAdjustedTabDeviation(workSheetStates);

    switch (modalName) {
      case "LowIncomeDeviation":
        if (name === "deviation_is_for") {
          // Find the matching item based on the selected dropdown value
          const matchingItem =
            workSheetStates.proposed_low_income_deviation.find(
              (item) => item.deviation_is_for === value
            );
          setProposedLowIncome49Data((prev) => ({
            ...prev,
            [name]: value,
            low_income_deviation_explanation: matchingItem
              ? matchingItem.low_income_deviation_explanation
              : "",
          }));
        } else {
          if (name === "low_income_deviation") {
            if (validTwoDecimalRegex.test(value)) {
              setProposedLowIncome49Data((prev) => ({
                ...prev,
                [name]: value,
              }));
            }
          } else {
            setProposedLowIncome49Data((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        }
        if (name === "deviation_is_for") {
          let calculatedValue = "";
          const numberOfChildren =
            workSheetStates.add_child_to_worksheet.length > 0
              ? workSheetStates.add_child_to_worksheet.filter(
                  (item) => item.child_status === "included"
                ).length
              : 0;

          const totalIncome =
            Number(workSheetStates.total_gross_income1) +
            Number(workSheetStates.total_gross_income2);

          if (value.trim() === workSheetStates.parent_name_1.trim()) {
            if (tempDeviationCalculation.lineDeviationValue1) {
              calculatedValue =
                tempDeviationCalculation.lineDeviationValue1.toString();
            } else {
              calculatedValue = calculateIncomeDeviation(
                Number(workSheetStates.total_gross_income1),
                numberOfChildren,
                totalIncome
              ).toString();
            }
          } else if (value.trim() === workSheetStates.parent_name_2.trim()) {
            if (tempDeviationCalculation.lineDeviationValue2) {
              calculatedValue =
                tempDeviationCalculation.lineDeviationValue2.toString();
            } else {
              calculatedValue = calculateIncomeDeviation(
                Number(workSheetStates.total_gross_income2),
                numberOfChildren,
                totalIncome
              ).toString();
            }
          }

          setProposedLowIncome49Data((prev) => ({
            ...prev,
            ["max_amount_low_income_deviation"]: calculatedValue,
          }));
        }
        break;

      case "JudicialDiscretionLowIncomeDeviation":
        if (name === "deviation_is_for") {
          // Find the matching item based on the selected dropdown value
          const matchingItem =
            workSheetStates.judicial_discretion_for_low_income.find(
              (item) => item.deviation_is_for === value
            );
          setJudicialDiscretionLowIncome49Data((prev) => ({
            ...prev,
            [name]: value,
            low_income_deviation_explanation: matchingItem
              ? matchingItem.low_income_deviation_explanation
              : "",
          }));
        } else {
          if (name === "low_income_deviation") {
            if (validTwoDecimalRegex.test(value)) {
              setJudicialDiscretionLowIncome49Data((prev) => ({
                ...prev,
                [name]: value,
              }));
            }
          } else {
            setJudicialDiscretionLowIncome49Data((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        }
        if (name === "deviation_is_for") {
          let calculatedValue = "";
          let getProposedvalue = "";
          const numberOfChildren =
            workSheetStates.add_child_to_worksheet.length > 0
              ? workSheetStates.add_child_to_worksheet.filter(
                  (item) => item.child_status === "included"
                ).length
              : 0;

          const totalIncome =
            Number(workSheetStates.total_gross_income1) +
            Number(workSheetStates.total_gross_income2);

          if (value.trim() === workSheetStates.parent_name_1.trim()) {
            if (tempDeviationCalculation.lineDeviationValue1) {
              calculatedValue =
                tempDeviationCalculation.lineDeviationValue1.toString();
            } else {
              calculatedValue = calculateIncomeDeviation(
                Number(workSheetStates.total_gross_income1),
                numberOfChildren,
                totalIncome
              ).toString();
            }

            getProposedvalue = workSheetStates.proposedLowIncomeDeviationAmoun1;
          } else if (value.trim() === workSheetStates.parent_name_2.trim()) {
            if (tempDeviationCalculation.lineDeviationValue2) {
              calculatedValue =
                tempDeviationCalculation.lineDeviationValue2.toString();
            } else {
              calculatedValue = calculateIncomeDeviation(
                Number(workSheetStates.total_gross_income2),
                numberOfChildren,
                totalIncome
              ).toString();
            }

            getProposedvalue = workSheetStates.proposedLowIncomeDeviationAmoun2;
          }

          setJudicialDiscretionLowIncome49Data((prev) => ({
            ...prev,
            ["max_amount_low_income_deviation"]: calculatedValue,
            ["proposed_low_income_deviation"]: getProposedvalue,
          }));
        }
        break;

      case "SpecificNonspecificDeviations":
        if (
          name === "proposed_deviation_amount" ||
          name === "judicial_discretion_applied_deviation_amount"
        ) {
          if (validTwoDecimalRegex.test(value)) {
            setSpeNonspecificDeviationData((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        } else {
          setSpeNonspecificDeviationData((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
        break;

      case "ExtrEducationalExpenses":
        if (
          name === "tuition_room_board_books_yearly" ||
          name === "other_extraordinary_expenses_yearly"
        ) {
          if (validTwoDecimalRegex.test(value)) {
            setExtraEducationalExpensesData((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        } else {
          setExtraEducationalExpensesData((prev) => ({
            ...prev,
            [name]: value,
          }));
        }

        if (
          name === "tuition_room_board_books_yearly" ||
          name === "other_extraordinary_expenses_yearly"
        ) {
          setFormProposedLowIncomeErrors((prevErrors) => ({
            ...prevErrors,
            ["tuition_room_board_books_yearly"]: "",
          }));
        }
        break;

      case "ExtraordinaryMedicalExpenses":
        if (name === "extraordinary_medical_expenses_yearly") {
          if (validTwoDecimalRegex.test(value)) {
            setExtraMedicalExpensesData((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        } else {
          setExtraMedicalExpensesData((prev) => ({
            ...prev,
            [name]: value,
          }));
        }

        break;

      case "ExtraSpecialExpenses":
        if (name === "amount_paid_yearly") {
          if (validTwoDecimalRegex.test(value)) {
            setExtraSpecialExpensesData((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        } else {
          setExtraSpecialExpensesData((prev) => ({
            ...prev,
            [name]: value,
          }));
        }

        break;

      default:
        break;
    }

    // Clear the error for the current field
    setFormProposedLowIncomeErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  /// input onBlur Input onChange
  const handleGetDeviationAmount = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const formattedValue = parseFloat(value).toFixed(2);
    const finalValue = isNaN(Number(formattedValue)) ? "" : formattedValue;
    setProposedLowIncome49Data((prev) => {
      return {
        ...prev,
        [name]: finalValue,
      };
    });
  };
  const RedAsteriskTypography = styled(Typography)({
    "& span": {
      color: "#FF0000",
    },
  });
  const updateSpecificNonspecificDeviationRecordSum = (
    parent1Total: number,
    parent2Total: number
  ) => {
    dispatch(
      updateBasicInfo(
        "proposed_Deviation_Total1",
        parent1Total !== undefined ? parent1Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "proposed_Deviation_Total2",
        parent2Total !== undefined ? parent2Total.toString() : "0"
      )
    );
  };
  ////add Specific / Nonspecific Deviations data
  const handleAddSpecificNonspecificDeviations = () => {
    const formErrors = AddSpecificNonspecificDeviationsValidation(
      speNonspecificDeviationData
    );
    setFormProposedLowIncomeErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });
    const isFormValid = Object.keys(formErrors).length === 0;
    if (isFormValid) {
      const {
        deviation_type,
        combined_adjusted_income_above_40000,
        deviation_is_for,
        proposed_deviation_amount,
        judicial_discretion_applied_deviation_amount,
      } = speNonspecificDeviationData;
      const newData = {
        arrayKey: "specific_nonspecific_deviation",
        itemId: isEdit ? isEdit : "",
        newItemValues: {
          deviation_type,
          combined_adjusted_income_above_40000,
          deviation_is_for,
          proposed_deviation_amount: proposed_deviation_amount
            ? proposed_deviation_amount
            : "0",
          judicial_discretion_applied_deviation_amount:
            judicial_discretion_applied_deviation_amount
              ? judicial_discretion_applied_deviation_amount
              : "0",
        },
      };
      const updatedArray = isEdit
        ? workSheetStates.specific_nonspecific_deviation.map((item) =>
            item.id === isEdit ? { ...item, ...newData.newItemValues } : item
          )
        : [
            ...workSheetStates.specific_nonspecific_deviation,
            newData.newItemValues,
          ];

      const parentsTotal = getTotalProposedDeviation(updatedArray);
      const parent1Total = parentsTotal[workSheetStates.parent_name_1];
      const parent2Total = parentsTotal[workSheetStates.parent_name_2];

      updateSpecificNonspecificDeviationRecordSum(parent1Total, parent2Total);

      dispatch(updateArrayInfo(newData));
      setSpecificDeviations(false);
      setModalDataEmpty();
    }
  };
  // update Specific Nonspecific Deviation function
  const editSpecificNonspecificDeviation = (
    item: AddspeNonspecificDeviation
  ) => {
    setIsEdit(item.id!);
    setSpeNonspecificDeviationData((prev) => {
      return {
        ...prev,
        deviation_type: item.deviation_type,
        combined_adjusted_income_above_40000:
          item.combined_adjusted_income_above_40000,
        deviation_is_for: item.deviation_is_for,
        proposed_deviation_amount: item.proposed_deviation_amount,
        judicial_discretion_applied_deviation_amount:
          item.judicial_discretion_applied_deviation_amount,
      };
    });
    const updatedArray = workSheetStates.specific_nonspecific_deviation.map(
      (el) => (el.id === item.id ? { ...el, ...item } : el)
    );

    // Calculate the totals using the updated array
    const parentsTotal = getTotalProposedDeviation(updatedArray);
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    updateSpecificNonspecificDeviationRecordSum(parent1Total, parent2Total);

    setSpecificDeviations(true);
  };
  /// delete Specific Nonspecific Deviation modal open
  const deletSpecificNonspecificModalOpen = (id: string) => {
    setDeleteTblId(id);
    setDeleteModal(true);
    setIsDeletedModal("SpecificNonspecific");
  };
  ///delete Specific Nonspecific Deviation data
  const deletSpecificNonspecificDeviation = () => {
    dispatch(
      deleteFromArray({
        arrayKey: "specific_nonspecific_deviation",
        itemId: deleteTblId,
      })
    );
    const updatedArray = workSheetStates.specific_nonspecific_deviation.filter(
      (item) => item.id !== deleteTblId
    );

    // Calculate the totals using the updated array
    const parentsTotal = getTotalProposedDeviation(updatedArray);
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    updateSpecificNonspecificDeviationRecordSum(parent1Total, parent2Total);
    setDeleteModal(false);
  };

  /// handle set Extraordinary Educational Expenses rexud store data
  const updateExtraEducationalExpensesRecordSum = (
    parent1Total: number,
    parent2Total: number,
    parentsTotal: ParentsTotal
  ) => {
    dispatch(
      updateBasicInfo(
        "extraordinary_educational_expenses_total1",
        parent1Total !== undefined ? parent1Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "extraordinary_educational_expenses_total2",
        parent2Total !== undefined ? parent2Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "extraordinary_educational_expenses_total3",
        parentsTotal["Nonparent Custodian"] !== undefined
          ? parentsTotal["Nonparent Custodian"].toString()
          : "0"
      )
    );
  };

  ////add Extraordinary Educational Expenses data
  const handleAddExtraEducationalExpenses = () => {
    const formErrors = AddExtraEducationalExpensesValidation(
      extraEducationalExpensesData
    );
    setFormProposedLowIncomeErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });
    const isFormValid = Object.keys(formErrors).length === 0;
    if (isFormValid) {
      const {
        child_name,
        tuition_room_board_books_yearly,
        other_extraordinary_expenses_yearly,
        paid_by,
      } = extraEducationalExpensesData;
      const newData = {
        arrayKey: "extra_ordinary_educational_expenses",
        itemId: isEdit ? isEdit : "",
        newItemValues: {
          child_name,
          tuition_room_board_books_yearly,
          other_extraordinary_expenses_yearly,
          paid_by,
        },
      };
      const updatedArray = isEdit
        ? workSheetStates.extra_ordinary_educational_expenses.map((item) =>
            item.id === isEdit ? { ...item, ...newData.newItemValues } : item
          )
        : [
            ...workSheetStates.extra_ordinary_educational_expenses,
            newData.newItemValues,
          ];

      // Calculate the totals using the updated array
      const parentsTotal = getTotalExtraordinaryEducationalSums(
        updatedArray,
        workSheetStates.add_child_to_worksheet
      );
      const parent1Total = parentsTotal[workSheetStates.parent_name_1];
      const parent2Total = parentsTotal[workSheetStates.parent_name_2];

      dispatch(updateArrayInfo(newData));
      updateExtraEducationalExpensesRecordSum(
        parent1Total ? Number(parent1Total) : 0,
        parent2Total ? Number(parent2Total) : 0,
        parentsTotal
      );

      setEducationalExpenses(false);
      setModalDataEmpty();
    }
  };
  // update Extra Ordinary Educational Expenses function
  const editExtraOrdinaryEducationalExpenses = (
    item: AddExtraEducationalExpenses
  ) => {
    setIsEdit(item.id!);
    setExtraEducationalExpensesData((prev) => {
      return {
        ...prev,
        child_name: item.child_name,
        tuition_room_board_books_yearly: item.tuition_room_board_books_yearly
          ? item.tuition_room_board_books_yearly
          : "0",
        other_extraordinary_expenses_yearly:
          item.other_extraordinary_expenses_yearly
            ? item.other_extraordinary_expenses_yearly
            : "0",
        paid_by: item.paid_by,
      };
    });
    const updatedArray =
      workSheetStates.extra_ordinary_educational_expenses.map((el) =>
        el.id === item.id ? { ...el, ...item } : el
      );

    // Calculate the totals using the updated array
    const parentsTotal = getTotalExtraordinaryEducationalSums(
      updatedArray,
      workSheetStates.add_child_to_worksheet
    );
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    updateExtraEducationalExpensesRecordSum(
      parent1Total,
      parent2Total,
      parentsTotal
    );
    setEducationalExpenses(true);
  };
  /// delete Extra Ordinary Educational Expenses modal open
  const deletExtraOrdinaryEducationalModalOpen = (id: string) => {
    setDeleteTblId(id);
    setDeleteModal(true);
    setIsDeletedModal("ExtraOrdinaryEducational");
  };
  ///delete Extra Ordinary Educational Expenses code
  const deletExtraOrdinaryEducational = () => {
    dispatch(
      deleteFromArray({
        arrayKey: "extra_ordinary_educational_expenses",
        itemId: deleteTblId,
      })
    );
    const updatedArray =
      workSheetStates.extra_ordinary_educational_expenses.filter(
        (item) => item.id !== deleteTblId
      );

    const parentsTotal = getTotalExtraordinaryEducationalSums(
      updatedArray,
      workSheetStates.add_child_to_worksheet
    );
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    updateExtraEducationalExpensesRecordSum(
      parent1Total,
      parent2Total,
      parentsTotal
    );

    setDeleteModal(false);
  };
  /// handle set Extraordinary Medical Expenses rexud store data
  const updateExtraMedicalExpensesRecordSum = (
    parent1Total: number,
    parent2Total: number,
    parentsTotal: ParentsTotal
  ) => {
    dispatch(
      updateBasicInfo(
        "extraordinary_medical_expenses_total1",
        parent1Total !== undefined ? parent1Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "extraordinary_medical_expenses_total2",
        parent2Total !== undefined ? parent2Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "extraordinary_medical_expenses_total3",
        parentsTotal["Nonparent Custodian"] !== undefined
          ? parentsTotal["Nonparent Custodian"].toString()
          : "0"
      )
    );
  };

  ////add Extraordinary Medical Expenses data
  const handleAddExtraordinaryMedicalExpenses = () => {
    const formErrors = AddExtraordinaryMedicalExpensesValidation(
      extraMedicalExpensesData
    );
    setFormProposedLowIncomeErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });
    const isFormValid = Object.keys(formErrors).length === 0;
    if (isFormValid) {
      const { child_name, paid_by, extraordinary_medical_expenses_yearly } =
        extraMedicalExpensesData;
      const newData = {
        arrayKey: "extra_ordinary_medical_expenses",
        itemId: isEdit ? isEdit : "",
        newItemValues: {
          child_name,
          paid_by,
          extraordinary_medical_expenses_yearly,
        },
      };

      dispatch(updateArrayInfo(newData));

      const updatedArray = isEdit
        ? workSheetStates.extra_ordinary_medical_expenses.map((item) =>
            item.id === isEdit ? { ...item, ...newData.newItemValues } : item
          )
        : [
            ...workSheetStates.extra_ordinary_medical_expenses,
            newData.newItemValues,
          ];

      // Calculate the totals using the updated array
      const parentsTotal = getTotalExtraordinaryMedicalSums(
        updatedArray,
        workSheetStates.add_child_to_worksheet
      );
      const parent1Total = parentsTotal[workSheetStates.parent_name_1];
      const parent2Total = parentsTotal[workSheetStates.parent_name_2];

      updateExtraMedicalExpensesRecordSum(
        parent1Total,
        parent2Total,
        parentsTotal
      );

      setMedicalExpenses(false);
      setModalDataEmpty();
    }
  };
  // update Extraordinary Medical Expenses function
  const editExtraOrdinaryMedicalExpenses = (item: AddExtraMedicalExpenses) => {
    setIsEdit(item.id!);
    setExtraMedicalExpensesData((prev) => {
      return {
        ...prev,
        child_name: item.child_name,
        paid_by: item.paid_by,
        extraordinary_medical_expenses_yearly:
          item.extraordinary_medical_expenses_yearly,
      };
    });
    const updatedArray = workSheetStates.extra_ordinary_medical_expenses.map(
      (el) => (el.id === item.id ? { ...el, ...item } : el)
    );

    // Calculate the totals using the updated array
    const parentsTotal = getTotalExtraordinaryMedicalSums(
      updatedArray,
      workSheetStates.add_child_to_worksheet
    );
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    updateExtraMedicalExpensesRecordSum(
      parent1Total,
      parent2Total,
      parentsTotal
    );
    setMedicalExpenses(true);
  };
  /// delete Extraordinary Medical Expenses modal open
  const deletExtraOrdinaryMedicalModalOpen = (id: string) => {
    setDeleteTblId(id);
    setDeleteModal(true);
    setIsDeletedModal("ExtraordinaryMedical");
  };
  ///delete Extraordinary Medical Expenses code
  const deletExtraOrdinaryMedical = () => {
    dispatch(
      deleteFromArray({
        arrayKey: "extra_ordinary_medical_expenses",
        itemId: deleteTblId,
      })
    );
    const updatedArray = workSheetStates.extra_ordinary_medical_expenses.filter(
      (item) => item.id !== deleteTblId
    );

    const parentsTotal = getTotalExtraordinaryMedicalSums(
      updatedArray,
      workSheetStates.add_child_to_worksheet
    );
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    updateExtraMedicalExpensesRecordSum(
      parent1Total,
      parent2Total,
      parentsTotal
    );

    setDeleteModal(false);
  };
  /// handle set Extraordinary Special Expenses rexud store data
  const updateExtraSpecialExpensesRecordSum = (
    parent1Total: number,
    parent2Total: number,
    parentsTotal: ParentsTotal
  ) => {
    dispatch(
      updateBasicInfo(
        "special_expenses_child_rearing_total1",
        parent1Total !== undefined ? parent1Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "special_expenses_child_rearing_total2",
        parent2Total !== undefined ? parent2Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "special_expenses_child_rearing_total3",
        parentsTotal["Nonparent Custodian"] !== undefined
          ? parentsTotal["Nonparent Custodian"].toString()
          : "0"
      )
    );
  };
  ////add Extraordinary Special Expenses data
  const handleAddExtraordinarySpecialExpenses = () => {
    const formErrors = AddExtraordinarySpecialExpensesValidation(
      extraSpecialExpensesData
    );
    setFormProposedLowIncomeErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });
    const isFormValid = Object.keys(formErrors).length === 0;
    if (isFormValid) {
      const {
        child_name,
        amount_paid_yearly,
        paid_by,
        explanation_of_expense,
      } = extraSpecialExpensesData;
      const newData = {
        arrayKey: "special_expenses_child_rearing",
        itemId: isEdit ? isEdit : "",
        newItemValues: {
          child_name,
          amount_paid_yearly,
          paid_by,
          explanation_of_expense,
        },
      };

      dispatch(updateArrayInfo(newData));

      const updatedArray = isEdit
        ? workSheetStates.special_expenses_child_rearing.map((item) =>
            item.id === isEdit ? { ...item, ...newData.newItemValues } : item
          )
        : [
            ...workSheetStates.special_expenses_child_rearing,
            newData.newItemValues,
          ];

      // Calculate the totals using the updated array
      const parentsTotal = getTotalExtraordinarySpecialSums(
        updatedArray,
        workSheetStates.add_child_to_worksheet
      );
      const parent1Total = parentsTotal[workSheetStates.parent_name_1];
      const parent2Total = parentsTotal[workSheetStates.parent_name_2];

      updateExtraSpecialExpensesRecordSum(
        parent1Total,
        parent2Total,
        parentsTotal
      );

      setSpecialExpenses(false);
      setModalDataEmpty();
    }
  };
  // update Extraordinary Special Expenses function
  const editExtraOrdinarySpecialExpenses = (item: AddExtraSpecialExpenses) => {
    setIsEdit(item.id!);
    setExtraSpecialExpensesData((prev) => {
      return {
        ...prev,
        child_name: item.child_name,
        amount_paid_yearly: item.amount_paid_yearly,
        paid_by: item.paid_by,
        explanation_of_expense: item.explanation_of_expense,
      };
    });
    const updatedArray = workSheetStates.special_expenses_child_rearing.map(
      (el) => (el.id === item.id ? { ...el, ...item } : el)
    );

    // Calculate the totals using the updated array
    const parentsTotal = getTotalExtraordinarySpecialSums(
      updatedArray,
      workSheetStates.add_child_to_worksheet
    );
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    updateExtraSpecialExpensesRecordSum(
      parent1Total,
      parent2Total,
      parentsTotal
    );
    setSpecialExpenses(true);
  };
  /// delete Extraordinary Special Expenses modal open
  const deletExtraOrdinarySpecialModalOpen = (id: string) => {
    setDeleteTblId(id);
    setDeleteModal(true);
    setIsDeletedModal("ExtraordinarySpecial");
  };
  ///delete Extraordinary Special Expenses code
  const deletExtraOrdinarySpecial = () => {
    dispatch(
      deleteFromArray({
        arrayKey: "special_expenses_child_rearing",
        itemId: deleteTblId,
      })
    );
    const updatedArray = workSheetStates.special_expenses_child_rearing.filter(
      (item) => item.id !== deleteTblId
    );

    const parentsTotal = getTotalExtraordinarySpecialSums(
      updatedArray,
      workSheetStates.add_child_to_worksheet
    );
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    updateExtraSpecialExpensesRecordSum(
      parent1Total,
      parent2Total,
      parentsTotal
    );

    setDeleteModal(false);
  };
  const rows1 = [
    {
      label: "49(a). Proposed Low Income Deviation Amount:",
      input1: "proposedLowIncomeDeviationAmoun1",
      input2: "proposedLowIncomeDeviationAmoun2",
      isRow1: true,
    },
    {
      label: "49(b). Judicial Discretion Applied Amount:",
      input1: "judicialDiscretionAppliedAmount1",
      input2: "judicialDiscretionAppliedAmount2",
      isRow1: false,
    },
  ];

  const rows3 = [
    {
      label:
        "52. Request a Parenting Time Deviation (may be entered as a positive (+) or negative (-) number): ",
      note: "NOTE: The Noncustodial Parent(s) must be selected on the Basic Information Worksheet Tab before a proposed Parenting Time Deviation may be entered.",
      hasTooltip: true,
      tooltipTitle:
        "A Parenting Time deviation may be requested and approved at the discretion of the court or jury as entered for the Noncustodial Parent. There is no formula to use to determine the amount one should enter. The Custodial Parent may request the deviation when the Noncustodial Parent does not exercise court ordered parenting time.",
    },
  ];

  const rows7 = [
    {
      label: "56. Enter a written explanation for EACH deviation requested:",
    },
  ];

  return (
    <>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#E6F5FF",
          border: "1px solid #0464AB80",
          borderRadius: "20px 20px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#0464AB"
          fontSize={14}
          fontWeight="bold"
        >
          <InfoIcon style={{ height: "17px" }} />
          <Box sx={{ pl: { md: "4px", xs: "20px" } }}>
            E. This section concerns Deviations (Special Circumstances)
          </Box>
        </Typography>
        <RedAsteriskTypography
          sx={{ px: 2, pb: 1 }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ pl: "30px" }}>
            Information entered below, including the calculations, will display
            on Schedule E. Please read instructions for each section before
            entering amounts as both MONTHLY and YEARLY amounts are used in
            different areas of the schedule. All Deviations included are
            discretionary with the Court or Jury, which means that the Judge or
            Jury has the final word on whether or not the deviation will be
            included in the child support worksheet calculations and order. The
            parties may agree to the deviations through an agreement, which must
            be approved by the court.{" "}
            <CustomTooltip title="Deviations are an increase to or decrease from the Presumptive Amount of Child Support and are discretionary with the court. Deviations automatically total and display on Line 14 of Schedule E and Line 10 on the Worksheet. You must also answer questions in Boxes B, C and D at Question 56, for each deviation requested.">
              <img
                height={13}
                src={interrogation}
                alt="Info"
                style={{ marginLeft: "5px", verticalAlign: "middle" }}
              />
            </CustomTooltip>
          </Box>
        </RedAsteriskTypography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Typography
            fontWeight="bold"
            fontSize={14}
            color="#022E4C"
            sx={{ p: 2 }}
          >
            Low Income Deviation
          </Typography>
        </Grid>
      </Box>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#F8F8F8",
          border: "1px solid #DFDFDF",
          borderRadius: "5px 5px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px" }}>
            48. Is a Low Income Deviation requested in this case? If yes,
            continue in this section. The court or jury has discretion to allow
            or not allow the Noncustodial Parent to receive a Low Income
            Deviation that will reduce the Presumptive Amount of Child Support.
            See O.C.G.A. § 19-6-15(i)(2)(B).{" "}
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px" }}>
            Select the Help Button (?) to see requirements for Low Income
            Deviation.{" "}
            <CustomTooltip
              title="Weighing Considerations Based Upon Sufficient Evidence:

When considering a Low Income Deviation, see O.C.G.A. § 19-6-15(i)(2)(B). In weighing the income sources and expenses of both parents and taking into account each parent

    Can the noncustodial parent provide evidence sufficient to demonstrate no earning capacity, or does his/her pro rata share of the presumptive amount of child support create an extreme economic hardship for such parent?

    What relative hardship on the custodial parent's household will result from a reduction in the amount of child support? What effect will a reduction in the amount of child support have on the needs of each parent? The needs of the child(ren) for whom child support is being determined? The ability of the noncustodial parent to pay child support?"
            >
              <img
                height={13}
                src={interrogation}
                alt="Info"
                style={{ marginLeft: "5px", verticalAlign: "middle" }}
              />
            </CustomTooltip>
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px" }}>
            If a Low Income Deviation will be included, continue to line 49(a);
            otherwise, continue to line 51. Lines 50(a) and 50(b) are optional
            and may be used to explain why the Noncustodial Parent should be
            granted a Low Income Deviation.{" "}
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px", display: "flex" }}>
            <Typography sx={{ pr: 1 }}>
              <strong>NOTE:</strong> The Noncustodial Parent(s) must be selected
              on the Basic Information Worksheet tab before a proposed Low
              Income Deviation may be entered.{" "}
            </Typography>{" "}
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px", display: "flex" }}>
            <Typography sx={{ pr: 1 }}>
              <strong>Warning:</strong> If the selection of the Noncustodial
              Parent is changed on the Basic Information Worksheet tab, this
              deviation will be removed from the calculations. It will be
              necessary for you to reenter the deviation for the Noncustodial
              Parent.
            </Typography>{" "}
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, py: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px" }}>
            Select 49(a) to Add/Edit/Remove a proposed Low Income Deviation
            amount. Select 49(b) when applying judicial discretion to
            Add/Edit/Remove a Low Income Deviation amount.{" "}
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => handleLowIncomeDeviationOpen("49A")}
                variant="outlined"
                className={style.globalButtonStyle}
              >
                49(a). Add Proposed Low Income Deviation
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => handleLowIncomeDeviationOpen("49B")}
                variant="outlined"
                className={style.globalButtonStyle}
              >
                49(b). Add Judicial Discretion For Low Income Deviation
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Proposed Low Income Deviation Modal */}

        <CustomModal
          open={lowIncomeDeviation}
          onClose={() => {
            handleLowIncomeDeviationClose();
            setIsEditMode(false); // Reset edit mode when closing the modal
          }}
          button3={isEditMode ? "Remove" : undefined}
          onClickButton3={handleDeleteData}
          actionButtonLabel="Save"
          title={
            isEditMode
              ? "49(a). Edit/Remove a Proposed Low Income Deviation"
              : "49(a). Add Proposed Low Income Deviation"
          }
          save={() => addProposedLowIncomeDeviation("Proposed")}
        >
          <Typography color="#022E4C" fontWeight="bold" sx={{ mt: 1 }}>
            SECTION 1. Identify Noncustodial Parent
          </Typography>
          <Typography color="#022E4C" sx={{ mt: 1 }}>
            Instructions in Section 1 are identical for Add, Edit or Remove a
            proposed Low Income Deviation:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            In Section 1, the &quot;Deviation is For&quot; field will display
            the name of the noncustodial parent. The &quot;Number of
            Children&quot; included in the calculations will also display. The
            &quot;Maximum Amount of Low Income Deviation&quot; field will
            display an amount that is used by the calculator to preserve the
            minimum amount of child support allowed. (See O.C.G.A. § 19-6-15(f)
            (2) (B) (vi) and (viii)).
          </Typography>
          <Typography sx={{ mt: 1 }}>
            If both parents are noncustodial parents, use the &quot;Deviation is
            For&quot; drop-down to select the noncustodial parent; enter the
            proposed deviation separately for each noncustodial parent.
          </Typography>

          <Grid container spacing={2} mb={2} mt={1}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <CustomDropdown
                  name="deviation_is_for"
                  label="Deviation Is For"
                  value={proposedLowIncome49Data.deviation_is_for}
                  onChange={(e) =>
                    handleProposedLowIncomeInputChange(e, "LowIncomeDeviation")
                  }
                  options={deviationForSelectedParentsData}
                  placeholder="- Choose Deviation -"
                  required={true}
                  error={formProposedLowIncomeErrors.deviation_is_for}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <CustomInput
                  onChange={() => {}}
                  value={proposedLowIncome49Data.number_of_children}
                  label="Number Of Children"
                  name="number_of_children"
                  type="textarea"
                  placeholder=""
                  disabled
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <CustomInput
                  onChange={() => {}}
                  value={
                    proposedLowIncome49Data.max_amount_low_income_deviation
                  }
                  label="Maximum Amount of Low Income Deviation"
                  name="max_amount_low_income_deviation"
                  type="textarea"
                  placeholder=""
                  disabled
                />
              </Box>
            </Grid>
          </Grid>

          <Typography color="#022E4C" fontWeight="bold" sx={{ mt: 1 }}>
            SECTION 2. Requested Low Income Deviation
          </Typography>
          <Typography color="#022E4C" sx={{ mt: 1 }}>
            Instructions for Section 2:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Add: In Section 2, enter the MONTHLY proposed deviation amount as a
            negative number in the &quot;Proposed Low Income Deviation&quot;
            field. (Exp.: place a dash (-) before the number and tab to the next
            field. The proposed deviation amount will display like this: -200).
            The &quot;Low Income Deviation Explanation&quot; box is optional but
            may be used to explain the reason for proposing a Low Income
            Deviation. Click the Save button.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Edit: In Section 2, edit the previously &quot;Proposed Low Income
            Deviation&quot; amount by entering a negative number. Update the
            &quot;Low Income Deviation Explanation&quot; box, if needed. Click
            the Save button.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Remove: In section 2, Click the Remove button.
          </Typography>

          <Grid container spacing={2} mb={2} mt={1}>
            <Grid item xs={12}>
              <Box mb={2}>
                <CustomInput
                  value={proposedLowIncome49Data.low_income_deviation}
                  label="Low Income Deviation (enter a negative number)"
                  name="low_income_deviation"
                  type="textarea"
                  placeholder="$0.00"
                  onChange={(e) =>
                    handleProposedLowIncomeInputChange(e, "LowIncomeDeviation")
                  }
                  onBlur={handleGetDeviationAmount}
                  required={true}
                  error={formProposedLowIncomeErrors.low_income_deviation}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <TextareaField
                  name="low_income_deviation_explanation"
                  label="Low Income Deviation Explanation"
                  value={
                    proposedLowIncome49Data.low_income_deviation_explanation
                  }
                  onChange={(e) =>
                    handleProposedLowIncomeInputChange(e, "LowIncomeDeviation")
                  }
                  placeholder="Write Something......"
                />
              </Box>
            </Grid>
          </Grid>
        </CustomModal>

        <CustomModal
          open={judicialDiscretionlowIncomeDeviation}
          onClose={handleJDLowIncomeDeviationClose}
          actionButtonLabel="Save"
          onClickButton3={handleDeleteData}
          button3={isEditMode ? "Remove" : undefined}
          title={
            isEditMode
              ? "49(b). Edit/Remove Judicial Discretion For Low Income Deviation"
              : "49(b). Add Judicial Discretion For Low Income Deviation"
          }
          save={() => addProposedLowIncomeDeviation("Judicial")}
        >
          <Typography color="#022E4C" fontWeight="bold" sx={{ mt: 1 }}>
            SECTION 1. Identify Noncustodial Parent
          </Typography>
          <Typography color="#022E4C" sx={{ mt: 1 }}>
            Instructions in Section 1 are identical for Add, Edit or Remove a
            proposed Low Income Deviation:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            In Section 1, the &quot;Deviation is For&quot; field will display
            the name of the noncustodial parent. The &quot;Number of
            Children&quot; included in the calculations will also display. The
            &quot;Maximum Amount of Low Income Deviation&quot; field will
            display an amount that is used by the calculator to preserve the
            minimum amount of child support allowed. (See O.C.G.A. §
            19-6-15(f)(2)(B)(vi) and (viii)). Any Proposed Low Income Deviation
            will display in the &quot;Proposed Low Income Deviation Field.&quot;
          </Typography>
          <Typography sx={{ mt: 1 }}>
            If both parents are noncustodial parents, use the &quot;Deviation is
            For&quot; drop-down to select the noncustodial parent; enter the
            proposed deviation separately for each noncustodial parent.
          </Typography>

          <Grid container spacing={2} mb={2} mt={1}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <CustomDropdown
                  name="deviation_is_for"
                  label="Deviation Is For"
                  value={judicialDiscretionLowIncome49Data.deviation_is_for}
                  onChange={(e) =>
                    handleProposedLowIncomeInputChange(
                      e,
                      "JudicialDiscretionLowIncomeDeviation"
                    )
                  }
                  options={deviationForSelectedParentsData}
                  placeholder="- Choose Deviation -"
                  required={true}
                  error={formProposedLowIncomeErrors.deviation_is_for}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <CustomInput
                  onChange={() => {}}
                  value={judicialDiscretionLowIncome49Data.number_of_children}
                  label="Number Of Children"
                  name="number_of_children"
                  type="textarea"
                  placeholder=""
                  disabled
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <CustomInput
                  onChange={() => {}}
                  value={
                    judicialDiscretionLowIncome49Data.max_amount_low_income_deviation
                  }
                  label="Maximum Amount of Low Income Deviation"
                  name="max_amount_low_income_deviation"
                  type="number"
                  placeholder=""
                  disabled
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <CustomInput
                  onChange={() => {}}
                  value={
                    judicialDiscretionLowIncome49Data.proposed_low_income_deviation
                  }
                  label="Proposed Low Income Deviation"
                  name="proposed_low_income_deviation"
                  type="number"
                  placeholder=""
                  disabled
                />
              </Box>
            </Grid>
          </Grid>

          <Typography color="#022E4C" fontWeight="bold" sx={{ mt: 1 }}>
            SECTION 2. Requested Low Income Deviation
          </Typography>
          <Typography color="#022E4C" sx={{ mt: 1 }}>
            Instructions for Section 2:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Add: In Section 2, enter the MONTHLY proposed deviation amount as a
            negative number in the &quot;Proposed Low Income Deviation&quot;
            field. (Exp.: place a dash (-) before the number and tab to the next
            field. The proposed deviation amount will display like this: -200).
            The &quot;Low Income Deviation Explanation&quot; box is optional but
            may be used to explain the reason for proposing a Low Income
            Deviation. Click the Save button.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Edit: In Section 2, edit the previously &quot;Proposed Low Income
            Deviation&quot; amount by entering a negative number. Update the
            &quot;Low Income Deviation Explanation&quot; box, if needed. Click
            the Save button.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Remove: In section 2, Click the Remove button.
          </Typography>

          <Grid container spacing={2} mb={2} mt={1}>
            <Grid item xs={12}>
              <Box mb={2}>
                <CustomInput
                  onChange={(e) =>
                    handleProposedLowIncomeInputChange(
                      e,
                      "JudicialDiscretionLowIncomeDeviation"
                    )
                  }
                  value={judicialDiscretionLowIncome49Data.low_income_deviation}
                  label="Low Income Deviation (enter a negative number)"
                  name="low_income_deviation"
                  type="textarea"
                  placeholder="$0.00"
                  required={true}
                  error={formProposedLowIncomeErrors.low_income_deviation}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mb={2}>
                <TextareaField
                  name="low_income_deviation_explanation"
                  label="Low Income Deviation Explanation"
                  value={
                    judicialDiscretionLowIncome49Data.low_income_deviation_explanation
                  }
                  onChange={(e) =>
                    handleProposedLowIncomeInputChange(
                      e,
                      "JudicialDiscretionLowIncomeDeviation"
                    )
                  }
                  placeholder="Write Something......"
                />
              </Box>
            </Grid>
          </Grid>
        </CustomModal>
      </Box>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="custom-table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}></TableCell>
                <TableCell className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_1 || "ParentName"}
                  </Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_2 || "ParentName"}
                  </Box>
                </TableCell>
                <TableCell sx={cellHeaderStyle} className={style.headerStyle}>
                  <Box>
                    Action
                    {/* <Interrogation
                      style={{ height: 13, verticalAlign: "middle" }}
                    /> */}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows1.map((row, index) => (
                <TableRow key={index} sx={cellStyle}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={style.firstColumnStyle}
                    style={{ width: "53%" }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="center" className={style.cellStyle}>
                    <CurrencyValue
                      name=""
                      value={
                        row.isRow1
                          ? Number(
                              workSheetStates.proposedLowIncomeDeviationAmoun1
                            ).toFixed(2)
                          : Number(
                              workSheetStates.judicialDiscretionAppliedAmount1
                            ).toFixed(2)
                      }
                      onChange={() => {}}
                      backgroundColor="#CFFAC9"
                    />
                  </TableCell>
                  <TableCell align="center" className={style.cellStyle}>
                    <CurrencyValue
                      name=""
                      value={
                        row.isRow1
                          ? Number(
                              workSheetStates.proposedLowIncomeDeviationAmoun2
                            ).toFixed(2)
                          : Number(
                              workSheetStates.judicialDiscretionAppliedAmount2
                            ).toFixed(2)
                      }
                      onChange={() => {}}
                      backgroundColor="#CFFAC9"
                    />
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "white", maxWidth: "20px" }}
                    align="center"
                    className={style.cellStyle}
                  >
                    <Box>
                      <IconButton
                        onClick={() => {
                          if (row.isRow1) {
                            handleEditTableData(1, "Proposed");
                          } else {
                            handleEditTableData(2, "Judicial");
                          }
                        }}
                        disabled={
                          row.isRow1
                            ? !workSheetStates.proposedLowIncomeDeviationAmoun1 &&
                              !workSheetStates.proposedLowIncomeDeviationAmoun2
                            : !workSheetStates.judicialDiscretionAppliedAmount1 &&
                              !workSheetStates.judicialDiscretionAppliedAmount2
                        }
                        sx={{
                          "&.Mui-disabled": {
                            color: "#E2E0E0",
                            cursor: "not-allowed",
                            pointerEvents: "auto",
                          },
                          color: "inherit",
                        }}
                      >
                        <EditIcon height={13} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell
                  className={style.firstColumnStyle}
                  component="th"
                  scope="row"
                  style={{ width: "53%" }}
                >
                  <Box>
                    50(a). Explanation for Requesting a Low Income Deviation for
                  </Box>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        onChange={() => {}}
                        value={workSheetStates.parent_name_1 || "ParentName"}
                        label="ParentName"
                        name="ParentName"
                        type="textarea"
                        placeholder="ParentName"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  align="center"
                  colSpan={3}
                  style={{ padding: "7px!important" }}
                >
                  <TextareaField
                    name="requestLowIncomeDeviationP1"
                    minRows={4}
                    value={workSheetStates.requestLowIncomeDeviationP1}
                    onChange={() => {}}
                    placeholder=""
                    backgroundColor="#CEFBC9"
                    disabled
                    marginBottomLabel="0px"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={style.firstColumnStyle}
                  component="th"
                  scope="row"
                  style={{ width: "53%" }}
                >
                  <Box>
                    50(a). Explanation for Requesting a Low Income Deviation for
                  </Box>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        onChange={() => {}}
                        value={workSheetStates.parent_name_2 || "ParentName"}
                        label="ParentName"
                        name="ParentName"
                        type="textarea"
                        placeholder="ParentName"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  align="center"
                  colSpan={3}
                  style={{ marginBottom: "0px!important" }}
                >
                  <TextareaField
                    name="requestLowIncomeDeviationP2"
                    minRows={4}
                    value={workSheetStates.requestLowIncomeDeviationP2}
                    onChange={() => {}}
                    placeholder=""
                    backgroundColor="#CEFBC9"
                    disabled
                    marginBottomLabel="0px"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Typography
            fontWeight="bold"
            fontSize={14}
            color="#022E4C"
            sx={{ p: 2 }}
          >
            Specific and Nonspecific Deviations - High Income and Other Amounts{" "}
          </Typography>
        </Grid>
      </Box>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#F8F8F8",
          border: "1px solid #DFDFDF",
          borderRadius: "5px 5px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ px: 2, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ py: 2 }}>
            Enter MONTHLY proposed Specific and Nonspecific Deviation amount(s)
            by selecting the button at 51(a) to &apos;Add&apos;, or 51(b) to
            &apos;Edit/Remove&apos;. The judge or jury will access the same
            buttons to apply judicial discretion. Click the Help question to see
            a list of these deviations.
            <CustomTooltip
              title="
Specific/Non Specific Deviations:
======================
    High Income,
    Health Related Insurance (Dental, Vision),
    Life Insurance,
    Child Care Tax Credit,
    Visitation Travel Expenses,
    Alimony Paid,
    Mortgage,
    Permanency Plan,
    Nonspecific"
            >
              <img
                height={13}
                src={interrogation}
                alt="Info"
                style={{ marginLeft: "5px", verticalAlign: "middle" }}
              />
            </CustomTooltip>
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={handleSpecificDeviationsOpen}
                variant="outlined"
                className={style.globalButtonStyle}
              >
                51(a). Add Specific/Nonspecific Deviations
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        open={specificDeviations}
        onClose={handleSpecificDeviationsClose}
        actionButtonLabel="Save"
        title={
          isEdit
            ? "51(a). Edit Specific/Nonspecific Deviations"
            : "51(a). Add Specific/Nonspecific Deviations"
        }
        save={handleAddSpecificNonspecificDeviations}
      >
        <Typography color="#022E4C" fontWeight="bold" sx={{ mt: 1 }}>
          SECTION 1. Specific and Nonspecific Deviations
        </Typography>
        <Typography color="#022E4C" sx={{ mt: 1 }}>
          Enter only the amount(s) you want the court to consider for the
          deviation and not the monthly amount of the actual expense as a
          proposed deviation from the Presumptive Amount of Child Support. (The
          Specific and Nonspecific Deviation section of Schedule E does not
          represent a financial affidavit.) You will add each deviation
          individually. Use the &quot;Deviation Type&quot; drop-down to select
          the proposed deviation. (A high income amount will display in the next
          field, if applicable to the case.) The &quot;Deviation is For&quot;
          field will display the name of the noncustodial parent. (If both
          parents are noncustodial parents, use the &quot;Deviation is For&quot;
          drop-down to select the noncustodial parent; enter the proposed
          deviation separately for each noncustodial parent.). Use the
          &quot;Proposed Deviation Amount&quot; field to enter the deviation
          amount. Enter amounts as &quot;positive (+)&quot; numbers for upward
          deviations and as &quot;negative (-)&quot; numbers for downward
          deviations. (Example: enter a positive amount when the noncustodial
          parent does not pay the expense, and enter a negative amount when the
          noncustodial parent does pay the expense.) Only the judge or jury will
          use the &quot;Judicial Discretion Applied Deviation Amount&quot; field
          to add a deviation. Click the Save button.
        </Typography>

        <Grid container spacing={2} mb={2} mt={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomDropdown
                name="deviation_type"
                label="Deviation Type"
                value={speNonspecificDeviationData.deviation_type}
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "SpecificNonspecificDeviations"
                  )
                }
                options={deviationTypeData}
                placeholder="- Choose Deviation Type -"
                error={formProposedLowIncomeErrors.deviation_type}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <TextareaField
                name="combined_adjusted_income_above_40000"
                label="Combined Adjusted Income of the parents that is greater than $40,000/month"
                minRows={2}
                value={
                  speNonspecificDeviationData.combined_adjusted_income_above_40000
                }
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "SpecificNonspecificDeviations"
                  )
                }
                placeholder="$"
                disabled
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomDropdown
                name="deviation_is_for"
                label="Deviation Is For"
                value={speNonspecificDeviationData.deviation_is_for}
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "SpecificNonspecificDeviations"
                  )
                }
                options={deviationForSelectedParentsData}
                placeholder="- Choose Deviation Is For -"
                error={formProposedLowIncomeErrors.deviation_is_for}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomInput
                name="proposed_deviation_amount"
                value={speNonspecificDeviationData.proposed_deviation_amount}
                label="Proposed Deviation Amount"
                placeholder="$"
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "SpecificNonspecificDeviations"
                  )
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomInput
                value={
                  speNonspecificDeviationData.judicial_discretion_applied_deviation_amount
                }
                label="Judicial Discretion Applied Deviation Amount"
                name="judicial_discretion_applied_deviation_amount"
                placeholder="$"
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "SpecificNonspecificDeviations"
                  )
                }
              />
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="custom-table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}>
                  <Box>Deviation Type</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Requested By</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Proposed Deviation Amount</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Judicial Discretion Applied Deviation Amount</Box>
                </TableCell>
                <TableCell sx={cellHeaderStyle} className={style.headerStyle}>
                  <Box>
                    Action{" "}
                    {/* <Interrogation
                      style={{ height: 13, verticalAlign: "middle" }}
                    />{" "} */}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workSheetStates.specific_nonspecific_deviation.map(
                (item, index) => (
                  <TableRow key={index} sx={cellStyle}>
                    <TableCell
                      className={style.cellStyle}
                      component="th"
                      scope="row"
                    >
                      {item.deviation_type}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.deviation_is_for}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.proposed_deviation_amount}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.judicial_discretion_applied_deviation_amount}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ backgroundColor: "white" }}
                      className={style.cellStyle}
                    >
                      <Box display={"flex"} justifyContent={"space-start"}>
                        <IconButton
                          onClick={() => editSpecificNonspecificDeviation(item)}
                        >
                          <EditIcon height={13} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            deletSpecificNonspecificModalOpen(item.id!)
                          }
                        >
                          <DeleteIcon height={13} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Grid container spacing={2} sx={{ pt: 4 }}>
        <Grid item xs={12} md={6} display="flex" sx={{ p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {workSheetStates.parent_name_1 || "ParentName"} Proposed Deviation
            Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(workSheetStates.proposed_Deviation_Total1).toFixed(
                2
              )}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" sx={{ borderRadius: 2, p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {workSheetStates.parent_name_2 || "ParentName"} Proposed Deviation
            Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(workSheetStates.proposed_Deviation_Total2).toFixed(
                2
              )}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Typography
            fontWeight="bold"
            fontSize={14}
            color="#022E4C"
            sx={{ p: 2 }}
          >
            Parenting Time Deviation
          </Typography>
        </Grid>
      </Box>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#F8F8F8",
          border: "1px solid #DFDFDF",
          borderRadius: "5px 5px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ px: 2, py: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px" }}>
            Enter the MONTHLY amount of the proposed Parenting Time Deviation
            for the Noncustodial Parent that is based upon extended parenting
            time as set forth in an existing order, a new order of visitation
            being entered in this action, when the child resides with both
            parents equally, or when visitation rights are not being utilized.
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, py: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px", display: "flex" }}>
            <Typography sx={{ pr: 1 }}>
              <strong>Warning:</strong> If the selection of the Noncustodial
              Parent is changed on the Basic Information Worksheet tab, this
              deviation will be removed from the calculations. It will then be
              necessary for you to reenter the deviation amount for the
              Noncustodial Parent.
            </Typography>{" "}
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table className="custom-table" aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}></TableCell>
                <TableCell className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_1 || "ParentName"}
                  </Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_2 || "ParentName"}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows3.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={style.firstColumnStyle}
                    style={{ width: "53%" }}
                  >
                    <Box>
                      {row.label}
                      {row.hasTooltip && (
                        <CustomTooltip title={row.tooltipTitle}>
                          <Interrogation
                            style={{ height: 13, verticalAlign: "middle" }}
                          />
                        </CustomTooltip>
                      )}
                    </Box>
                    <Box>
                      {row.note && (
                        <Box
                          component="span"
                          sx={{
                            display: "block",
                            marginTop: "8px",
                            fontSize: "12px",
                            color: "#404040",
                          }}
                        >
                          {row.note}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="center" className={style.cellStyle}>
                    <CurrencyValue
                      name="parenting_time_deviation1"
                      value={
                        workSheetStates.parenting_time_deviation1
                          ? workSheetStates.parenting_time_deviation1
                          : ""
                      }
                      onChange={handleInputOnChange}
                      backgroundColor="#CFFAC9"
                      onBlur={uninsuredInputHandle}
                    />
                  </TableCell>
                  <TableCell align="center" className={style.cellStyle}>
                    <CurrencyValue
                      name="parenting_time_deviation2"
                      value={
                        workSheetStates.parenting_time_deviation2
                          ? workSheetStates.parenting_time_deviation2
                          : ""
                      }
                      onChange={handleInputOnChange}
                      backgroundColor="#CFFAC9"
                      onBlur={uninsuredInputHandle}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Typography
            fontWeight="bold"
            fontSize={14}
            color="#022E4C"
            sx={{ p: 2 }}
          >
            Extraordinary Educational Expenses for Children
          </Typography>
        </Grid>
      </Box>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#F8F8F8",
          border: "1px solid #DFDFDF",
          borderRadius: "5px 5px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px" }}>
            Enter YEARLY cost information, on Extraordinary Educational Expenses
            for Children, by selecting the button at 53(a) to &quot;Add,&quot;
            or 53(b) to &quot;Edit/Remove.&quot;
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, py: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px", display: "flex" }}>
            <Typography sx={{ pr: 1 }}>
              <strong>Warning:</strong> Expenses associated with a child will be
              removed from the calculation when the child is either removed from
              the worksheet or excluded from the calculations at line 14(b) on
              the Basic Information Worksheet tab.
            </Typography>
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={handleEducationalExpensesOpen}
                variant="outlined"
                className={style.globalButtonStyle}
              >
                53(a). Add Deviation for Extraordinary Educational Expenses for
                Children
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        open={educationalExpenses}
        onClose={handleEducationalExpensesClose}
        actionButtonLabel="Save"
        title={
          isEdit
            ? "53(a). Edit Deviation for Extraordinary Educational Expenses for Children"
            : "53(a). Add Deviation for Extraordinary Educational Expenses for Children"
        }
        save={handleAddExtraEducationalExpenses}
      >
        <Typography
          color="#022E4C"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: 1 }}
        >
          SECTION 1. Educational Deviations
        </Typography>
        <Typography textAlign="center" color="#022E4C" sx={{ mt: 1 }}>
          Enter separately, for each child, the YEARLY Extraordinary Educational
          Expenses. Children included on the Basic Information Worksheet Tab as
          children in the current case will appear in the &quot;Child Name&quot;
          drop-down. Use the &quot;Child Name&quot; drop-down to select the
          child. Enter yearly amounts of expense(s) using the two fields
          provided. Use the &quot;Paid By&quot; drop-down to identify the
          parent(s) or nonparent custodian who pays the expense. Click the Save
          button.
        </Typography>

        <Grid container spacing={2} mb={2} mt={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomDropdown
                name="child_name"
                label="Child Name"
                value={extraEducationalExpensesData.child_name}
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "ExtrEducationalExpenses"
                  )
                }
                options={childNameData}
                placeholder="- Choose A Child -"
                required
                error={formProposedLowIncomeErrors.child_name}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <CustomInput
                name="tuition_room_board_books_yearly"
                value={
                  extraEducationalExpensesData.tuition_room_board_books_yearly
                }
                label="Tuition, room & board, fees and books (Yearly)"
                type="number"
                placeholder="0.00"
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "ExtrEducationalExpenses"
                  )
                }
                error={
                  formProposedLowIncomeErrors.tuition_room_board_books_yearly
                }
                startAdornment="$"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomInput
                value={
                  extraEducationalExpensesData.other_extraordinary_expenses_yearly
                }
                label="Other extraordinary educational expenses (Yearly)"
                name="other_extraordinary_expenses_yearly"
                type="number"
                placeholder="0.00"
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "ExtrEducationalExpenses"
                  )
                }
                error={
                  formProposedLowIncomeErrors.tuition_room_board_books_yearly
                }
                startAdornment="$"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomDropdown
                name="paid_by"
                label="Paid By"
                value={extraEducationalExpensesData.paid_by}
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "ExtrEducationalExpenses"
                  )
                }
                options={paidByData}
                placeholder="- Choose Who Paid -"
                error={formProposedLowIncomeErrors.paid_by}
                required
              />
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="custom-table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}>
                  <Box>Child Name</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Tuition, room & board, fees and books</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Other extraordinary educational expenses</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Paid By</Box>
                </TableCell>
                <TableCell sx={cellHeaderStyle} className={style.headerStyle}>
                  <Box>
                    Action{" "}
                    {/* <Interrogation
                      style={{ height: 13, verticalAlign: "middle" }}
                    /> */}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workSheetStates.extra_ordinary_educational_expenses?.map(
                (item, index) => (
                  <TableRow key={index} sx={cellStyle}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {item.child_name}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.tuition_room_board_books_yearly}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.other_extraordinary_expenses_yearly}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.paid_by}
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "white" }}
                      align="center"
                      className={style.cellStyle}
                    >
                      <Box display={"flex"} justifyContent={"space-start"}>
                        <IconButton
                          onClick={() =>
                            editExtraOrdinaryEducationalExpenses(item)
                          }
                        >
                          <EditIcon height={13} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            deletExtraOrdinaryEducationalModalOpen(item.id!)
                          }
                        >
                          <DeleteIcon height={13} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Grid container spacing={2} sx={{ pt: 4 }}>
        <Grid item xs={12} md={6} display="flex" sx={{ p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {workSheetStates.parent_name_1 || "ParentName"} Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.extraordinary_educational_expenses_total1
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" sx={{ borderRadius: 2, p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {workSheetStates.parent_name_2 || "ParentName"} Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.extraordinary_educational_expenses_total2
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" sx={{ p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            Nonparent Custodian Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.extraordinary_educational_expenses_total3
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Typography
            fontWeight="bold"
            fontSize={14}
            color="#022E4C"
            sx={{ p: 2 }}
          >
            Extraordinary Medical Expenses for Children{" "}
          </Typography>
        </Grid>
      </Box>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#F8F8F8",
          border: "1px solid #DFDFDF",
          borderRadius: "5px 5px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px" }}>
            Enter YEARLY cost information, on Extraordinary Medical Expenses for
            Children, by selecting the button at 54(a) to &quot;Add,&quot; or
            54(b) to &quot;Edit/Remove.&quot;
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, py: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px", display: "flex" }}>
            <Typography sx={{ pr: 1 }}>
              <strong>Warning:</strong> Expenses associated with a child will be
              removed from the calculation when the child is either removed from
              the worksheet or excluded from the calculations at line 14(b) on
              the Basic Information Worksheet tab.
            </Typography>{" "}
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={handleMedicalExpensesOpen}
                variant="outlined"
                className={style.globalButtonStyle}
              >
                54(a). Add Deviation for Extraordinary Medical Expenses for
                Children
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        open={medicalExpenses}
        onClose={handleMedicalExpensesClose}
        actionButtonLabel="Save"
        title={
          isEdit
            ? "54(a). Edit Deviation for Extraordinary Medical Expenses for Children"
            : "54(a). Add Deviation for Extraordinary Medical Expenses for Children"
        }
        save={handleAddExtraordinaryMedicalExpenses}
      >
        <Typography
          color="#022E4C"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: 1 }}
        >
          SECTION 1. Medical Deviations
        </Typography>
        <Typography textAlign="center" color="#022E4C" sx={{ mt: 1 }}>
          Enter separately, for each child, the YEARLY Extraordinary Medical
          Expenses. Children included on the Basic Information Worksheet Tab as
          children in the current case will appear in the &quot;Child Name&quot;
          drop-down. Use the &quot;Child Name&quot; drop-down to select the
          child. Use the &quot;Paid By&quot; drop-down to identify the parent(s)
          or nonparent custodian who pays the expense. Enter the yearly amount
          of the expense using the field provided. Click the Save button.
        </Typography>

        <Grid container spacing={2} mb={2} mt={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomDropdown
                name="child_name"
                label="Child Name"
                value={extraMedicalExpensesData.child_name}
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "ExtraordinaryMedicalExpenses"
                  )
                }
                options={childNameData}
                placeholder="- Choose A Child -"
                error={formProposedLowIncomeErrors.child_name}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomDropdown
                name="paid_by"
                label="Paid By"
                value={extraMedicalExpensesData.paid_by}
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "ExtraordinaryMedicalExpenses"
                  )
                }
                options={paidByData}
                placeholder="- Choose Who Paid -"
                error={formProposedLowIncomeErrors.paid_by}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomInput
                name="extraordinary_medical_expenses_yearly"
                label="Extraordinary Medical Expenses (Yearly)"
                value={
                  extraMedicalExpensesData.extraordinary_medical_expenses_yearly
                }
                type="number"
                placeholder="$0.00"
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(
                    e,
                    "ExtraordinaryMedicalExpenses"
                  )
                }
                error={
                  formProposedLowIncomeErrors.extraordinary_medical_expenses_yearly
                }
                required
              />
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="custom-table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}>
                  <Box>Child Name</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Paid By</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Extraordinary Medical Expenses</Box>
                </TableCell>
                <TableCell sx={cellHeaderStyle} className={style.headerStyle}>
                  <Box>
                    Action{" "}
                    {/* <Interrogation
                      style={{ height: 13, verticalAlign: "middle" }}
                    /> */}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workSheetStates.extra_ordinary_medical_expenses.map(
                (item, index) => (
                  <TableRow key={index} sx={cellStyle}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {item.child_name}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.paid_by}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.extraordinary_medical_expenses_yearly}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ backgroundColor: "white" }}
                      className={style.cellStyle}
                    >
                      <Box display={"flex"} justifyContent={"space-start"}>
                        <IconButton
                          onClick={() => editExtraOrdinaryMedicalExpenses(item)}
                        >
                          <EditIcon height={13} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            deletExtraOrdinaryMedicalModalOpen(item.id!)
                          }
                        >
                          <DeleteIcon height={13} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Grid container spacing={2} sx={{ pt: 4 }}>
        <Grid item xs={12} md={6} display="flex" sx={{ p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {workSheetStates.parent_name_1 || "ParentName"} Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.extraordinary_medical_expenses_total1
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" sx={{ borderRadius: 2, p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {workSheetStates.parent_name_2 || "ParentName"} Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.extraordinary_medical_expenses_total2
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" sx={{ p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            Nonparent Custodian Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.extraordinary_medical_expenses_total3
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Typography
            fontWeight="bold"
            fontSize={14}
            color="#022E4C"
            sx={{ p: 2 }}
          >
            Special Expenses for Child Rearing{" "}
          </Typography>
        </Grid>
      </Box>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#F8F8F8",
          border: "1px solid #DFDFDF",
          borderRadius: "5px 5px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px" }}>
            Enter YEARLY cost information, on Special Expenses for Child
            Rearing, by selecting the button at 55(a) to &quot;Add,&quot; or
            55(b) to &quot;Edit/Remove.&quot;
          </Box>
        </Typography>
        <Typography
          sx={{ px: 2, py: 1, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px", display: "flex" }}>
            <Typography sx={{ pr: 1 }}>
              <strong>Warning:</strong> Expenses associated with a child will be
              removed from the calculation when the child is either removed from
              the worksheet or excluded from the calculations at line 14(b) on
              the Basic Information Worksheet tab.
            </Typography>
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={handleSpecialExpensesOpen}
                variant="outlined"
                className={style.globalButtonStyle}
              >
                55(a). Add Deviation for Special Expenses for Child Rearing
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        open={specialExpenses}
        onClose={handleSpecialExpensesClose}
        actionButtonLabel="Save"
        title={
          isEdit
            ? "55(a). Edit Deviation for Special Expenses for Child Rearing"
            : "55(a). Add Deviation for Special Expenses for Child Rearing"
        }
        save={handleAddExtraordinarySpecialExpenses}
      >
        <Typography
          color="#022E4C"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: 1 }}
        >
          SECTION 1. Special Expenses Deviations
        </Typography>
        <Typography textAlign="center" color="#022E4C" sx={{ mt: 1 }}>
          Enter separately, for each child, the YEARLY Extraordinary Special
          Expenses. Children included on the Basic Information Worksheet Tab as
          children in the current case will appear in the &quot;Child Name&quot;
          drop-down. Use the &quot;Child Name&quot; drop-down to select the
          child. Use the &quot;Paid By&quot; drop-down to identify the parent(s)
          or nonparent custodian who pays the expense. Enter the yearly amount
          of the expense using the field provided. Click the Save button.
        </Typography>

        <Grid container spacing={2} mb={2} mt={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomDropdown
                name="child_name"
                label="Child Name"
                value={extraSpecialExpensesData.child_name}
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(e, "ExtraSpecialExpenses")
                }
                options={childNameData}
                placeholder="- Choose A Child -"
                error={formProposedLowIncomeErrors.child_name}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomInput
                name="amount_paid_yearly"
                value={extraSpecialExpensesData.amount_paid_yearly}
                label="Amount Paid (Yearly)"
                type="number"
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(e, "ExtraSpecialExpenses")
                }
                placeholder="$0.00"
                error={formProposedLowIncomeErrors.amount_paid_yearly}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomDropdown
                name="paid_by"
                label="Paid By"
                value={extraSpecialExpensesData.paid_by}
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(e, "ExtraSpecialExpenses")
                }
                options={paidByData}
                placeholder="- Choose Who Paid -"
                error={formProposedLowIncomeErrors.paid_by}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <CustomInput
                name="explanation_of_expense"
                value={extraSpecialExpensesData.explanation_of_expense}
                label="Explanation of Expense"
                type="textarea"
                onChange={(e) =>
                  handleProposedLowIncomeInputChange(e, "ExtraSpecialExpenses")
                }
                placeholder="Explanation"
                error={formProposedLowIncomeErrors.explanation_of_expense}
                required
              />
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="custom-table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}>
                  <Box>Child Name</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Amount Paid</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Paid By</Box>
                </TableCell>
                <TableCell className={style.headerStyle}>
                  <Box>Explanation of Expense</Box>
                </TableCell>
                <TableCell sx={cellHeaderStyle} className={style.headerStyle}>
                  <Box>
                    Action{" "}
                    {/* <Interrogation
                      style={{ height: 13, verticalAlign: "middle" }}
                    /> */}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workSheetStates.special_expenses_child_rearing.map(
                (item, index) => (
                  <TableRow key={index} sx={cellStyle}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {item.child_name}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.amount_paid_yearly}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.paid_by}
                    </TableCell>
                    <TableCell align="center" className={style.cellStyle}>
                      {item.explanation_of_expense}
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "white" }}
                      align="center"
                      className={style.cellStyle}
                    >
                      <Box display={"flex"} justifyContent={"space-start"}>
                        <IconButton
                          onClick={() => editExtraOrdinarySpecialExpenses(item)}
                        >
                          <EditIcon height={13} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            deletExtraOrdinarySpecialModalOpen(item.id!)
                          }
                        >
                          <DeleteIcon height={13} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Grid container spacing={2} sx={{ pt: 4 }}>
        <Grid item xs={12} md={6} display="flex" sx={{ p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {workSheetStates.parent_name_1 || "ParentName"} Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.special_expenses_child_rearing_total1
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" sx={{ p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {workSheetStates.parent_name_2 || "ParentName"} Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.special_expenses_child_rearing_total2
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" sx={{ p: 1 }}>
          <Typography
            sx={{
              p: 2,
              background: "#CFFAC9",
              minWidth: "55%",
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            Nonparent Custodian Total:
          </Typography>
          <Box
            sx={{
              background: "#7FDB75",
              p: 1,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <CurrencyValue
              name=""
              value={Number(
                workSheetStates.special_expenses_child_rearing_total3
              ).toFixed(2)}
              onChange={() => {}}
              backgroundColor="#CFFAC9"
              disabled
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Typography
            fontWeight="bold"
            fontSize={14}
            color="#022E4C"
            sx={{ p: 2 }}
          >
            Findings Of Fact{" "}
          </Typography>
        </Grid>
      </Box>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#F8F8F8",
          border: "1px solid #DFDFDF",
          borderRadius: "5px 5px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ p: 2, display: "flex" }}
          color="#404040"
          fontSize={14}
        >
          <Box sx={{ p: "4px", display: "flex" }}>
            <Typography sx={{ pr: 1 }}>
              <strong>Important Requirement:</strong> No Deviations are
              permitted under the law unless all three questions below [ (B),
              (C) and (D) ] are answered. See O.C.G.A. § 19-6-15(i)(1). Explain
              why EACH requested deviation should be allowed. It is recommended
              you begin each explanation by identifying the deviation and parent
              or nonparent custodian requesting the deviation.
            </Typography>{" "}
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <TableContainer style={{ overflowX: "unset" }}>
          <Table className="custom-table" aria-label="customized table">
            <TableBody>
              {rows7.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={style.headerStyle}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={style.headerStyle}
                  ></TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={style.firstColumnStyle}
                  style={{ width: "53%" }}
                >
                  <Box>
                    B. Would the presumptive amount be unjust or inappropriate?
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  className={style.cellStyle}
                  colSpan={2}
                >
                  <TextareaField
                    name="unjust_or_inappropriate"
                    minRows={2}
                    value={workSheetStates.unjust_or_inappropriate}
                    onChange={handleInputOnChange}
                    placeholder=""
                    backgroundColor="#FDFFA6"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={style.firstColumnStyle}
                  style={{ width: "53%" }}
                >
                  <Box>
                    C. Would deviation serve the best interests of the children?{" "}
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  className={style.cellStyle}
                  colSpan={2}
                >
                  <TextareaField
                    name="best_interests_of_children"
                    minRows={2}
                    value={workSheetStates.best_interests_of_children}
                    onChange={handleInputOnChange}
                    placeholder=""
                    backgroundColor="#FDFFA6"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={style.firstColumnStyle}
                  style={{ width: "53%" }}
                >
                  <Box>
                    D. Would deviation seriously impair the ability of the
                    CUSTODIAL parent or NONPARENT Custodian to maintain
                    minimally adequate housing, food, and clothing for the
                    children being supported by the order and to provide other
                    basic necessities?{" "}
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  className={style.cellStyle}
                  colSpan={2}
                >
                  <TextareaField
                    name="impair_ability_to_maintain"
                    minRows={2}
                    value={workSheetStates.impair_ability_to_maintain}
                    onChange={handleInputOnChange}
                    placeholder=""
                    backgroundColor="#FDFFA6"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <WorksheetButtons />
      </Box>
      <Grid item xs={12} md={12}>
        <Grid
          sx={{
            mb: 0,
            borderTop: "2px solid #DFDFDF",
            py: 2,
            px: 1,

            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row", sm: "row" }, // Add this line
          }}
        >
          <Box display="flex">
            <img src={SettingIcons} alt="img" style={{ height: "15px" }} />
            <Link
              onClick={handleReportModalOpen}
              sx={{
                marginLeft: { xs: "2px", md: "5px" },
                marginTop: { xs: "0px", md: 0 },
                color: "#147AFF",
                textDecoration: "underline",
              }}
            >
              Report Technical Issue
            </Link>

            <CustomModal
              open={reportTechnicalModal}
              onClose={handleReportModalClose}
              actionButtonLabel="Save"
              title="Report Technical Issue"
              save={handleSaveData}
              pb="0px"
              borderBottom="0px"
            >
              <ReportTechnicalForm
                ref={formRef}
                inputCaptcha={inputCaptcha}
                handleSubmitTechnicalReport={handleSubmitTechnicalReport}
              />
            </CustomModal>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: { xs: "row", md: "row" } }}
          >
            <Button
              className={style.globalButtonStyle}
              sx={{
                ...buttonStyle,
              }}
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "4"));
              }}
              variant="outlined"
            >
              <span style={{ padding: "0 4px" }}>{"<"}</span> Back Step
            </Button>

            <Button
              variant="contained"
              className={style.globalContainedButton}
              sx={{
                ...buttonStyle,
              }}
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "6"));
              }}
            >
              Next Step <span style={{ padding: "0 4px" }}>{">"}</span>
            </Button>
          </Box>
        </Grid>
        {/* Validation Error Modal with dynamic message */}
        <ValidationErrorModal
          onClose={handleInputValidationErrorModal}
          onCancel={handleInputValidationErrorModalOnCancel}
          open={inputValidationErrorModal}
          title="octestphp.georgiacourts.gov says"
          descriptionMessage={inputValidationMessage}
        />
        <DeleteModal
          onClose={handleDeleteMdlClose}
          open={deleteModal}
          delete={
            isDeletedModal === "ExtraOrdinaryEducational"
              ? deletExtraOrdinaryEducational
              : isDeletedModal === "SpecificNonspecific"
              ? deletSpecificNonspecificDeviation
              : isDeletedModal === "ExtraordinaryMedical"
              ? deletExtraOrdinaryMedical
              : isDeletedModal === "ExtraordinarySpecial"
              ? deletExtraOrdinarySpecial
              : () => {}
          }
          title="Delete Deviation Records"
          deleteMessage="Are you sure you want permanently remove this record from the system?"
        />
        {/* LowDaviation Delete modal */}

        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          delete={confirmDeleteData} // Confirm and delete the item
          title="Delete Deviation Records"
          deleteMessage="Are you sure you want to permanently remove this record from the system?"
        />
      </Grid>
    </>
  );
};

export default DeviationsSchedule;
