import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../assets/images/info.svg";
import { useAppDispatch, useAppSelector } from "../../../globalState/hooks";
import { updateBasicInfo } from "../../../globalState/actions/create-worksheet-action";
import CurrencyInput from "../../../components/currencyInput";
import CurrencyValue from "../../../components/currencyInputWithoutSelect";
import CustomInput from "../../../components/inputField";
import TextareaField from "../../../components/text-area";
import CustomDropdown from "../../../components/drop-down";
import WorksheetButtons from "../../../components/worksheetButtons";
import SettingIcons from "../../../assets/images/SettingIcon.svg";
import style from "../workSheet.module.css";
import CustomModal from "../../../components/customModal";
import ReportTechnicalForm, {
  ReportTechnicalFormHandle,
} from "../../../components/reportTechnicalIssue";
import { GenerateCaptcha } from "../../../utils/generateCaptcha";
import technicalReportService from "../../../services/technicalReport-service";
import { showToast } from "../../../components/toastNotification";
import { BusinessTypeData, grossIncomeOptions } from "../../../utils/constants";
import { CreateWorksheetInitialState } from "../../../types/create-worksheet";
import {
  selfEmpBusinessIncomeType1,
  selfEmpBusinessIncomeType2,
} from "../../../types/create-worksheet/selfEmpoymentTabtype";
import { InputIncomecalculation } from "../../../utils/inputIncomecalculation";
import ValidationErrorModal from "../../../components/validationErrorModal";
import CustomTooltip from "../../../components/tooltip";
import interrogation from "../../../assets/images/interrogation.svg";

interface TechnicalReportInput {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
}

const SelfEmploymentTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const formRef = useRef<ReportTechnicalFormHandle>(null);
  const workSheetStates = useAppSelector((state) => state.createWorksheet);

  const [reportTechnicalModal, setReportTechnicalModal] =
    useState<boolean>(false);
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [inputValidationErrorModal, setInputValidationErrorModal] =
    useState(false);
  const [inputValidationMessage, setInputValidationMessage] = useState("");
  const [parentInputValue, setParentInputValue] = useState(0);
  const [hoursInputValue, setHoursInputValue] = useState("");
  const [perWeekHoursError, setPerWeekHoursError] = useState("");
  const [perWeekHours, setPerWeekHours] = useState("");
  const [hourlyModal, setHourlyModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleReportModalOpen = () => {
    setInputCaptcha(GenerateCaptcha);
    setReportTechnicalModal(true);
  };
  const handleReportModalClose = () => setReportTechnicalModal(false);

  //// API calling function start

  /// API called for Technical Report
  const handleSubmitTechnicalReport = async (
    reportInputValues: TechnicalReportInput
  ) => {
    const inputData = {
      worksheet_id: Number(reportInputValues.worksheet_id),
      name: reportInputValues.name !== "" ? reportInputValues.name : null,
      email: reportInputValues.email,
      phone:
        reportInputValues.phone !== "" ? Number(reportInputValues.phone) : null,
      browser:
        reportInputValues.browser !== "" ? reportInputValues.browser : null,
      technical_issue: reportInputValues.technical_issue,
    };
    await technicalReportService
      .reportTechnicalIssueAPI(inputData)
      .then((response) => {
        if (response && response.data) {
          showToast(response.data.message, "success");
          setReportTechnicalModal(false);
        } else {
          showToast(response?.data?.message, "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };
  //// API calling function end

  //  function to calculate the difference between two values
  const calculateDifference = (
    key1: string,
    key2: string,
    states: CreateWorksheetInitialState
  ): string => {
    const value1 =
      Number(states[key1 as keyof CreateWorksheetInitialState]) || 0;
    const value2 =
      Number(states[key2 as keyof CreateWorksheetInitialState]) || 0;
    return (value1 - value2).toFixed(2);
  };

  //  calculate the sum
  const calculateSum = (
    keys: string[],
    states: CreateWorksheetInitialState
  ): string => {
    const finalSumData = keys.reduce((acc, key) => {
      const value =
        Number(states[key as keyof CreateWorksheetInitialState]) || 0;
      return acc + value;
    }, 0);
    return finalSumData.toFixed(2);
  };

  const getParent1InputTotalMinus = (
    states: CreateWorksheetInitialState
  ): string => {
    return calculateDifference(
      selfEmpBusinessIncomeType1[0],
      selfEmpBusinessIncomeType1[1],
      states
    );
  };

  const getParent2InputTotalMinus = (
    states: CreateWorksheetInitialState
  ): string => {
    return calculateDifference(
      selfEmpBusinessIncomeType2[0],
      selfEmpBusinessIncomeType2[1],
      states
    );
  };

  const calculateTotalBusinessExpenses1 = (
    states: CreateWorksheetInitialState
  ): string => {
    const keys = rows3.map((item) => item.input1);
    return calculateSum(keys, states);
  };

  const calculateTotalBusinessExpenses2 = (
    states: CreateWorksheetInitialState
  ): string => {
    const keys = rows3.map((item) => item.input2);
    return calculateSum(keys, states);
  };

  // const calculateNetIncome = (grossProfitKey: string, expensesKey: string, states: CreateWorksheetInitialState): string => {
  //   return calculateDifference(grossProfitKey, expensesKey, states);
  // };
  const calculateNetIncome1 = (states: CreateWorksheetInitialState): string => {
    const grossProfit = parseFloat(states["equals_gross_profit1"]) || 0;
    const businessExpenses =
      parseFloat(states["total_business_expenses1"]) || 0;

    // Example calculation for net income
    const netIncome = grossProfit - businessExpenses;

    return netIncome.toString();
  };
  // const calculateNetIncome2 = (states: CreateWorksheetInitialState): string => {
  //   return calculateNetIncome("equals_gross_profit2", "total_business_expenses2", states);
  // };
  const calculateNetIncome2 = (states: CreateWorksheetInitialState): string => {
    const grossProfit = parseFloat(states["equals_gross_profit2"]) || 0;
    const totalBusinessExpenses =
      parseFloat(states["total_business_expenses2"]) || 0;

    const netIncome = grossProfit - totalBusinessExpenses;
    states["net_income2"] = netIncome.toString();

    return netIncome.toString();
  };

  const calculateTotalNonDeductibleExpenses1 = (
    states: CreateWorksheetInitialState
  ): string => {
    const keys = rows5.map((item) => item.input1);
    return calculateSum(keys, states);
  };

  const calculateTotalNonDeductibleExpenses2 = (
    states: CreateWorksheetInitialState
  ): string => {
    const keys = rows5.map((item) => item.input2);
    return calculateSum(keys, states);
  };

  const calculateTotalSelfEmploymentIncome = (
    netIncomeKey: string,
    nonDeductibleExpensesKey: string,
    states: CreateWorksheetInitialState
  ): string => {
    return calculateSum([netIncomeKey, nonDeductibleExpensesKey], states);
  };

  const calculateTotalSelfEmploymentIncome1 = (
    states: CreateWorksheetInitialState
  ): string => {
    return calculateTotalSelfEmploymentIncome(
      "net_income1",
      "total_non_deductible_expenses1",
      states
    );
  };

  const calculateTotalSelfEmploymentIncome2 = (
    states: CreateWorksheetInitialState
  ): string => {
    return calculateTotalSelfEmploymentIncome(
      "net_income2",
      "total_non_deductible_expenses2",
      states
    );
  };

  /// handle Parent1 Input Onchange code
  const handleParent1InputChange =
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      // const validTwoDecimalRegex = /^\d*\.?\d{0,2}$/;
      // if (validTwoDecimalRegex.test(value)) {
        if (fieldName === "a_compensation_to_owner1") {
          if (value !== "NaN") {
            dispatch(updateBasicInfo("compensation_to_owner1", value));
          }
        }

        dispatch(updateBasicInfo(fieldName, value));

        const updatedState = {
          ...workSheetStates,
          [fieldName]: value,
        };
        const finalSumData = getParent1InputTotalMinus(updatedState);
        updatedState.equals_gross_profit1 = finalSumData.toString();
        dispatch(
          updateBasicInfo("equals_gross_profit1", finalSumData.toString())
        );

        const totalBusinessExpenses1 =
          calculateTotalBusinessExpenses1(updatedState);
        updatedState.total_business_expenses1 = totalBusinessExpenses1;
        dispatch(
          updateBasicInfo("total_business_expenses1", totalBusinessExpenses1)
        );
        // Calculate and update net income
        const netIncome1 = calculateNetIncome1(updatedState);
        dispatch(updateBasicInfo("net_income1", netIncome1));

        const totalNonDeductibleExpenses1 =
          calculateTotalNonDeductibleExpenses1(updatedState);
        dispatch(
          updateBasicInfo(
            "total_non_deductible_expenses1",
            totalNonDeductibleExpenses1
          )
        );

        const totalSelfEmploymentIncome1 =
          calculateTotalSelfEmploymentIncome1(updatedState);
        dispatch(
          updateBasicInfo(
            "total_self_employment_income1",
            totalSelfEmploymentIncome1
          )
        );
      // }
    };

  /// handle Parent1 drop-down Onchange code
  const handleParent1DropdownChange =
    (fieldName: string) => (value: string) => {
      const currentValue =
        workSheetStates[fieldName as keyof CreateWorksheetInitialState] || "0";

      setHoursInputValue(fieldName);

      if (Number(currentValue) === 0 || isNaN(Number(currentValue))) {
        setInputValidationMessage(
          "You must enter a number before it can be converted"
        );
        setInputValidationErrorModal(true);
      } else {
        if (value !== "Hourly") {
          const getInputResult = InputIncomecalculation(
            Number(currentValue),
            0,
            value
          );
          if (fieldName === "a_compensation_to_owner1") {
            if (getInputResult !== "NaN") {
              dispatch(
                updateBasicInfo("compensation_to_owner1", getInputResult)
              );
            }
          }

          dispatch(updateBasicInfo(fieldName, getInputResult));

          const updatedState = {
            ...workSheetStates,
            [fieldName]: getInputResult,
          };

          const finalSumData = getParent1InputTotalMinus(updatedState);
          updatedState.equals_gross_profit1 = finalSumData.toString();
          dispatch(
            updateBasicInfo("equals_gross_profit1", finalSumData.toString())
          );

          const totalBusinessExpenses1 =
            calculateTotalBusinessExpenses1(updatedState);
          updatedState.total_business_expenses1 = totalBusinessExpenses1;
          dispatch(
            updateBasicInfo("total_business_expenses1", totalBusinessExpenses1)
          );

          const netIncome1 = calculateNetIncome1(updatedState);
          updatedState.net_income1 = netIncome1;
          dispatch(updateBasicInfo("net_income1", netIncome1));

          const totalNonDeductibleExpenses1 =
            calculateTotalNonDeductibleExpenses1(updatedState);
          updatedState.total_non_deductible_expenses1 =
            totalNonDeductibleExpenses1;
          dispatch(
            updateBasicInfo(
              "total_non_deductible_expenses1",
              totalNonDeductibleExpenses1
            )
          );

          const totalSelfEmploymentIncome1 =
            calculateTotalSelfEmploymentIncome1(updatedState);
          updatedState.total_self_employment_income1 =
            totalSelfEmploymentIncome1;
          dispatch(
            updateBasicInfo(
              "total_self_employment_income1",
              totalSelfEmploymentIncome1
            )
          );
        } else {
          setParentInputValue(Number(currentValue));
          setPerWeekHoursError("");
          setPerWeekHours("");
          setHourlyModal(!hourlyModal);
        }
      }
    };

  /// handle Parent2 Input Onchange code
  const handleParent2InputChange =
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      // const validTwoDecimalRegex = /^\d*\.?\d{0,2}$/;
      // if (validTwoDecimalRegex.test(value)) {
        if (fieldName === "a_compensation_to_owner2") {
          if (value !== "NaN") {
            dispatch(updateBasicInfo("compensation_to_owner2", value));
          }
        }

        dispatch(updateBasicInfo(fieldName, value));

        const updatedState = {
          ...workSheetStates,
          [fieldName]: value,
        };
        const finalSumData = getParent2InputTotalMinus(updatedState);
        dispatch(
          updateBasicInfo("equals_gross_profit2", finalSumData.toString())
        );

        const totalBusinessExpenses2 =
          calculateTotalBusinessExpenses2(updatedState);
        dispatch(
          updateBasicInfo("total_business_expenses2", totalBusinessExpenses2)
        );

        const netIncome2 = calculateNetIncome2(updatedState);
        dispatch(updateBasicInfo("net_income2", netIncome2));

        const totalNonDeductibleExpenses2 =
          calculateTotalNonDeductibleExpenses2(updatedState);
        dispatch(
          updateBasicInfo(
            "total_non_deductible_expenses2",
            totalNonDeductibleExpenses2
          )
        );

        const totalSelfEmploymentIncome2 =
          calculateTotalSelfEmploymentIncome2(updatedState);
        dispatch(
          updateBasicInfo(
            "total_self_employment_income2",
            totalSelfEmploymentIncome2
          )
        );
      // }
    };

  /// handle Parent2 drop-down Onchange code
  const handleParent2DropdownChange =
    (fieldName: string) => (value: string) => {
      const currentValue =
        workSheetStates[fieldName as keyof CreateWorksheetInitialState] || "0";

      setHoursInputValue(fieldName);

      if (Number(currentValue) === 0 || isNaN(Number(currentValue))) {
        setInputValidationMessage(
          "You must enter a number before it can be converted"
        );
        setInputValidationErrorModal(true);
      } else {
        if (value !== "Hourly") {
          const getInputResult = InputIncomecalculation(
            Number(currentValue),
            0,
            value
          );
          if (fieldName === "a_compensation_to_owner2") {
            if (value !== "NaN") {
              dispatch(updateBasicInfo("compensation_to_owner2", value));
            }
          }
          dispatch(updateBasicInfo(fieldName, getInputResult));

          const updatedState = {
            ...workSheetStates,
            [fieldName]: getInputResult,
          };
          const finalSumData = getParent2InputTotalMinus(updatedState);
          updatedState.equals_gross_profit2 = finalSumData.toString();
          dispatch(
            updateBasicInfo("equals_gross_profit2", finalSumData.toString())
          );

          const totalBusinessExpenses2 =
            calculateTotalBusinessExpenses2(updatedState);
          updatedState.total_business_expenses2 = totalBusinessExpenses2;
          dispatch(
            updateBasicInfo("total_business_expenses2", totalBusinessExpenses2)
          );

          const netIncome2 = calculateNetIncome2(updatedState);
          updatedState.net_income2 = netIncome2;
          dispatch(updateBasicInfo("net_income2", netIncome2));

          const totalNonDeductibleExpenses2 =
            calculateTotalNonDeductibleExpenses2(updatedState);
          updatedState.total_non_deductible_expenses2 =
            totalNonDeductibleExpenses2;
          dispatch(
            updateBasicInfo(
              "total_non_deductible_expenses2",
              totalNonDeductibleExpenses2
            )
          );
        } else {
          setParentInputValue(Number(currentValue));
          setPerWeekHoursError("");
          setPerWeekHours("");
          setHourlyModal(!hourlyModal);
        }
      }
    };

  const handleSaveHoursData = () => {
    if (perWeekHours) {
      const isParent1 = hoursInputValue.includes("1");

      const getInputResult = InputIncomecalculation(
        parentInputValue,
        Number(perWeekHours),
        "Hourly"
      );
      dispatch(updateBasicInfo(hoursInputValue, getInputResult));

      const updatedState = {
        ...workSheetStates,
        [hoursInputValue]: getInputResult,
      };

      // Calculate and update Gross Profit
      const finalSumData = isParent1
        ? getParent1InputTotalMinus(updatedState)
        : getParent2InputTotalMinus(updatedState);
      updatedState[
        isParent1 ? "equals_gross_profit1" : "equals_gross_profit2"
      ] = finalSumData.toString();
      dispatch(
        updateBasicInfo(
          isParent1 ? "equals_gross_profit1" : "equals_gross_profit2",
          finalSumData.toString()
        )
      );

      // Recalculate and update Total Business Expenses
      const totalBusinessExpenses = isParent1
        ? calculateTotalBusinessExpenses1(updatedState)
        : calculateTotalBusinessExpenses2(updatedState);
      updatedState[
        isParent1 ? "total_business_expenses1" : "total_business_expenses2"
      ] = totalBusinessExpenses;
      dispatch(
        updateBasicInfo(
          isParent1 ? "total_business_expenses1" : "total_business_expenses2",
          totalBusinessExpenses
        )
      );

      // Calculate and update Net Income
      const netIncome = isParent1
        ? calculateNetIncome1(updatedState)
        : calculateNetIncome2(updatedState);
      updatedState[isParent1 ? "net_income1" : "net_income2"] = netIncome;
      dispatch(
        updateBasicInfo(isParent1 ? "net_income1" : "net_income2", netIncome)
      );

      //   Total Non-Deductible Expenses
      const totalNonDeductibleExpenses = isParent1
        ? calculateTotalNonDeductibleExpenses1(updatedState)
        : calculateTotalNonDeductibleExpenses2(updatedState);
      updatedState[
        isParent1
          ? "total_non_deductible_expenses1"
          : "total_non_deductible_expenses2"
      ] = totalNonDeductibleExpenses;
      dispatch(
        updateBasicInfo(
          isParent1
            ? "total_non_deductible_expenses1"
            : "total_non_deductible_expenses2",
          totalNonDeductibleExpenses
        )
      );

      //  Total Self-Employment Income
      const totalSelfEmploymentIncome = isParent1
        ? calculateTotalSelfEmploymentIncome1(updatedState)
        : calculateTotalSelfEmploymentIncome2(updatedState);
      dispatch(
        updateBasicInfo(
          isParent1
            ? "total_self_employment_income1"
            : "total_self_employment_income2",
          totalSelfEmploymentIncome
        )
      );
      setHourlyModal(false);
      setTimeout(() => {
        setPerWeekHours("");
        setPerWeekHoursError("");
      }, 0);
    } else {
      setPerWeekHoursError(
        "Number of Hours Must be between 1 and 174, Please Try Again"
      );
    }
  };

  /// handle per week hours Input Onchange
  const handleHoursInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numberValue = Number(value);

    if (numberValue > 0 && numberValue <= 174) {
      setPerWeekHours(value);
      setPerWeekHoursError("");
    } else {
      setPerWeekHoursError("Please enter a value between 1 and 174.");
    }
  };

  /// handle Save data code
  const handleSaveData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };
  /// handle Hourly modal code
  const handleHourlyModal = () => {
    setHourlyModal(!hourlyModal);
  };
  ///handle Input Validation Error Modal
  const handleInputValidationErrorModal = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);
  };

  ///handle TextArea
  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    dispatch(updateBasicInfo(name, value));
  };

  ///handle transfer Gross value from self Employment tab
  const handleTransferGrossValue = () => {
    const value1 = workSheetStates.total_self_employment_income1;
    const value2 = workSheetStates.total_self_employment_income2;
    if (Number(value1) > 0) {
      const grossValue1 = Number(workSheetStates.total_gross_income1);
      const finalSumData1 = grossValue1 + Number(value1);
      dispatch(updateBasicInfo("self_employment_income1", value1));
      dispatch(
        updateBasicInfo("total_gross_income1", finalSumData1.toString())
      );
    } else {
      dispatch(updateBasicInfo("self_employment_income1", "0"));
    }
    if (Number(value2) > 0) {
      const grossValue2 = Number(workSheetStates.total_gross_income2);
      const finalSumData2 = grossValue2 + Number(value2);
      dispatch(updateBasicInfo("self_employment_income2", value2));
      dispatch(
        updateBasicInfo("total_gross_income2", finalSumData2.toString())
      );
    } else {
      dispatch(updateBasicInfo("self_employment_income2", "0"));
    }
    dispatch(updateBasicInfo("activeTab", "2"));
  };
  const cellStyleSelfEmpHeading = {
    border: "1px solid #ccc",
    textAlign: "center",
    padding: "15px 20px 15px 10px",
    background: "#F8F8F8",
  };

  const rows = [
    {
      label: "1. Description of Business",
      input1: "business_description1",
      input2: "business_description2",
    },
    {
      label: "2. Name of Business",
      input1: "business_name1",
      input2: "business_name2",
    },
    {
      label: "3. Type of Business",
      input1: "business_type1",
      input2: "business_type2",
    },
  ];

  const rows2 = [
    {
      label: "4. Gross Receipts (A)",
      input1: "gross_receipts1",
      input2: "gross_receipts2",
    },
    {
      label: "5. Cost of Sales (B)",
      input1: "cost_of_sales1",
      input2: "cost_of_sales2",
    },
  ];
  const rows3 = [
    {
      label: "7(a) Compensation to owner",
      input1: "a_compensation_to_owner1",
      input2: "a_compensation_to_owner2",
    },
    {
      label: "7(b) Other salaries and wages",
      input1: "other_salaries_wages1",
      input2: "other_salaries_wages2",
    },
    {
      label: "7(c) Advertising/promotion",
      input1: "advertising_promotion1",
      input2: "advertising_promotion2",
    },
    {
      label: "7(d) Car and truck expenses",
      input1: "car_truck_expenses1",
      input2: "car_truck_expenses2",
    },
    {
      label: "7(e) Depreciation",
      input1: "depreciation1",
      input2: "depreciation2",
    },
    {
      label: "7(f) Employee benefits (including medical insurance)",
      input1: "employee_benefits1",
      input2: "employee_benefits2",
    },
    {
      label: "7(g) Insurance - business",
      input1: "insurance_business1",
      input2: "insurance_business2",
    },
    {
      label: "7(h) Interest",
      input1: "interest1",
      input2: "interest2",
    },
    {
      label: "7(i) Office supplies and expenses",
      input1: "office_supplies_expenses1",
      input2: "office_supplies_expenses2",
    },
    {
      label: "7(j) Rent or lease - building",
      input1: "rent_or_lease_building1",
      input2: "rent_or_lease_building2",
    },
    {
      label: "7(k) Rent or lease - equipment",
      input1: "rent_or_lease_equipment1",
      input2: "rent_or_lease_equipment2",
    },
    {
      label: "7(l) Taxes and licenses (including payroll taxes)",
      input1: "taxes_licenses1",
      input2: "taxes_licenses2",
    },
    {
      label: "7(m) Travel and entertainment",
      input1: "travel_entertainment1",
      input2: "travel_entertainment2",
    },
    {
      label: "7(n) Utilities",
      input1: "utilities1",
      input2: "utilities2",
    },
    {
      label: "7(o) Other",
      input1: "other_expenses1",
      input2: "other_expenses2",
    },
  ];

  const rows5 = [
    {
      label:
        "Excessive promotional, travel, vehicle or personal living expenses",
      input1: "excessive_expenses1",
      input2: "excessive_expenses2",
    },
    {
      label: "Home office expenses",
      input1: "home_office_expenses1",
      input2: "home_office_expenses2",
    },
    {
      label: "Equipment depreciation, accelerated depreciation, tax credits",
      input1: "equipment_depreciation1",
      input2: "equipment_depreciation2",
    },
    {
      label: "Other",
      input1: "other1",
      input2: "other2",
    },
  ];

  return (
    <>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#E6F5FF",
          border: "1px solid #0464AB80",
          borderRadius: "20px 20px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{ px: 2, pt: 1, display: "flex" }}
          color="#0464AB"
          fontSize={14}
          fontWeight="bold"
        >
          <InfoIcon style={{ height: "17px" }} />
          <Box sx={{ px: "4px" }}>Self-Employment Calculator</Box>
        </Typography>
        <Typography sx={{ px: 2, pb: 1 }} color="#404040" fontSize={14}>
          <Box sx={{ pl: "30px" }}>
            O.C.G.A. 19-6-15(f)(1)(B) workSheetStates, &quot;Income from
            self-employment includes income from, but not limited to, business
            operations, work as an independent contractor or consultant, sales
            of goods or services, and rental properties, less ordinary and
            reasonable expenses necessary to produce such income. Income from
            self-employment, rent, royalties, proprietorship of a business, or
            joint ownership of a partnership, limited liability company, or
            closely held corporation is defined as gross receipts minus ordinary
            and reasonable expenses required for self-employment or business
            operations.&quot;
          </Box>
        </Typography>
        <Typography
          sx={{ px: 5, pt: 1, display: "flex" }}
          color="#0464AB"
          fontSize={14}
          fontWeight="bold"
        >
          <Box sx={{ p: "4px" }}>Guidance</Box>
        </Typography>
        <Typography sx={{ px: 2, pb: 1 }} color="#404040" fontSize={14}>
          <Box sx={{ pl: "30px" }}>
            o calculate Self-Employment Income, use this calculator OR enter
            Self-Employment income on line 21 of Schedule A Gross Income. Enter
            amounts in yellow colored fields only.
          </Box>
        </Typography>
        <Typography
          sx={{ px: 5, pt: 1, display: "flex" }}
          color="#0464AB"
          fontSize={14}
          fontWeight="bold"
        >
          <Box sx={{ p: "4px" }}>
            Enter monthly self-employment income and business expenses directly
            in a field OR use the drop-down arrow to convert hourly, weekly,
            bi-weekly, semi-monthly or yearly self-employment income and
            business expenses to monthly amounts.
          </Box>
        </Typography>
        <Typography sx={{ px: 2, pb: 1 }} color="#404040" fontSize={14}>
          <Box sx={{ pl: "30px" }}>
            If there is more than one owner of a business, ONLY include the
            self-employment income for a parent associated with this child
            support calculation. Select the &quot;Transfer Self-Employment
            Income&quot; button to transfer the income to Schedule A Line 21.
            Updates must be made on the Self-employment Calculator and
            transferred again to Schedule A.
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 900 }}
            className="custom-table"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}></TableCell>
                <TableCell align="center" className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_1 || "ParentName"}
                  </Box>
                </TableCell>
                <TableCell align="center" className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_2 || "ParentName"}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={style.cellStyleSelfEmp}
                    component="th"
                    scope="row"
                  >
                    {item.label}
                  </TableCell>
                  <TableCell className={style.cellStyleSelfEmp} align="center">
                    {item.input1 === "business_type1" ? (
                      <CustomDropdown
                        minWidth="186px"
                        name={item.input1}
                        value={workSheetStates.business_type1}
                        onChange={(event) =>
                          dispatch(
                            updateBasicInfo(item.input1, event.target.value)
                          )
                        }
                        options={BusinessTypeData}
                        placeholder="Select Type of Business"
                        backgroundColor="#FDFFA6"
                      />
                    ) : (
                      <CustomInput
                        name={item.input1}
                        value={(() => {
                          const value =
                            workSheetStates[
                              item.input1 as keyof CreateWorksheetInitialState
                            ];
                          if (
                            typeof value === "string" ||
                            typeof value === "number"
                          ) {
                            return value;
                          }
                          return "";
                        })()}
                        onChange={(event) =>
                          dispatch(
                            updateBasicInfo(item.input1, event.target.value)
                          )
                        }
                        placeholder="Enter"
                        backgroundColor="#FDFFA6"
                      />
                    )}
                  </TableCell>
                  <TableCell className={style.cellStyleSelfEmp} align="center">
                    {item.input2 === "business_type2" ? (
                      <CustomDropdown
                        minWidth="186px"
                        name={item.input2}
                        value={workSheetStates.business_type2}
                        onChange={(event) =>
                          dispatch(
                            updateBasicInfo(item.input2, event.target.value)
                          )
                        }
                        options={BusinessTypeData}
                        placeholder="Select Type of Business"
                        backgroundColor="#FDFFA6"
                      />
                    ) : (
                      <CustomInput
                        name={item.input2}
                        value={(() => {
                          const value =
                            workSheetStates[
                              item.input2 as keyof CreateWorksheetInitialState
                            ];
                          if (
                            typeof value === "string" ||
                            typeof value === "number"
                          ) {
                            return value;
                          }
                          return "";
                        })()}
                        onChange={(event) =>
                          dispatch(
                            updateBasicInfo(item.input2, event.target.value)
                          )
                        }
                        placeholder="Enter"
                        backgroundColor="#FDFFA6"
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell
                  className="custom-table-cell-head"
                  sx={cellStyleSelfEmpHeading}
                ></TableCell>
                <TableCell
                  className="custom-table-cell-head"
                  align="center"
                  colSpan={2}
                  sx={cellStyleSelfEmpHeading}
                >
                  <Typography fontWeight="bold" color="#404040">
                    Business Income (per month)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              {rows2.map((item2, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={style.cellStyleSelfEmp}
                    component="th"
                    scope="row"
                  >
                    {item2.label}
                    {item2.label === "5. Cost of Sales (B)" && (
                      <CustomTooltip title="Cost of sales. 1) In retailing: the purchase cost or inventory value of merchandise sold during a stated period plus the cost of direct work thereon (as alterations or workroom charges). 2) In manufacturing: the production cost or inventory value of goods sold during a stated period.">
                        <img
                          height={13}
                          src={interrogation}
                          alt="Info"
                          style={{ marginLeft: "5px", verticalAlign: "middle" }}
                        />
                      </CustomTooltip>
                    )}
                  </TableCell>
                  <TableCell className={style.cellStyleSelfEmp} align="center">
                    <CurrencyInput
                      options={grossIncomeOptions}
                      inputValue={(() => {
                        const value =
                          workSheetStates[
                            item2.input1 as keyof CreateWorksheetInitialState
                          ];
                        if (
                          typeof value === "string" ||
                          typeof value === "number"
                        ) {
                          return value;
                        }
                        return "";
                      })()}
                      onInputChange={
                        handleParent1InputChange(item2.input1 || "") as (
                          event: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => void
                      }
                      onDropdownChange={handleParent1DropdownChange(
                        item2.input1 || ""
                      )}
                    />
                  </TableCell>
                  <TableCell className={style.cellStyleSelfEmp} align="center">
                    <CurrencyInput
                      options={grossIncomeOptions}
                      inputValue={(() => {
                        const value =
                          workSheetStates[
                            item2.input2 as keyof CreateWorksheetInitialState
                          ];
                        if (
                          typeof value === "string" ||
                          typeof value === "number"
                        ) {
                          return value;
                        }
                        return "";
                      })()}
                      onInputChange={
                        handleParent2InputChange(item2.input2 || "") as (
                          event: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => void
                      }
                      onDropdownChange={handleParent2DropdownChange(
                        item2.input2 || ""
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableBody>
              <TableRow sx={{ bgcolor: "#CEFBC9" }}>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  component="th"
                  scope="row"
                >
                  <strong> 6. Equals Gross Profit (A-B=C)</strong>
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{
                    background: "#7FDB75",
                  }}
                >
                  <CurrencyValue
                    name="equals_gross_profit1"
                    value={Number(workSheetStates.equals_gross_profit1).toFixed(
                      2
                    )}
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{
                    background: "#7FDB75",
                  }}
                >
                  <CurrencyValue
                    name="equals_gross_profit2"
                    value={Number(workSheetStates.equals_gross_profit2).toFixed(
                      2
                    )}
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className="custom-table-cell-head"
                  colSpan={3}
                  sx={cellStyleSelfEmpHeading}
                >
                  <Typography fontWeight="bold" align="left" color="#404040">
                    7. Business Expenses
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {rows3.map((item3, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={style.cellStyleSelfEmp}
                    component="th"
                    scope="row"
                  >
                    {item3.label}
                  </TableCell>
                  <TableCell className={style.cellStyleSelfEmp} align="center">
                    <CurrencyInput
                      inputValue={(() => {
                        const value =
                          workSheetStates[
                            item3.input1 as keyof CreateWorksheetInitialState
                          ];
                        if (
                          typeof value === "string" ||
                          typeof value === "number"
                        ) {
                          return value;
                        }
                        return "";
                      })()}
                      onInputChange={
                        handleParent1InputChange(item3.input1 || "") as (
                          event: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => void
                      }
                      options={grossIncomeOptions}
                      onDropdownChange={handleParent1DropdownChange(
                        item3.input1 || ""
                      )}
                    />
                  </TableCell>
                  <TableCell className={style.cellStyleSelfEmp} align="center">
                    <CurrencyInput
                      options={grossIncomeOptions}
                      inputValue={(() => {
                        const value =
                          workSheetStates[
                            item3.input2 as keyof CreateWorksheetInitialState
                          ];
                        if (
                          typeof value === "string" ||
                          typeof value === "number"
                        ) {
                          return value;
                        }
                        return "";
                      })()}
                      onInputChange={
                        handleParent2InputChange(item3.input2 || "") as (
                          event: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => void
                      }
                      onDropdownChange={handleParent2DropdownChange(
                        item3.input2 || ""
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              <TableCell
                className={style.cellStyleSelfEmp}
                component="th"
                scope="row"
              >
                7(p) Explanation for 7(o) Other Business Expenses
              </TableCell>
              <TableCell
                className={style.cellStyleSelfEmp}
                align="center"
                colSpan={3}
              >
                <TextareaField
                  name="explanation_other_expenses"
                  minRows={2}
                  value={workSheetStates.explanation_other_expenses}
                  onChange={handleTextareaChange}
                  placeholder=""
                />
              </TableCell>
            </TableBody>

            <TableBody>
              <TableRow sx={{ background: "#CEFBC9 " }}>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  component="th"
                  scope="row"
                >
                  <strong>8. Total Business Expenses (D)</strong>
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="total_business_expenses1"
                    value={workSheetStates.total_business_expenses1 || "0.00"}
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="total_business_expenses2"
                    value={workSheetStates.total_business_expenses2 || "0.00"}
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow sx={{ background: "#CEFBC9 " }}>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  component="th"
                  scope="row"
                >
                  <strong>9. Net Income (C - D = E)</strong>
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="net_income1"
                    backgroundColor="#CFFAC9"
                    value={
                      Number(workSheetStates.net_income1).toFixed(2) || "0.00"
                    }
                    onChange={() => {}}
                    disabled
                  />
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="net_income2"
                    backgroundColor="#CFFAC9"
                    value={
                      Number(workSheetStates.net_income2).toFixed(2) || "0.00"
                    }
                    onChange={() => {}}
                    disabled
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableCell
                className={style.cellStyleSelfEmp}
                component="th"
                scope="row"
              >
                <Box sx={{ fontSize: "12px", paddingY: "8px " }}>
                  10. The following expenses included above are not deductible
                  for child support. See O.C.G.A.§19-6-15(f)(1)(B). Add back
                  each of the following items deducted:
                </Box>
              </TableCell>
              <TableCell
                className={style.cellStyleSelfEmp}
                align="center"
              ></TableCell>
              <TableCell
                className={style.cellStyleSelfEmp}
                align="center"
              ></TableCell>
            </TableBody>
            <TableBody>
              <TableRow sx={{ background: "#CEFBC9 " }}>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  component="th"
                  scope="row"
                >
                  Compensation to owner (Do not include this amount on Schedule
                  A as it is included with self-employment income.)
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="compensation_to_owner1"
                    value={workSheetStates.compensation_to_owner1}
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="compensation_to_owner2"
                    value={workSheetStates.compensation_to_owner2}
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {rows5.map((item5, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={style.cellStyleSelfEmp}
                    component="th"
                    scope="row"
                  >
                    {item5.label}
                  </TableCell>
                  <TableCell className={style.cellStyleSelfEmp} align="center">
                    <CurrencyInput
                      inputValue={(() => {
                        const value =
                          workSheetStates[
                            item5.input1 as keyof CreateWorksheetInitialState
                          ];
                        if (
                          typeof value === "string" ||
                          typeof value === "number"
                        ) {
                          return value;
                        }
                        return "";
                      })()}
                      onInputChange={
                        handleParent1InputChange(item5.input1 || "") as (
                          event: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => void
                      }
                      options={grossIncomeOptions}
                      onDropdownChange={handleParent1DropdownChange(
                        item5.input1 || ""
                      )}
                    />
                  </TableCell>
                  <TableCell className={style.cellStyleSelfEmp} align="center">
                    <CurrencyInput
                      inputValue={(() => {
                        const value =
                          workSheetStates[
                            item5.input2 as keyof CreateWorksheetInitialState
                          ];
                        if (
                          typeof value === "string" ||
                          typeof value === "number"
                        ) {
                          return value;
                        }
                        return "";
                      })()}
                      onInputChange={
                        handleParent2InputChange(item5.input2 || "") as (
                          event: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => void
                      }
                      options={grossIncomeOptions}
                      onDropdownChange={handleParent2DropdownChange(
                        item5.input2 || ""
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              <TableCell
                className={style.cellStyleSelfEmp}
                component="th"
                scope="row"
              >
                <Box>Explanation for Other Non-Deductible Expenses</Box>
              </TableCell>
              <TableCell
                className={style.cellStyleSelfEmp}
                align="center"
                colSpan={3}
              >
                <TextareaField
                  name="explanation_non_deductible_expenses"
                  minRows={2}
                  value={workSheetStates.explanation_non_deductible_expenses}
                  onChange={handleTextareaChange}
                  placeholder=""
                  backgroundColor="#FDFFA6"
                />
              </TableCell>
            </TableBody>

            <TableBody>
              <TableRow sx={{ background: "#CEFBC9 " }}>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  component="th"
                  scope="row"
                >
                  <strong>11. Total Non-Deductible Expenses (F)</strong>
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="total_non_deductible_expenses1"
                    value={
                      workSheetStates.total_non_deductible_expenses1 || "0.00"
                    }
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="total_non_deductible_expenses2"
                    value={
                      workSheetStates.total_non_deductible_expenses2 || "0.00"
                    }
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow sx={{ background: "#CEFBC9 " }}>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  component="th"
                  scope="row"
                >
                  <strong>12. Total Self-Employment Income (E + F)</strong>
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="total_self_employment_income1"
                    value={
                      workSheetStates.total_self_employment_income1 || "0.00"
                    }
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
                <TableCell
                  className={style.cellStyleSelfEmp}
                  align="center"
                  sx={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    name="total_self_employment_income2"
                    value={
                      workSheetStates.total_self_employment_income2 || "0.00"
                    }
                    onChange={() => {}}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableCell
                className={style.cellStyleSelfEmp}
                component="th"
                scope="row"
              >
                <Box>
                  13. If needed, use the box below to enter notes that explain
                  self employment income as entered above.
                </Box>
              </TableCell>
              <TableCell
                className={style.cellStyleSelfEmp}
                align="center"
                colSpan={3}
              >
                <TextareaField
                  name="notes_self_employment_income"
                  minRows={2}
                  value={workSheetStates.notes_self_employment_income}
                  onChange={handleTextareaChange}
                  placeholder=""
                  backgroundColor="#FDFFA6"
                />
              </TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ pt: 4 }}>
        <Typography>
          Amounts from the Self-Employment Calculator will display on Schedule
          A, Line 21 as a positive number for a profit or as zero for a loss.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <WorksheetButtons />
      </Box>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <Grid
          sx={{
            mb: 0,
            borderTop: "2px solid #DFDFDF",
            py: 2,
            px: 1,
            textDecoration: "underline",
            cursor: "pointer",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <img src={SettingIcons} alt="img" style={{ height: "15px" }} />
            <Link
              onClick={handleReportModalOpen}
              sx={{
                marginLeft: "5px",
                color: "#147AFF",
                textDecoration: "underline",
              }}
            >
              Report Technical Issue
            </Link>

            <CustomModal
              open={reportTechnicalModal}
              onClose={handleReportModalClose}
              actionButtonLabel="Save"
              title="Report Technical Issue"
              save={handleSaveData}
              borderBottom="0px"
              pb="0px"
            >
              <ReportTechnicalForm
                ref={formRef}
                inputCaptcha={inputCaptcha}
                handleSubmitTechnicalReport={handleSubmitTechnicalReport}
              />
            </CustomModal>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#0464AB",
                borderRadius: "20px",
                p: 1,
                m: 1,
                fontWeight: 600,
                textTransform: "none",
                width: { xs: "100%", md: "100%" },
                maxWidth: { xs: "none", md: "550px" },
                "&:hover": {
                  backgroundColor: "#FBB522",
                  color: "#022E4C",
                },
              }}
              onClick={handleTransferGrossValue}
            >
              Transfer Self-Employment Income to Gross Income - Schedule A -
              Line 21
            </Button>
          </Box>
        </Grid>
        {/* Custom modal for Hours per week input code */}
        <CustomModal
          open={hourlyModal}
          onClose={handleHourlyModal}
          actionButtonLabel={"Ok"}
          title={"octestphp.georgiacourts.gov says"}
          save={handleSaveHoursData}
        >
          <Grid container spacing={2} mb={2} mt={1}>
            <Grid item xs={12} md={12}>
              <Box>
                <CustomInput
                  onChange={handleHoursInputChange}
                  value={perWeekHours}
                  label="How many hours per week do you work?"
                  name="perWeekHours"
                  type="number"
                  placeholder=""
                  required={true}
                  error={perWeekHoursError}
                />
              </Box>
            </Grid>
          </Grid>
        </CustomModal>
        {/* Validation Error modal  */}
        <ValidationErrorModal
          onClose={handleInputValidationErrorModal}
          open={inputValidationErrorModal}
          title="octestphp.georgiacourts.gov says"
          descriptionMessage={inputValidationMessage}
        />
      </Grid>
    </>
  );
};

export default SelfEmploymentTab;
