import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Grid,
  Link,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../assets/images/info.svg";
import { styled } from "@mui/material/styles";
import CurrencyInput from "../../../components/currencyInput";
import TextareaField from "../../../components/text-area";
import CurrencyValue from "../../../components/currencyInputWithoutSelect";
import CustomInput from "../../../components/inputField";
import style from "../workSheet.module.css";
import SettingIcons from "../../../assets/images/SettingIcon.svg";
import { useAppDispatch, useAppSelector } from "../../../globalState/hooks";
import { updateBasicInfo } from "../../../globalState/actions/create-worksheet-action";
import WorksheetButtons from "../../../components/worksheetButtons";
import CustomTooltip from "../../../components/tooltip";
import interrogation from "../../../assets/images/interrogation.svg";
import ReportTechnicalForm, {
  ReportTechnicalFormHandle,
} from "../../../components/reportTechnicalIssue";
import CustomModal from "../../../components/customModal";
import Checkbox from "@mui/material/Checkbox";
import { GenerateCaptcha } from "../../../utils/generateCaptcha";
import technicalReportService from "../../../services/technicalReport-service";
import { showToast } from "../../../components/toastNotification";
import ValidationErrorModal from "../../../components/validationErrorModal";
import { InputIncomecalculation } from "../../../utils/inputIncomecalculation";
import { CreateWorksheetInitialState } from "../../../types/create-worksheet";
import {
  GrossIncomeScheduleInput1,
  GrossIncomeScheduleInput2,
} from "../../../types/create-worksheet/grossImcomeTabtype";
import { grossIncomeOptions } from "../../../utils/constants";

interface TechnicalReportInput {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
}

const GrossIncomeTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const workSheetStates = useAppSelector((state) => state.createWorksheet);
  console.log(workSheetStates, "***WorkSheetStates***");

  const [reportTechnicalModal, setReportTechnicalModal] = useState(false);
  const [hourlyModal, setHourlyModal] = useState(false);
  const [inputValidationErrorModal, setInputValidationErrorModal] =
    useState(false);
  const [inputValidationMessage, setInputValidationMessage] = useState("");
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [perWeekHours, setPerWeekHours] = useState("");
  const [parentInputValue, setParentInputValue] = useState(0);
  const [hoursInputValue, setHoursInputValue] = useState("");
  const [perWeekHoursError, setPerWeekHoursError] = useState("");
  const [isPerInput1, setIsPerInput1] = useState(false);

  const formRef = useRef<ReportTechnicalFormHandle>(null);
  const handleReportModalOpen = () => {
    setInputCaptcha(GenerateCaptcha);
    setReportTechnicalModal(true);
  };
  const handleReportModalClose = () => setReportTechnicalModal(false);

  /// handle Hourly modal code
  const handleHourlyModal = () => {
    setHourlyModal(!hourlyModal);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSaveData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };
  ///-------------API calling Function start-------------------
  /// APi called for Technical report issue
  const handleSubmitTechnicalReport = async (
    reportInputValues: TechnicalReportInput
  ) => {
    const inputData = {
      worksheet_id: Number(reportInputValues.worksheet_id),
      name: reportInputValues.name !== "" ? reportInputValues.name : null,
      email: reportInputValues.email,
      phone:
        reportInputValues.phone !== "" ? Number(reportInputValues.phone) : null,
      browser:
        reportInputValues.browser !== "" ? reportInputValues.browser : null,
      technical_issue: reportInputValues.technical_issue,
    };
    await technicalReportService
      .reportTechnicalIssueAPI(inputData)
      .then((response) => {
        if (response && response.data) {
          showToast(response.data.message, "success");
          setReportTechnicalModal(false);
        } else {
          showToast(response?.data?.message, "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };
  ///-------------API calling Function end-------------------
  ///handle Input Validation Error Modal
  const handleInputValidationErrorModal = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);
  };
  //// Get Parent Input 1 total
  const getParent1InputTotalSum = (
    states: CreateWorksheetInitialState
  ): string => {
    const finalSumData = GrossIncomeScheduleInput1.reduce((acc, field) => {
      const fieldValue = states[field];
      const numericValue = Number(fieldValue) || 0;
      return acc + numericValue;
    }, 0);

    return finalSumData.toFixed(2);
  };

  /// handle Parent1 Input Onchange code
  const handleParent1InputChange =
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const validTwoDecimalRegex = /^\d*\.?\d{0,2}$/;

      setIsPerInput1(true);

      if (value === "") {
        dispatch(updateBasicInfo(fieldName, ""));
        const finalSumData = getParent1InputTotalSum({
          ...workSheetStates,
          [fieldName]: "0",
        });
        dispatch(updateBasicInfo("total_gross_income1", finalSumData));
      } else {
        if (validTwoDecimalRegex.test(value)) {
          dispatch(updateBasicInfo(fieldName, value));
          const finalSumData = getParent1InputTotalSum(workSheetStates);
          dispatch(updateBasicInfo("total_gross_income1", finalSumData));
        }
      }
    };
  /// handle Parent1 drop-down Onchange code
  const handleParent1DropdownChange =
    (fieldName: string) => (value: string) => {
      const currentValue =
        workSheetStates[fieldName as keyof CreateWorksheetInitialState] || 0;
      setHoursInputValue(fieldName);

      if (currentValue === 0) {
        setInputValidationMessage(
          "You must enter a number before it can be converted"
        );
        setInputValidationErrorModal(true);
      } else {
        setIsPerInput1(true);
        if (value !== "Hourly") {
          const getInputResult = InputIncomecalculation(
            Number(currentValue),
            0,
            value
          );
          dispatch(updateBasicInfo(fieldName, getInputResult));

          const finalSumData = getParent1InputTotalSum({
            ...workSheetStates,
            [fieldName]: getInputResult,
          });
          dispatch(updateBasicInfo("total_gross_income1", finalSumData));
        } else {
          setParentInputValue(Number(currentValue));
          setPerWeekHoursError("");
          setPerWeekHours("");
          setHourlyModal(!hourlyModal);
        }
      }
    };
  //// Get Parent Input 2 total
  const getParent2InputTotalSum = (
    states: CreateWorksheetInitialState
  ): string => {
    const finalSumData = GrossIncomeScheduleInput2.reduce((acc, field) => {
      const fieldValue = states[field];
      const numericValue = Number(fieldValue) || 0;
      return acc + numericValue;
    }, 0);

    return finalSumData.toFixed(2);
  };

  const handleParent2InputChange =
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const validTwoDecimalRegex = /^\d*\.?\d{0,2}$/;
      setIsPerInput1(false);

      if (value === "") {
        dispatch(updateBasicInfo(fieldName, ""));
        const finalSumData = getParent2InputTotalSum({
          ...workSheetStates,
          [fieldName]: "0",
        });
        dispatch(updateBasicInfo("total_gross_income2", finalSumData));
      } else {
        if (validTwoDecimalRegex.test(value)) {
          dispatch(updateBasicInfo(fieldName, value));
          const finalSumData = getParent2InputTotalSum(workSheetStates);
          dispatch(updateBasicInfo("total_gross_income2", finalSumData));
        }
      }
    };

  const handleParent2DropdownChange =
    (fieldName: string) => (value: string) => {
      const currentValue =
        workSheetStates[fieldName as keyof CreateWorksheetInitialState] || 0;

      setHoursInputValue(fieldName);

      if (currentValue === 0) {
        setInputValidationMessage(
          "You must enter a number before it can be converted"
        );
        setInputValidationErrorModal(true);
      } else {
        setIsPerInput1(false);
        if (value !== "Hourly") {
          const getInputResult = InputIncomecalculation(
            Number(currentValue),
            0,
            value
          );

          dispatch(updateBasicInfo(fieldName, getInputResult));

          const finalSumData = getParent2InputTotalSum({
            ...workSheetStates,
            [fieldName]: getInputResult,
          });
          dispatch(updateBasicInfo("total_gross_income2", finalSumData));
        } else {
          setParentInputValue(Number(currentValue));
          setPerWeekHoursError("");
          setPerWeekHours("");
          setHourlyModal(!hourlyModal);
        }
      }
    };

  /// handle per week hours Inout Onchange
  const handleHoursInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numberValue = Number(value);

    if (numberValue > 0 && numberValue <= 174) {
      setPerWeekHours(value);
      setPerWeekHoursError("");
    } else {
      setPerWeekHoursError("Please enter a value between 1 and 174.");
    }
  };
  const handleCheckBoxOnchange =
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      dispatch(updateBasicInfo(fieldName, checked));
    };

  const handleTextAreaOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    dispatch(updateBasicInfo(name, value));
  };

  /// save the hours data base on conditions
  const handleSaveHoursData = () => {
    let finalSumData = "0";
    if (perWeekHours) {
      const getInputResult = InputIncomecalculation(
        parentInputValue,
        Number(perWeekHours),
        "Hourly"
      );
      dispatch(updateBasicInfo(hoursInputValue, getInputResult));

      if (isPerInput1) {
        finalSumData = getParent1InputTotalSum({
          ...workSheetStates,
          [hoursInputValue]: getInputResult,
        });
        dispatch(updateBasicInfo("total_gross_income1", finalSumData));
      } else {
        finalSumData = getParent2InputTotalSum({
          ...workSheetStates,
          [hoursInputValue]: getInputResult,
        });
        dispatch(updateBasicInfo("total_gross_income2", finalSumData));
      }

      setHourlyModal(!hourlyModal);
      setTimeout(() => {
        setPerWeekHours("");
      }, 500);
      return perWeekHours;
    } else {
      setPerWeekHoursError(
        "Number of Hours Must be a between 1 and 174, Please Try Again"
      );
    }
  };

  const RedAsteriskTypography = styled(Typography)({
    "& span": {
      color: "#FF0000",
    },
  });

  const headerStyle = {
    backgroundColor: "#E6F5FF",
    fontWeight: "bold",
    color: "#0464AB",
    border: "1px solid #ccc",
    textAlign: "center",
    padding: "5px 10px",
    fontSize: "12px!important",
  };

  const headerStyle2 = {
    backgroundColor: "#CEFBC9",
    fontWeight: "bold",
    color: "#404040",
    border: "1px solid #ccc",
    textAlign: "left",
    padding: "5px 17px 5px 15px",
    fontSize: "12px!important",
  };

  const table_headingDiv = {
    padding: "5px 10px",
    background: " #B9E3FF",
    borderRadius: "25px",
    border: "1px solid #E2E0E0",
    color: "#404040",
    textAlign: "left",
    fontSize: "12px!important",
  };

  const cellStyle = {
    border: "1px solid #ccc",
    textAlign: "left",
    padding: "5px 20px 5px 15px",
    fontSize: "12px!important",
  };

  const firstColumnStyle = {
    width: "63%",
    border: "1px solid #ccc",
    padding: "5px 10px",
    fontSize: "12px!important",
  };

  const grossIncomeTableRows = [
    {
      label:
        "18. Check box to right, if parent in this action receives Temporary Assistance for Needy Families (TANF)",
      hasTooltip: true,
      tooltipTitle:
        "Temporary Assistance for Needy Families (TANF) are benefits paid to a person who qualifies through the Division of Family of Children Services (DFCS) or a similar program in other states/territories; excludes Medicaid/Food Stamps.  Do not include the monthly TANF amount as income in a child support calculation.",
      input1: "tanf1",
      input2: "tanf2",
    },
    {
      label:
        "19. Gross Salary and Wages (will not include means-tested public assistance, such as TANF or food stamps)",
      hasTooltip: true,
      tooltipTitle:
        "Income from any source before deductions for taxes; excludes needs-based income such as Supplemental Security Income and TANF. Enter income for each parent here and/or in other appropriate fields on this schedule.",
      input1: "gross_salary1",
      input2: "gross_salary2",
    },
    {
      label: "20. Commissions, Fees, and Tips",
      input1: "commissions1",
      input2: "commissions2",
    },
    {
      label: "21. Self-Employment Income",
      hasTooltip: true,
      hasButton: true,
      tooltipTitle:
        "Gross income from business endeavors minus ordinary and reasonable expenses necessary to produce such income.  Use one of two options for entry of this income.      1) Enter income for each parent, or 2) use the Button to access the Self-Employment Calculator.",
      input1: "self_employment_income1",
      input2: "self_employment_income2",
    },
    { label: "22. Bonuses", input1: "bonuses1", input2: "bonuses2" },
    {
      label: "23. Overtime Payments",
      input1: "overtime_payment1",
      input2: "overtime_payment2",
    },
    {
      label: "24. Severance Pay",
      input1: "severence_pay1",
      input2: "severence_pay2",
    },
    {
      label: "25. Recurring Income from Pensions or Retirement Plans",
      input1: "recurring_income1",
      input2: "recurring_income2",
    },
    {
      label: "26. Interest Income",
      input1: "interest_income1",
      input2: "interest_income2",
    },
    {
      label: "27. Income from Dividends",
      input1: "income_from_dividends1",
      input2: "income_from_dividends2",
    },
    {
      label: "28. Trust Income",
      input1: "trust_income1",
      input2: "trust_income2",
    },
    {
      label: "29. Income from Annuities",
      input1: "income_from_annuities1",
      input2: "income_from_annuities2",
    },
    {
      label: "30. Capital Gains",
      input1: "capital_gains1",
      input2: "capital_gains2",
    },
    {
      label:
        "31. Social Security Disability or Retirement Benefits (excludes SSI or payments to children)",
      input1: "social_security_disablity_benefits1",
      input2: "social_security_disablity_benefits2",
      hasTooltip: true,
      tooltipTitle:
        "Social Security Disability or Retirement benefits received as income by a parent in this action (does not include SSI or payments made for children). This sum does represent income to the parent who receives the check from the Social Security Administration. Enter on this line the monthly amount paid to the parent under that parents column. ",
    },
    {
      label: "32. Federal Veterans Disability Benefits",
      input1: "federal_veterans_disability_benefits1",
      input2: "federal_veterans_disability_benefits2",
    },
    {
      label: "33. Workers Compensation Benefits",
      input1: "workers_compensation_benefits1",
      input2: "workers_compensation_benefits2",
    },
    {
      label: "34. Unemployment Benefits",
      input1: "unemployment_benefits1",
      input2: "unemployment_benefits2",
    },
    {
      label: "35. Judgments from Personal Injury or Other Civil Cases",
      input1: "judgements1",
      input2: "judgements2",
    },
    {
      label: "36. Gifts (cash or other gifts that can be converted to cash)",
      input1: "gifts1",
      input2: "gifts2",
    },
    {
      label: "37. Prizes/Lottery Winnings",
      input1: "prizes1",
      input2: "prizes2",
    },
    {
      label: "38. Alimony/Maintenance from persons not in this case",
      input1: "alimony1",
      input2: "alimony2",
    },
    {
      label: "39. Assets which are used for support of family",
      input1: "assets1",
      input2: "assets2",
    },
    {
      label: "40. Fringe Benefits (if significantly reduce living expenses)",
      input1: "fringe_benefits1",
      input2: "fringe_benefits2",
    },
    {
      label:
        "41. Any Other Income, including Imputed Income (do not include means-tested public assistance)",
      note: "NOTE: View information on determining Imputed Income.",
      hasTooltip: false,
      tooltipTitle:
        "When establishing the amount of child support, if a parent fails to produce reliable evidence of income, such as tax returns for prior years, check stubs, or other information for determining current ability to pay child support or ability to pay child support in prior years, and the court or the jury has no other reliable evidence of the parent’s income or income potential, gross income for the current year may be imputed. When imputing income, the court shall take into account the specific circumstances of the parent to the extent known, including such factors as the parent’s assets, residence, employment and earnings history, job skills, educational attainment, literacy, age, health, criminal record and other employment barriers, and record of seeking work, as well as the local job market, the availability of employers willing to hire the parent, prevailing earnings level in the local community, and other relevant background factors in the case. If a parent is incarcerated, the court shall not assume an ability for earning capacity based upon pre-incarceration wages or other employment related income, but income may be imputed based upon the actual income and assets available to such incarcerated parent.",
      input1: "other_income1",
      input2: "other_income2",
    },
  ];

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&::before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  return (
    <>
      <Box>
        <Card
          elevation={0}
          sx={{
            bgcolor: "#E6F5FF",
            border: "1px solid #0464AB80",
            borderRadius: "20px 20px",
            opacity: 1,
          }}
        >
          <Typography
            sx={{ px: 1, pt: 1, display: "flex", alignItems: "center" }}
            color="#0464AB"
            fontSize={14}
            fontWeight="bold"
          >
            <InfoIcon style={{ height: "17px" }} />
            <Box sx={{ p: "4px" }}>A. This section concerns Gross Income.</Box>
          </Typography>
          <RedAsteriskTypography
            sx={{ px: 1, pb: 1 }}
            color="#404040"
            fontSize={14}
          >
            <Box sx={{ pl: "30px" }}>
              Information entered in this section, including the calculations,
              will display on Schedule A. Enter below, on lines 19 through 41,
              the Gross Income as it applies to each Parent. *The monthly Gross
              Income, which is calculated in this section, is required to
              generate a Worksheet. Enter the monthly gross income directly in
              the field OR use the drop-down arrow to convert wages into a
              monthly amount.
            </Box>
          </RedAsteriskTypography>
        </Card>
      </Box>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 900 }}
            className="custom-table"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="custom-table-cell-head"
                  sx={headerStyle}
                ></TableCell>
                <TableCell
                  className="custom-table-cell-head"
                  align="center"
                  sx={headerStyle}
                >
                  <Box sx={table_headingDiv}>
                    {workSheetStates.parent_name_1 || "ParentName"}
                  </Box>
                </TableCell>
                <TableCell
                  className="custom-table-cell-head"
                  align="center"
                  sx={headerStyle}
                >
                  <Box sx={table_headingDiv}>
                    {workSheetStates.parent_name_2 || "ParentName"}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {grossIncomeTableRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="custom-table-cell-body"
                    component="th"
                    scope="row"
                    sx={firstColumnStyle}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          {row.label}
                          {row.hasTooltip && (
                            <CustomTooltip title={row.tooltipTitle}>
                              <img
                                height={13}
                                src={interrogation}
                                alt="Info"
                                style={{
                                  marginLeft: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </CustomTooltip>
                          )}
                        </Box>
                        {row.hasButton && (
                          <Button
                            variant="outlined"
                            className={style.globalButtonStyle}
                            onClick={() => {
                              dispatch(updateBasicInfo("activeTab", "6"));
                            }}
                          >
                            Go To Self-Employment Calculator
                          </Button>
                        )}
                      </Box>
                      {row.note && (
                        <Box
                          component="span"
                          sx={{
                            display: "block",
                            marginTop: "8px",
                            fontSize: "12px",
                            color: "#404040",
                          }}
                        >
                          {row.note}{" "}
                          <CustomTooltip title="When establishing the amount of child support, if a parent fails to produce reliable evidence of income, such as tax returns for prior years, check stubs, or other information for determining current ability to pay child support or ability to pay child support in prior years, and the court or the jury has no other reliable evidence of the parent’s income or income potential, gross income for the current year may be imputed. When imputing income, the court shall take into account the specific circumstances of the parent to the extent known, including such factors as the parent’s assets, residence, employment and earnings history, job skills, educational attainment, literacy, age, health, criminal record and other employment barriers, and record of seeking work, as well as the local job market, the availability of employers willing to hire the parent, prevailing earnings level in the local community, and other relevant background factors in the case. If a parent is incarcerated, the court shall not assume an ability for earning capacity based upon pre-incarceration wages or other employment related income, but income may be imputed based upon the actual income and assets available to such incarcerated parent.">
                            <img
                              height={13}
                              src={interrogation}
                              alt="Info"
                              style={{
                                marginLeft: "5px",
                                verticalAlign: "middle",
                              }}
                            />
                          </CustomTooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    className="custom-table-cell-body"
                    align="center"
                    sx={cellStyle}
                  >
                    {index === 0 ? (
                      <Checkbox
                        sx={{
                          "&:hover": { bgcolor: "transparent" },
                        }}
                        disableRipple
                        checked={workSheetStates.tanf1}
                        color="default"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        inputProps={{ "aria-label": "Checkbox demo" }}
                        onChange={handleCheckBoxOnchange("tanf1")}
                      />
                    ) : (
                      <CurrencyInput
                        options={grossIncomeOptions}
                        inputValue={(() => {
                          const value =
                            workSheetStates[
                              row.input1 as keyof CreateWorksheetInitialState
                            ];
                          if (
                            typeof value === "string" ||
                            typeof value === "number"
                          ) {
                            return value;
                          }
                          return "";
                        })()}
                        onInputChange={
                          handleParent1InputChange(row.input1 || "") as (
                            event: ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >
                          ) => void
                        }
                        onDropdownChange={handleParent1DropdownChange(
                          row.input1 || ""
                        )}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className="custom-table-cell-body"
                    align="center"
                    sx={cellStyle}
                  >
                    {index === 0 ? (
                      <Checkbox
                        sx={{
                          "&:hover": { bgcolor: "transparent" },
                        }}
                        disableRipple
                        checked={workSheetStates.tanf2}
                        color="default"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        inputProps={{ "aria-label": "Checkbox demo" }}
                        onChange={handleCheckBoxOnchange("tanf2")}
                      />
                    ) : (
                      <CurrencyInput
                        options={grossIncomeOptions}
                        inputValue={(() => {
                          const value =
                            workSheetStates[
                              row.input2 as keyof CreateWorksheetInitialState
                            ];
                          if (
                            typeof value === "string" ||
                            typeof value === "number"
                          ) {
                            return value;
                          }
                          return "";
                        })()}
                        onInputChange={
                          handleParent2InputChange(row.input2 || "") as (
                            event: ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >
                          ) => void
                        }
                        onDropdownChange={handleParent2DropdownChange(
                          row.input2
                        )}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableBody>
              <TableRow>
                <TableCell className="custom-table-cell-head" sx={headerStyle2}>
                  TOTAL GROSS MONTHLY INCOME
                </TableCell>
                <TableCell
                  className="custom-table-cell-head"
                  align="center"
                  sx={headerStyle2}
                  style={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    value={workSheetStates.total_gross_income1}
                    CurrencyBackground="#fff"
                    backgroundColor="#CFFAC9"
                    onChange={() => {}}
                    name=""
                    disabled
                  />
                </TableCell>
                <TableCell
                  className="custom-table-cell-head"
                  align="center"
                  sx={headerStyle2}
                  style={{ background: "#7FDB75" }}
                >
                  <CurrencyValue
                    backgroundColor="#CFFAC9"
                    CurrencyBackground="#fff"
                    value={workSheetStates.total_gross_income2}
                    onChange={() => {}}
                    name=""
                    disabled
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell
                  className="custom-table-cell-body"
                  component="th"
                  scope="row"
                  sx={firstColumnStyle}
                >
                  <Box sx={{ fontSize: "12px" }}>
                    41(a). Please explain in the box provided here the basis for
                    Other Income or Imputed Income as entered on Line 41 for
                  </Box>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        onChange={() => {}}
                        value={workSheetStates.parent_name_1}
                        label="ParentName"
                        name="ParentName"
                        type="textarea"
                        placeholder=""
                        disabled
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  className="custom-table-cell-body"
                  align="center"
                  sx={cellStyle}
                  colSpan={2}
                  style={{
                    padding: "10px 7px 5px 7px",
                  }}
                >
                  <TextareaField
                    name="comment1"
                    minRows={4}
                    value={workSheetStates.comment1}
                    onChange={handleTextAreaOnChange}
                    placeholder=""
                    backgroundColor="#FDFFA6"
                    marginBottomLabel="0px"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className="custom-table-cell-body"
                  component="th"
                  scope="row"
                  sx={firstColumnStyle}
                >
                  <Box>
                    41(b). Please explain in the box provided here the basis for
                    Other Income or Imputed Income as entered on Line 41 for
                  </Box>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        onChange={() => {}}
                        value={workSheetStates.parent_name_2}
                        label="ParentName"
                        name="ParentName"
                        type="textarea"
                        placeholder=""
                        disabled
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  className="custom-table-cell-body"
                  align="center"
                  sx={cellStyle}
                  colSpan={2}
                  style={{
                    padding: "10px 7px 5px 7px",
                  }}
                >
                  <TextareaField
                    name="comment2"
                    minRows={4}
                    value={workSheetStates.comment2}
                    onChange={handleTextAreaOnChange}
                    placeholder=""
                    backgroundColor="#FDFFA6"
                    marginBottomLabel="0px"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <WorksheetButtons />
      </Box>
      <Grid item xs={12} md={12}>
        <Grid
          sx={{
            mb: 0,
            borderTop: "2px solid #DFDFDF",
            px: 1,
            py: 2,
            textDecoration: "underline",
            cursor: "pointer",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <img src={SettingIcons} alt="img" style={{ height: "15px" }} />
            <Link
              sx={{
                marginLeft: "5px",
                color: "#147AFF",
                textDecoration: "underline",
              }}
              onClick={handleReportModalOpen}
            >
              Report Technical Issue
            </Link>
            <CustomModal
              open={reportTechnicalModal}
              onClose={handleReportModalClose}
              actionButtonLabel="Save"
              title="Report Technical Issue"
              save={handleSaveData}
              borderBottom="0px"
              pb="0px"
            >
              <ReportTechnicalForm
                ref={formRef}
                inputCaptcha={inputCaptcha}
                handleSubmitTechnicalReport={handleSubmitTechnicalReport}
              />
            </CustomModal>
          </Box>
          <Box sx={{ p: { xs: 2, md: 1 } }}>
            <Button
              className={style.globalButtonStyle}
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "1"));
              }}
              variant="outlined"
              sx={{ marginX: 2 }}
            >
              <span style={{ padding: "0 4px" }}>{"<"}</span> Back Step
            </Button>
            <Button
              variant="contained"
              className={style.globalContainedButton}
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "3"));
              }}
            >
              Next Step <span style={{ padding: "0 4px" }}>{">"}</span>
            </Button>
          </Box>
        </Grid>

        {/* Custom modal for Hours per week input code */}
        <CustomModal
          open={hourlyModal}
          onClose={handleHourlyModal}
          actionButtonLabel={"Ok"}
          title={"octestphp.georgiacourts.gov says"}
          save={handleSaveHoursData}
        >
          <Grid container spacing={2} mb={2} mt={1}>
            <Grid item xs={12} md={12}>
              <Box>
                <CustomInput
                  onChange={handleHoursInputChange}
                  value={perWeekHours}
                  label="How many hours per week do you work?"
                  name="perWeekHours"
                  type="number"
                  placeholder=""
                  required={true}
                  error={perWeekHoursError}
                />
              </Box>
            </Grid>
          </Grid>
        </CustomModal>
        {/* Validation Error modal  */}
        <ValidationErrorModal
          onClose={handleInputValidationErrorModal}
          open={inputValidationErrorModal}
          title="octestphp.georgiacourts.gov says"
          descriptionMessage={inputValidationMessage}
        />
      </Grid>
    </>
  );
};

export default GrossIncomeTab;
