import moment from "moment";

//// generaye Years data for drop-down
export const generateYearDropdown = (startYear: number) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({ value: `${year}`, label: `${year}` });
  }

  return years;
};
//// generaye Years data for drop-down 18 years from current year
export const generate18YearFromCurrentYearDropdown = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 18; // 18 years ago
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push({ value: `${year}`, label: `${year}` });
  }

  return years;
};

export const grossIncomeOptions = [
  "Hourly",
  "Weekly",
  "Bi-Weekly",
  "Semi-Monthly",
  "Yearly",
];

export const BusinessTypeData = [
  { value: "", label: "Select Type of Business" },
  { value: "sole proprietorship", label: "Sole Proprietorship" },
  { value: "llc", label: "LLC" },
  { value: "partnership", label: "Partnership" },
  { value: "s corporation", label: "S Corporation" },
  { value: "rental income", label: "Rental Income" },
];

export const footNotesSchedule = [
  { value: "", label: "Choose Schedule" },
  {
    value: "basic information worksheet",
    label: "Basic Information Worksheet",
  },
  { value: "schedule a", label: "Schedule A" },
  { value: "schedule b", label: "Schedule B" },
  { value: "schedule d", label: "Schedule D" },
  { value: "schedule e", label: "Schedule E" },
];
export const deviationTypeData = [
  { value: "", label: "Choose Deviation Type" },
  { value: "High Income", label: "High Income" },
  {
    value: "Health Related",
    label: "Health Related Insurance (Dental, Vision)",
  },
  { value: "Life Insurance", label: "Life Insurance" },
  { value: "Child Care Tax Credit", label: "Child Care Tax Credit" },
  { value: "Visitation Travel Expenses", label: "Visitation Travel Expenses" },
  { value: "Alimony Paid", label: "Alimony Paid" },
  { value: "Mortgage", label: "Mortgage" },
  { value: "Permanency Plan", label: "Permanency Plan" },
  { value: "Nonspecific", label: "Nonspecific" },
];
export const footNotesScheduleLinesNo = Array.from({ length: 56 }, (_, i) => ({
  label: (i + 1).toString(),
  value: (i + 1).toString(),
}));

export const footNotesScheduleLinesNumber = {
  "Basic Information Worksheet": [
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: "4",
    },
    {
      label: "5",
      value: "5",
    },
    {
      label: "6",
      value: "6",
    },
    {
      label: "7",
      value: "7",
    },
    {
      label: "8",
      value: "8",
    },
    {
      label: "9",
      value: "9",
    },
    {
      label: "10",
      value: "10",
    },
    {
      label: "11",
      value: "11",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "13",
      value: "13",
    },
    {
      label: "14",
      value: "14",
    },
    {
      label: "15 (a)",
      value: "15 (a)",
    },
    {
      label: "15 (b)",
      value: "15 (b)",
    },
    {
      label: "16",
      value: "16",
    },
    {
      label: "17",
      value: "17",
    },
  ],
  "Schedule A": [
    {
      label: "18",
      value: "18",
    },
    {
      label: "19",
      value: "19",
    },
    {
      label: "20",
      value: "20",
    },
    {
      label: "21",
      value: "21",
    },
    {
      label: "22",
      value: "22",
    },
    {
      label: "23",
      value: "23",
    },
    {
      label: "24",
      value: "24",
    },
    {
      label: "25",
      value: "25",
    },
    {
      label: "26",
      value: "26",
    },
    {
      label: "27",
      value: "27",
    },
    {
      label: "28",
      value: "28",
    },
    {
      label: "29",
      value: "29",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "31",
      value: "31",
    },
    {
      label: "32",
      value: "32",
    },
    {
      label: "33",
      value: "33",
    },
    {
      label: "34",
      value: "34",
    },
    {
      label: "35",
      value: "35",
    },
    {
      label: "36",
      value: "36",
    },
    {
      label: "37",
      value: "37",
    },
    {
      label: "38",
      value: "38",
    },
    {
      label: "39",
      value: "39",
    },
    {
      label: "40",
      value: "40",
    },
    {
      label: "41",
      value: "41",
    },
    {
      label: "41 (a)",
      value: "41 (a)",
    },
    {
      label: "41 (b)",
      value: "41 (b)",
    },
    {
      label: "42",
      value: "42",
    },
  ],
  "Schedule B": [
    {
      label: "42",
      value: "42",
    },
    {
      label: "43",
      value: "43",
    },
    {
      label: "44",
      value: "44",
    },
    {
      label: "45 (a)",
      value: "45 (a)",
    },
    {
      label: "45 (b)",
      value: "45 (b)",
    },
  ],
  "Schedule C": [
    {
      label: "46",
      value: "46",
    },
    {
      label: "47",
      value: "47",
    },
  ],
  "Schedule D": [
    {
      label: "48",
      value: "48",
    },
    {
      label: "49",
      value: "49",
    },
    {
      label: "49 (a)",
      value: "49 (a)",
    },
    {
      label: "49 (b)",
      value: "49 (b)",
    },
    {
      label: "50 (a)",
      value: "50 (a)",
    },
    {
      label: "50 (b)",
      value: "50 (b)",
    },
    {
      label: "51",
      value: "51",
    },
    {
      label: "52",
      value: "52",
    },
    {
      label: "53",
      value: "53",
    },
    {
      label: "54",
      value: "54",
    },
    {
      label: "55",
      value: "55",
    },
    {
      label: "56 (b)",
      value: "56 (b)",
    },
    {
      label: "56 (c)",
      value: "56 (c)",
    },
    {
      label: "56 (d)",
      value: "56 (d)",
    },
  ],
  "Schedule ESS": [
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4 (a)",
      value: "4 (a)",
    },
    {
      label: "5 (b)",
      value: "5 (b)",
    },
    {
      label: "6",
      value: "6",
    },
    {
      label: "7 (a)",
      value: "7 (a)",
    },
    {
      label: "7 (b)",
      value: "7 (b)",
    },
    {
      label: "7 (c)",
      value: "7 (c)",
    },
    {
      label: "7 (d)",
      value: "7 (d)",
    },
    {
      label: "7 (e)",
      value: "7 (e)",
    },
    {
      label: "7 (f)",
      value: "7 (f)",
    },
    {
      label: "7 (g)",
      value: "7 (g)",
    },
    {
      label: "7 (h)",
      value: "7 (h)",
    },
    {
      label: "7 (i)",
      value: "7 (i)",
    },
    {
      label: "7 (j)",
      value: "7 (j)",
    },
    {
      label: "7 (k)",
      value: "7 (k)",
    },
    {
      label: "7 (l)",
      value: "7 (l)",
    },
    {
      label: "7 (m)",
      value: "7 (m)",
    },
    {
      label: "7 (n)",
      value: "7 (n)",
    },
    {
      label: "7 (o)",
      value: "7 (o)",
    },
    {
      label: "7 (p)",
      value: "7 (p)",
    },
    {
      label: "8",
      value: "8",
    },
    {
      label: "9",
      value: "9",
    },
    {
      label: "10",
      value: "10",
    },
    {
      label: "11",
      value: "11",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "13",
      value: "13",
    },
  ],
};

export const getFieldName = (item: string) => {
  let fieldName;
  switch (item) {
    case "court_id":
      fieldName = "Court Name";
      break;
    case "county_id":
      fieldName = "Country Name";
      break;
    case "plaintiff_name":
      fieldName = "Plaintiff Name";
      break;
    case "defendant_name":
      fieldName = "Defendant Name";
      break;
    case "parent_name_1":
      fieldName = "Parents Name1";
      break;
    case "parent_name_2":
      fieldName = "Parents Name2";
      break;
    case "add_child_to_worksheet":
      fieldName = "Child Detail";
      break;

    default:
      break;
  }
  return fieldName;
};

export function isValidDate(dateString: string) {
  // Check if the date format is valid and in "MM/DD/YYYY" format
  const isValidFormat = moment(dateString, "MM/DD/YYYY", true).isValid();

  if (isValidFormat) {
    const year = parseInt(dateString.split("/")[2], 10); // Extract the year part
    const currentYear = new Date().getFullYear();

    // Ensure the year is between 1900 and the current year
    if (year < 1900 || year > currentYear) {
      return false;
    }
    return true; // Valid date
  }

  return false; // Invalid format
}

export function isValidTime(timeString: string) {
  // The 'strict' parsing mode is true to ensure strict adherence to the format
  return moment(timeString, "hh:mma", true).isValid(); // 'a' indicates am/pm in lowercase
}

console.log(isValidDate("12/31/2020")); // Expected output: true
console.log(isValidDate("02/29/2021")); // Expected output: false - 2021 is not a leap year

console.log(isValidTime("09:30am")); // Expected output: true
console.log(isValidTime("13:00pm")); // Expected output: false - '13' is not valid in 12-hour format
