import React, { ChangeEvent, useState, useEffect } from "react";
import { Box, Card, SelectChangeEvent, CircularProgress } from "@mui/material";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  CardContent,
} from "@mui/material";
import CustomInput from "../../components/inputField";
import CustomDropdown from "../../components/drop-down";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReCAPTCHA from "react-google-recaptcha";

import signUpService from "../../services/signup-service";
import reCAPTCHAkey from "../../utils/reCAPTCHAkey";
import { useNavigate, Link } from "react-router-dom";
import { showToast } from "../../components/toastNotification";
import { DropDown } from "../../types/global";

interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  selectLanguage: string;
  selectCourtName: string;
  selectCountyName: string;
  barNumber: string;
}
interface CourtData {
  id: number;
  court_name: string;
}
interface CountryData {
  id: number;
  county_name: string;
}
interface ApiResponse<T> {
  data: T;
}
interface UserRoleData {
  id: number;
  role_name: string;
}
interface UserTypeData {
  id: number;
  user_type_name: string;
}
const RegisterForm: React.FC = () => {
  const navigate = useNavigate();

  const [signupData, setSignupData] = useState<RegisterData>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    selectLanguage: "english",
    selectCourtName: "",
    selectCountyName: "",
    barNumber: "",
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<Partial<RegisterData>>({});
  const [courtData, setCourtData] = useState<DropDown[]>([]);
  const [countyData, setCountyData] = useState<DropDown[]>([]);
  const [hover, setHover] = useState<boolean>(false);
  const [recaptchaCode, setRecaptchaCode] = useState<string | null>(null);
  const [isRecaptchaValide, setIsRecaptchaValide] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userRoleData, setUserRoleData] = useState<UserRoleData[]>([]);
  const [userTypeData, setUserTypeData] = useState<UserTypeData[]>([]);

  useEffect(() => {
    handleGetCourtListData();
    handleGetCountyListData();
    handleGetUserRoleListData();
    handleGetUserTypeListData();
  }, []);

  const handleInputSelectOnchange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "firstName" || name === "lastName") {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }
    setSignupData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };
  const handleRecaptcha = (value: string | null) => {
    setRecaptchaCode(value);
    setIsRecaptchaValide("");
  };
  const formValidate = (): boolean => {
    const newErrors: Partial<RegisterData> = {};
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const alphabetRegex = /^[A-Za-z\s]+$/;

    if (!signupData.firstName.trim()) {
      newErrors.firstName = "First Name cannot be blank";
    } else if (specialCharRegex.test(signupData.firstName.trim())) {
      newErrors.firstName = "First Name cannot contain special characters";
    } else if (!alphabetRegex.test(signupData.firstName.trim())) {
      newErrors.firstName = "First Name can only contain alphabets";
    } else if (signupData.firstName.length > 50) {
      newErrors.lastName = "Ensure this field has not more than 50 characters";
    }

    if (!signupData.lastName.trim()) {
      newErrors.lastName = "Last Name cannot be blank";
    } else if (specialCharRegex.test(signupData.lastName.trim())) {
      newErrors.lastName = "Last Name cannot contain special characters";
    } else if (!alphabetRegex.test(signupData.lastName.trim())) {
      newErrors.lastName = "Last Name can only contain alphabets";
    } else if (signupData.lastName.length > 50) {
      newErrors.lastName = "Ensure this field has not more than 50 characters";
    }

    if (!signupData.email.trim()) {
      newErrors.email = "Email Address cannot be blank";
    } else if (!emailRegex.test(signupData.email)) {
      newErrors.email = "Email Address is invalid";
    }

    if (!signupData.password.trim()) {
      newErrors.password = "Password cannot be blank";
    } else if (signupData.password.length < 8) {
      newErrors.password = "Password must be 8 characters or more";
    } else if (!/[A-Z]/.test(signupData.password)) {
      newErrors.password =
        "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(signupData.password)) {
      newErrors.password =
        "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(signupData.password)) {
      newErrors.password = "Password must contain at least one number";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(signupData.password)) {
      newErrors.password =
        "Password must contain at least one special character";
    }

    if (recaptchaCode === null) {
      setIsRecaptchaValide("Please confirm that you are not a bot.");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleGetCourtListData = () => {
    signUpService
      .getCourtListAPI()
      .then((response: { data: ApiResponse<CourtData[]> }) => {
        if (response?.data?.data?.length > 0) {
          const finalData: DropDown[] = response.data.data.map(
            (court: CourtData) => ({
              value: court.id.toString(),
              label: court.court_name,
            })
          );
          setCourtData(finalData);
        } else {
          setCourtData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  const handleGetCountyListData = () => {
    signUpService
      .getCountyListAPI()
      .then((response: { data: ApiResponse<CountryData[]> }) => {
        if (response?.data.data?.length > 0) {
          const finalData: DropDown[] = response.data.data.map(
            (item: CountryData) => ({
              value: item.id.toString(),
              label: item.county_name,
            })
          );
          setCountyData(finalData);
        } else {
          setCountyData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  const handleGetUserRoleListData = () => {
    signUpService
      .getUserRoleListAPI()
      .then((response: { data: ApiResponse<UserRoleData[]> }) => {
        if (response?.data?.data?.length > 0) {
          setUserRoleData(response.data.data);
        } else {
          setUserRoleData([]);
        }
      })
      .catch((error) => {
        console.log(error, "User Information Error");
      });
  };
  const handleGetUserTypeListData = () => {
    signUpService
      .getUserTypeListAPI()
      .then((response: { data: ApiResponse<UserTypeData[]> }) => {
        if (response?.data.data?.length > 0) {
          setUserTypeData(response.data.data);
        } else {
          setUserTypeData([]);
        }
      })
      .catch((error) => {
        console.log(error, "User Information Error");
      });
  };
  const handleSignUpSubmit = async () => {
    if (formValidate()) {
      if (recaptchaCode !== null) {
        setIsLoading(true);
        const roleId = userRoleData.filter((x) => x.role_name === "User")[0].id;
        const userTypeId = userTypeData.filter(
          (x) => x.user_type_name === "ProSe"
        )[0].id;
        const inputData = {
          first_name: signupData.firstName,
          last_name: signupData.lastName,
          email: signupData.email,
          password: signupData.password,
          language: signupData.selectLanguage === "" ? "english" : "english",
          court_id: signupData.selectCourtName,
          county_id: signupData.selectCountyName,
          bar_number: signupData.barNumber,
          user_type_id: userTypeId,
          role_id: roleId,
        };
        await signUpService
          .getSignUpAPI(inputData)
          .then((response) => {
            if (
              response &&
              response.data.message === "User registered successfully"
            ) {
              showToast(response.data.message, "success");
              navigate("/login");
            } else {
              showToast(response?.data?.message, "error");
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error, "API Error");
          });
      } else {
        setIsRecaptchaValide("Please confirm that you are not a bot.");
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            minWidth: 200,
            maxWidth: { xs: 315, sm: 600, md: 750 },
            my: 5,
            mx: 2,
            p: 2,
            bgcolor: "#fff",
            border: "1px solid #DFDFDF",
            borderRadius: "30px 30px",
            opacity: 1,
          }}
        >
          <Box sx={{ marginTop: "2rem" }}>
            <Box>
              <Typography
                variant="h4"
                color="#0464AB;"
                textAlign="center"
                gutterBottom
                sx={{
                  fontFamily: "DM Serif Display",
                }}
                className="h4 main_heading"
              >
                Create Your Account
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #DFDFDF",
                pb: 1,
                px: 2,
              }}
            >
              <Typography
                variant="subtitle2"
                gutterBottom
                fontSize={11}
                fontFamily={"Inter"}
                marginRight={1}
                textAlign="center"
              >
                Enter a valid email address that will serve as your user name.
                If you do not already have an email address, please create one
                first. Your password must meet state of Georgia security
                standards and consist of the following:
              </Typography>

              <Box
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                sx={{ position: "relative" }}
              >
                <InfoOutlinedIcon
                  sx={{
                    color: "#FBB522",
                    cursor: "pointer",
                    fontSize: 20,
                  }}
                />
                {hover && (
                  <Card
                    sx={{
                      position: "absolute",
                      top: "100%",
                      right: 0,
                      width: { xs: 300, sm: 350, md: 350 },
                      boxShadow: 3,
                      zIndex: 1,
                      mt: 1,
                      borderRadius: "20px",
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <CardContent
                      sx={{
                        paddingBottom: "8px !important",
                        fontFamily: "Inter",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        fontSize={11}
                        sx={{
                          color: "#0464AB",
                          fontWeight: "bold",
                        }}
                      >
                        Password length must be at least eight characters, and
                        must include the following:
                      </Typography>
                      <Box m={1}>
                        <ul>
                          <li>one upper-case and one lower-case letter;</li>
                          <li>one or more numbers; and</li>
                          <li>
                            one or more special characters, such as @, #, $.
                          </li>
                        </ul>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              m={2}
              fontWeight="bold"
              gutterBottom
              sx={{ color: "#022E4C", fontSize: "14px" }}
            >
              User Information
            </Typography>
            <Box mx={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    name="firstName"
                    label="First Name"
                    value={signupData.firstName}
                    onChange={handleInputSelectOnchange}
                    placeholder="First Name"
                    required={true}
                    error={errors.firstName}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    name="lastName"
                    label="Last Name"
                    value={signupData.lastName}
                    onChange={handleInputSelectOnchange}
                    placeholder="Last Name"
                    required={true}
                    error={errors.lastName}
                    type="text"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomInput
                    onChange={handleInputSelectOnchange}
                    value={signupData.email}
                    label="Email"
                    name="email"
                    type="text"
                    required={true}
                    error={errors.email}
                    placeholder="Email"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    onChange={handleInputSelectOnchange}
                    value={signupData.password}
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required={true}
                    error={errors.password}
                    placeholder="Password"
                    endAdornment={
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomDropdown
                    name="selectLanguage"
                    label="Default Language"
                    value={signupData.selectLanguage}
                    onChange={handleInputSelectOnchange}
                    options={[
                      { value: "english", label: "English" },
                      // { value: "spanish", label: "Spanish" }
                    ]}
                    placeholder="- Select -"
                  />
                </Grid>
              </Grid>
            </Box>
            <Typography
              mx={2}
              mt={1}
              fontWeight="bold"
              gutterBottom
              sx={{ color: "#022E4C", fontSize: "14px" }}
            >
              Administration
            </Typography>
            <Box m={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomDropdown
                    name="selectCourtName"
                    label="Default Court Name"
                    value={signupData.selectCourtName}
                    onChange={handleInputSelectOnchange}
                    options={courtData}
                    placeholder="- Select -"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomDropdown
                    name="selectCountyName"
                    label="Default County Name"
                    value={signupData.selectCountyName}
                    onChange={handleInputSelectOnchange}
                    options={countyData}
                    placeholder="- Select -"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    name="barNumber"
                    label="Bar Number"
                    value={signupData.barNumber}
                    onChange={handleInputSelectOnchange}
                    placeholder="Bar Number"
                    type="number"
                  />
                </Grid>
              </Grid>
              <Box sx={{ mb: 3, mt: 3 }}>
                <ReCAPTCHA
                  sitekey={
                    window.location.host === "localhost:3000"
                      ? reCAPTCHAkey.localhostSitekey
                      : window.location.host ===
                        "childsupport-dev.brainvire.net"
                      ? reCAPTCHAkey.devSitekey
                      : reCAPTCHAkey.UATSitekey
                  }
                  onChange={handleRecaptcha}
                />
                {isRecaptchaValide !== "" && (
                  <span style={{ color: "#d32f2f" }}> {isRecaptchaValide}</span>
                )}
              </Box>
            </Box>
            {/* <Grid item xs={12} md={12} m={5}> */}
            <Box display="flex" justifyContent="flex-start" mb={5}>
              <Button
                variant="contained"
                disableElevation
                disabled={isLoading}
                onClick={handleSignUpSubmit}
                sx={{
                  borderRadius: "20px",
                  width: 150,
                  mx: 2,
                  fontWeight: "600",
                  backgroundColor: "#0464AB",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#FBB522",
                    color: "#022E4C",
                    fontWeight: "600",
                  },
                }}
              >
                Save New User{" "}
                {isLoading && (
                  <CircularProgress size={18} sx={{ padding: "0px 8px" }} />
                )}
              </Button>
              <Link to="/">
                <Button
                  variant="outlined"
                  disableElevation
                  sx={{
                    borderRadius: "20px",
                    width: 100,
                    textTransform: "none",
                    backgroundColor: "#fff",
                    border: "1px solid #0464AB",
                    color: "#0464AB",
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "#FBB522",
                      color: "#022E4C",
                      border: "none",
                      fontWeight: "600",
                    },
                  }}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
            {/* </Grid> */}
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default RegisterForm;
